import cx from 'classnames'
import Typography, { Variant } from 'components/typography'

const ProgressBar = ({ progress }: ProgressBarProps): JSX.Element => {
  return (
    <div className="flex items-center w-full">
      <div
        className={cx('w-full rounded-2xl h-1 mt-3', {
          'bg-blue900': progress > 0,
          'bg-neutral300': progress === 0,
        })}
        style={{
          width: `${progress}%`,
        }}
      />
      {progress > 0 && (
        <Typography variant={Variant.Footnote} type="semibold">
          {progress}%
        </Typography>
      )}
    </div>
  )
}

interface ProgressBarProps {
  progress: number
}

export default ProgressBar
