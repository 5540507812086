/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tooltip } from '@mui/material'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import { ButtonVariant } from 'components/button'
import Button from 'components/button/Button'
import { ButtonProps } from 'components/button/button.types'
import Typography, { Variant } from 'components/typography'
import _isEmpty from 'lodash/isEmpty'
import { useCallback, useMemo } from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
import Select, { components, GroupBase, MenuProps, OptionProps } from 'react-select'
import makeAnimated from 'react-select/animated'
import { SelectOptions } from 'types/common.types'

import { selectStyle } from './styles'

const Menu = components.Menu

function SELECT_DROPDOWN<T>({
  control,
  options,
  id,
  defaultValue,
  placeholder,
  label,
  className,
  required,
  disabled = false,
  showSelectMenuButton = false,
  selectMenuButtonProps,
  menuPosition = 'absolute',
  menuPlacement = 'auto',
  handleChange,
  menuPortalTarget,
  maxHeight = '300px',
  newSelectDropDown = true,
  isCustomFilter = false,
  hide = false,
  isDark = false,
}: SelectFieldProps<T & FieldValues>) {
  const customFilter = useCallback(({ data }: { data: SelectOptions }, input: string) => {
    if (input) {
      if (data.filtertext) {
        if (data.filtertext.toLowerCase().includes(input.toLowerCase())) return true
      }
      return false
    }
    return true // if not search, then all match
  }, [])

  const filter = useMemo(() => (isCustomFilter ? customFilter : undefined), [customFilter, isCustomFilter])

  const animatedComponents = makeAnimated()
  const customMenuRenderer = useCallback(
    (props: MenuProps<SelectOptions, false, GroupBase<SelectOptions>>) => (
      <Menu className="z-20" {...props}>
        {props.children}
        {showSelectMenuButton && (
          <div className="p-3" style={{ maxHeight: '200px', overflowY: 'auto' }}>
            <Button
              icon={IconsType.plus}
              className="!rounded-none w-full "
              onClick={selectMenuButtonProps?.buttonAction}
              variant={ButtonVariant.Primary}>
              {selectMenuButtonProps?.buttonLabel}
              {selectMenuButtonProps?.children}
            </Button>
          </div>
        )}
      </Menu>
    ),
    [showSelectMenuButton, selectMenuButtonProps]
  )

  const Option = useCallback((props: OptionProps<SelectOptions>) => {
    if (props.data.additionalItems) {
      return (
        <components.Option {...props}>
          <div className="flex items-center max-w-full gap-2">
            <Tooltip title={props.children}>
              <div className="truncate max-w-full">{props.children}</div>
            </Tooltip>
            {props.data.additionalItems(props.data.value)}
          </div>
        </components.Option>
      )
    }
    return <components.Option {...props} />
  }, [])

  if (hide) return null

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={id as string}
      render={({ field: { onChange, onBlur, value, ref }, formState }) => {
        const { errors } = formState

        const valueFromOptions = options?.find(option => option.value == value?.value)

        const newValue = valueFromOptions || value

        return (
          <div className={cx('flex flex-col items-start w-full', className)}>
            {label &&
              (newSelectDropDown ? (
                <Typography
                  variant={Variant.Callout}
                  type="semibold"
                  className="mb-[0.125rem] flex items-center text-slate-600">
                  <span className="my-1">{label}</span>
                  {required && <span className="mb-2 text-red-500">*</span>}
                </Typography>
              ) : (
                <Typography variant={Variant.Callout} type="semibold" className="mb-[0.125rem] flex items-center">
                  <span className="my-1">{label}</span>
                  {required && <span className="mb-2 text-red-500">*</span>}
                </Typography>
              ))}

            <Select<SelectOptions>
              menuPosition={menuPosition}
              menuPlacement={menuPlacement}
              ref={ref}
              blurInputOnSelect
              onBlur={onBlur}
              onChange={val => {
                onChange(val)
                handleChange && handleChange(val)
              }}
              filterOption={filter}
              value={newValue && newValue.value == '' && newValue.label == '' ? null : newValue}
              options={options}
              isDisabled={disabled}
              menuShouldScrollIntoView
              components={{
                ...animatedComponents,
                Menu: customMenuRenderer,
                Option: Option,
              }}
              styles={selectStyle(Boolean(!_isEmpty(errors[id])), isDark, maxHeight)}
              placeholder={placeholder}
              defaultValue={defaultValue}
              className="w-full"
              menuPortalTarget={menuPortalTarget}
              isOptionDisabled={option => !!option.disabled}
            />
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              !_isEmpty(errors[id]) && (
                <Typography variant={Variant.Callout} className="pt-[0.125rem] text-red500">
                  This field is required
                </Typography>
              )
            }
          </div>
        )
      }}
    />
  )
}

interface SelectFieldProps<T extends FieldValues> {
  control: Control<FieldValues | T | any>
  label?: string
  id: keyof T
  defaultValue?: SelectOptions
  placeholder?: string
  type?: React.HTMLInputTypeAttribute
  options?: SelectOptions[]
  className?: string
  disabled?: boolean
  required?: boolean
  menuPosition?: 'absolute' | 'fixed'
  menuPlacement?: 'top' | 'bottom' | 'auto'
  showSelectMenuButton?: boolean
  selectMenuButtonProps?: SelectMenuButtonProps
  handleChange?: (val: SelectOptions | null) => void
  menuPortalTarget?: HTMLElement | null
  newSelectDropDown?: boolean
  isCustomFilter?: boolean
  hide?: boolean
  maxHeight?: string
  isDark?: boolean
}

interface SelectMenuButtonProps extends ButtonProps {
  buttonLabel: string
  buttonAction: () => void
}

export default SELECT_DROPDOWN
