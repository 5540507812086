import { IconProps } from 'assets'
import React from 'react'

const LoadingTwo = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.6001 2.75V5M9.6001 16.25V14M3.75443 12.875L5.70299 11.75M16.3501 9.50002H14.1001M12.9751 15.3457L11.8501 13.3971M2.8501 9.50002H5.1001M6.2251 3.65433L7.3501 5.60289M6.2251 15.3457L7.3501 13.3971M15.4457 12.875L13.4972 11.75M3.75443 6.125L5.70299 7.25"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        className={pathClassName}
      />
    </svg>
  )
}

export default LoadingTwo
