/* eslint-disable @typescript-eslint/no-explicit-any */
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import React from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
import Select, { components as Component, GroupHeadingProps } from 'react-select'

import { selectStyle } from './styles'

function SelectWithRadioButton<T>({
  control,
  id,
  label,
  required,
  className,
  height,
  groupedOptions,
  placeholder = 'Select',
  defaultValue,
}: SelectWithRadioButtonProps<T & FieldValues>) {
  const GroupHeading = (props: GroupHeadingProps) => (
    <div>
      <Component.GroupHeading
        {...props}
        style={{
          color: 'black',
          fontWeight: 600,
          lineHeight: '1.1875rem',
          marginBottom: '0.125rem',
        }}
      />
    </div>
  )

  return (
    <Controller
      name={id as string}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => {
        const { name, onBlur, onChange, ref, value } = field
        return (
          <div className={cx('w-full', className)}>
            {label && (
              <Typography variant={Variant.Callout} type="semibold" className="mb-[0.125rem] flex items-center">
                <span className="my-1">{label}</span>
                {required && <span className="mb-2 text-red-500">*</span>}
              </Typography>
            )}
            <Select
              menuPlacement="auto"
              defaultValue={defaultValue}
              placeholder={placeholder}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              ref={ref}
              value={value}
              styles={selectStyle({ height })}
              components={{
                GroupHeading,
                Option: props => {
                  if (props.children === 'Custom') {
                    return null
                  }
                  return (
                    <Component.Option {...props}>
                      <div className="flex flex-col justify-center h-[1.875rem]">
                        <div className="flex items-center text-start ">
                          <input
                            className="w-[1.2rem] h-[1.2rem] accent-blue800 border-2 rounded border-solid border-neutral300 mr-4"
                            type="radio"
                            checked={props.isSelected}
                            readOnly
                          />
                          <Typography
                            className="whitespace-nowrap overflow-hidden text-ellipsis text-neutral800"
                            variant={Variant.Body}>
                            {props.children}
                          </Typography>
                        </div>
                      </div>
                    </Component.Option>
                  )
                },
              }}
              options={groupedOptions}
            />
          </div>
        )
      }}
    />
  )
}

interface SelectWithRadioButtonProps<T extends FieldValues> {
  control: Control<FieldValues | T | any>
  label?: string
  id: keyof T
  placeholder?: string
  className?: string
  required?: boolean
  defaultValue?: { value: string; label: string }[]
  height?: string
  groupedOptions?: GroupedOptions[]
}

export interface GroupSelectOptions {
  value: string
  label: string
  groupName: string
}

export interface GroupedOptions {
  label: string
  options: GroupSelectOptions[]
}

export default SelectWithRadioButton
