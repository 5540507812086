import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(localizedFormat)
export const dateFormatter = (rawValue: string, sourceFormat = 'DD/MM/YYYY', targetFormat?: string) => {
  const parsedDate = dayjs(rawValue, sourceFormat)
  if (parsedDate.isValid()) {
    return parsedDate.format(targetFormat || 'DD/MM/YYYY')
  }
  return rawValue // if it's not a date or invalid date, return the raw value
}
