import { ColumnDef } from '@tanstack/react-table'
import Typography, { Variant } from 'components/typography'
import React from 'react'

import { LocalEntityTabTableRow } from './LocalEntityTab'

export const getLocalEntityDocsTabColumns = ({
  handlePreview,
}: {
  handlePreview: (data: LocalEntityTabTableRow) => void
}): ColumnDef<LocalEntityTabTableRow>[] => [
  {
    header: 'Document Type',
    cell(props) {
      const data = props.row.original
      return (
        <Typography variant={Variant.Callout} className="text-white" type="semibold">
          {data.label}
        </Typography>
      )
    },
    size: 200,
  },
  {
    header: 'Name',
    cell(props) {
      const data = props.row.original
      return (
        <Typography
          onClick={() => handlePreview(data)}
          variant={Variant.Callout}
          className="text-indigo-500 cursor-pointer"
          type="semibold">
          {data.file_name || `${data.label} File`}
        </Typography>
      )
    },
    size: 100,
  },
]
