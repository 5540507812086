import classNames from 'classnames'
import Typography, { Variant } from 'components/typography'
import React from 'react'
import colors from 'tailwindcss/colors'

interface PercentageBarProps {
  value: number
  primaryColor?: string
  secondaryColor?: string
  className?: string
  showValue?: boolean
  individuaBarSize?: string
}

const PercentageBar: React.FC<PercentageBarProps> = ({
  value,
  showValue = true,
  individuaBarSize = '0.9375rem',
  primaryColor = colors.blue[900],
  secondaryColor = colors.neutral[300],
  className,
}) => {
  const numberOfFullBars = Math.floor((value / 100) * 5)
  const remainingPercentage = ((value / 100) * 5) % 1
  const partialBarWidth = remainingPercentage * 15
  return (
    <div className={classNames(' flex items-center gap-1 ', className)}>
      <div className="gap-0.5 flex items-center">
        {Array.from({ length: 5 }).map((_, index) => (
          <>
            {index < numberOfFullBars ? (
              <div
                style={{ backgroundColor: primaryColor, width: individuaBarSize }}
                key={index}
                className="w-[0.9375rem] h-[0.3125rem] rounded-3xl"></div>
            ) : index === numberOfFullBars ? (
              <div
                style={{ backgroundColor: secondaryColor }}
                key={index}
                className="w-[0.9375rem] h-[0.3125rem] rounded-3xl">
                <div
                  key={index}
                  className="rounded-3xl"
                  style={{ width: `${partialBarWidth}px`, height: '5px', backgroundColor: primaryColor }}></div>
              </div>
            ) : (
              <div
                style={{ backgroundColor: secondaryColor }}
                key={index}
                className="w-[0.9375rem] h-[0.3125rem] rounded-3xl"></div>
            )}
          </>
        ))}
      </div>
      {showValue && (
        <Typography style={{ color: primaryColor }} variant={Variant.Callout} type="semibold">
          {`${value}`}
          <span style={{ fontSize: 10 }}>%</span>
        </Typography>
      )}
    </div>
  )
}

export default PercentageBar
