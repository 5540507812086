import Button from 'components/button'

import INPUT_FIELDS from '../fields/inputField'
import INPUT_WITH_ACCORDION from '../fields/inputWithAccordion/InputWithAccordion'
import PASSWORD_FIELDS from '../fields/passwordField'
import SELECT_DROPDOWN from '../fields/selectDropDown'

export type FIELD_RENDERERS =
  | typeof INPUT_FIELDS
  | typeof PASSWORD_FIELDS
  | typeof Button
  | typeof SELECT_DROPDOWN
  | typeof INPUT_WITH_ACCORDION

export enum InputType {
  Password = 'password',
  Text = 'text',
}
