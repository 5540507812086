import { IconProps } from 'assets'
import React from 'react'

const HandShake = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M16.9255 5.56267L15.1875 6.43165L12.9375 2.12276L14.6932 1.24493C14.8251 1.17898 14.9776 1.16747 15.1179 1.21287C15.2582 1.25827 15.3751 1.35696 15.4433 1.48768L17.1725 4.79919C17.2071 4.86541 17.2281 4.93785 17.2344 5.01229C17.2407 5.08672 17.2321 5.16166 17.2091 5.23274C17.1861 5.30381 17.1492 5.36959 17.1005 5.42623C17.0518 5.48288 16.9923 5.52926 16.9255 5.56267V5.56267Z"
        stroke="#1E3A8A"
        strokeWidth="1.275"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M2.81257 6.35646L1.07461 5.48748C1.0078 5.45407 0.948309 5.40769 0.899613 5.35104C0.850918 5.2944 0.813993 5.22862 0.790994 5.15754C0.767996 5.08647 0.759384 5.01153 0.765662 4.93709C0.771939 4.86266 0.79298 4.79022 0.827557 4.724L2.55675 1.41249C2.62501 1.28177 2.74187 1.18308 2.88218 1.13768C3.02249 1.09227 3.17502 1.10379 3.30692 1.16974L5.06257 2.04756L2.81257 6.35646Z"
        stroke="#1E3A8A"
        strokeWidth="1.275"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M15.1875 6.43278L14.0625 7.74889L11.4752 10.3362C11.4065 10.4049 11.3212 10.4546 11.2276 10.4805C11.134 10.5065 11.0353 10.5077 10.941 10.4841L6.86593 9.46537C6.7895 9.44626 6.71793 9.41131 6.65586 9.36278L2.8125 6.35742"
        stroke="#1E3A8A"
        strokeWidth="1.275"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M14.0626 7.74805L10.9688 5.49805L10.0688 6.17305C9.67937 6.46515 9.20567 6.62305 8.71884 6.62305C8.23201 6.62305 7.75831 6.46515 7.36884 6.17305L6.9877 5.88719C6.92322 5.83883 6.86989 5.77719 6.83132 5.70643C6.79274 5.63567 6.76982 5.55745 6.76411 5.47706C6.7584 5.39668 6.77003 5.316 6.79821 5.2405C6.82639 5.16499 6.87046 5.09643 6.92745 5.03944L9.67909 2.2878C9.73132 2.23557 9.79333 2.19413 9.86158 2.16586C9.92982 2.1376 10.003 2.12305 10.0768 2.12305H12.9376"
        stroke="#1E3A8A"
        strokeWidth="1.275"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M5.10254 2.0481L8.71098 0.996114C8.83988 0.958535 8.97797 0.968303 9.1003 1.02365L11.5309 2.12346"
        stroke="#1E3A8A"
        strokeWidth="1.275"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M7.875 11.9668L5.75582 11.437C5.66982 11.4155 5.59013 11.374 5.52323 11.3159L3.9375 9.9375"
        stroke="#1E3A8A"
        strokeWidth="1.275"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HandShake
