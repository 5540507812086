import { Dialog, Transition } from '@headlessui/react'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import { Fragment } from 'react'

import ModalHeader from './components/modalHeader/ModalHeader'

function Modal({
  children,
  isOpen,
  onClose,
  icon,
  iconClassName,
  iconPathClassName,
  title,
  containerClassName,
  className,
  iconComponent,
  modalId = 'customIntegralModal',
}: ModalProps) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 commonIntegralModalClass" id={modalId} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className={cx('transform  rounded-xl bg-white transition-all', containerClassName)}>
                <div
                  className={cx('flex flex-col items-start', className, {
                    'gap-4': title,
                  })}>
                  <ModalHeader
                    handleClose={onClose}
                    icon={icon}
                    title={title}
                    iconClassName={iconClassName}
                    iconPathClassName={iconPathClassName}
                    iconComponent={iconComponent}
                  />
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

interface ModalProps {
  children: React.ReactNode
  isOpen: boolean
  onClose: VoidFunction
  icon?: IconsType
  title?: string | React.ReactNode
  iconClassName?: string
  iconPathClassName?: string
  containerClassName?: string
  className?: string
  iconComponent?: React.ReactNode
  modalId?: string
}

export default Modal
