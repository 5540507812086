import { useMutation } from '@tanstack/react-query'
import { otherAppendixType } from 'api/documents/types'
import { deleteOtherAppendixFile, updateBulkAppendices } from 'api/legalEntity/appendixFiles'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import classNames from 'classnames'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Button from 'components/button'
import DocumentPreviewModal from 'components/documentPreviewModal'
import NewTable from 'components/newTable'
import useConfirmationModal from 'hooks/useConfirmationModal'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { getAppendicesConfig } from 'organisms/reportWizard/ReportWizard.helpers'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { getToastErrorMessage } from 'utils/utils'

import EditOtherAppendixFile from './Components/EditOtherAppendixFile'
import UploadOtherFilesModal from './Components/UploadOtherFilesModal'
import { getOtherAppendicesColumns } from './OtherAppendicesTab.helpers'

type Props = {
  data: ReturnType<typeof getAppendicesConfig>['Other Appendices']['data']
}

const OtherAppendicesTab = ({ data }: Props) => {
  const { refetchChecklist } = useReportWizardContext()
  const [reorderedOtherFiles, setReorderedOtherFiles] = useState<otherAppendixType[]>(data || [])
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isPreview, setIsPreview] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [newUploadModal, setNewUploadModal] = useState(false)

  const [selectedFile, setSelectedFile] = useState<otherAppendixType>()

  const reorderOtherFilesRow = useCallback(
    (draggedRowIndex: number, targetRowIndex: number) => {
      const swappedAttributes = [...reorderedOtherFiles]
      swappedAttributes.splice(targetRowIndex, 0, swappedAttributes.splice(draggedRowIndex, 1)[0])
      setReorderedOtherFiles([...swappedAttributes])
    },
    [reorderedOtherFiles]
  )

  const onCloseModal = useCallback(() => {
    setSelectedFile(undefined)
    setIsEdit(false)
    setIsPreview(false)
    setNewUploadModal(false)
  }, [])

  useEffect(() => {
    setReorderedOtherFiles(data || [])
  }, [data])

  const isReordered = useMemo(() => {
    if (!data?.length) return false
    return data.map(file => file.id).join('-') !== reorderedOtherFiles.map(file => file.id).join('-')
  }, [data, reorderedOtherFiles])

  const { getConfirmation, ConfirmationModal } = useConfirmationModal()

  const deleteOtherAppendixFileMutation = useMutation(deleteOtherAppendixFile)

  const handleDelete = useCallback(
    async (data: otherAppendixType) => {
      const confirmation = await getConfirmation(
        `Are you sure you want to delete the Appendix File - ${data.file_name}`
      )
      if (confirmation) {
        deleteOtherAppendixFileMutation.mutate(data.appendix_url, {
          onSuccess: () => {
            toast.success(`${data.file_name || 'Selected'} document deleted successfully`)
            refetchChecklist()
          },
          onError: error => {
            getToastErrorMessage(error as AxiosError)
          },
        })
      }
    },
    [deleteOtherAppendixFileMutation, getConfirmation, refetchChecklist]
  )

  const reorderOtherFilesMutation = useMutation(updateBulkAppendices, {
    onSuccess: () => {
      refetchChecklist()
      toast.success('Files reordered successfully')
    },
    onError: () => {
      toast.error('Reorder failed')
    },
  })

  const onReorderClick = useCallback(() => {
    reorderOtherFilesMutation.mutate(
      reorderedOtherFiles.map((file, index) => {
        const id = file.id
        return {
          id: Number(id),
          order: index,
        }
      })
    )
  }, [reorderOtherFilesMutation, reorderedOtherFiles])

  const handleEdit = useCallback((data: otherAppendixType) => {
    setSelectedFile(data)
    setIsEdit(true)
  }, [])

  const handlePreview = useCallback((data: otherAppendixType) => {
    setSelectedFile(data)
    setIsPreview(true)
  }, [])

  const columns = useMemo(() => {
    return getOtherAppendicesColumns({
      handleDelete,
      handleEdit,
      handlePreview,
    })
  }, [handleDelete, handleEdit, handlePreview])

  const disableButtons = reorderOtherFilesMutation.isLoading || deleteOtherAppendixFileMutation.isLoading

  return (
    <BorderlessBox bgClass="bg-[rgb(16,15,46)]" className="w-full py-3 gap-4 relative">
      <BorderlessBox bgClass="bg-[#1A193B]" className="w-full !px-2 !py-1">
        <NewTable
          tableRowClassName="!bg-[#141431] !border-none hover:!bg-[#141431]"
          tableCellClassname="!border-none"
          tableHeadClassName="!bg-[#212048] !text-white"
          data={reorderedOtherFiles}
          reorderRow={reorderedOtherFiles.length > 1 ? reorderOtherFilesRow : undefined}
          columns={columns}
          pageSize={reorderedOtherFiles.length}
          isDataLoading={reorderOtherFilesMutation.isLoading || deleteOtherAppendixFileMutation.isLoading}
        />
      </BorderlessBox>
      <div className="flex items-center w-full">
        <Button
          isDefaultSize={false}
          disabled={disableButtons}
          icon={IconsType.upload}
          onClick={() => setNewUploadModal(true)}
          iconPathClassName={classNames({
            '!stroke-indigo-300': !disableButtons,
            '!stroke-gray-600': disableButtons,
          })}
          className={classNames({
            '!bg-indigo-950 !text-indigo-300': !disableButtons,
            '!bg-gray-800 !text-gray-600': disableButtons,
          })}>
          File
        </Button>
        {reorderedOtherFiles.length > 1 && (
          <Button
            isDefaultSize={false}
            icon={IconsType.save}
            disabled={!isReordered || disableButtons}
            iconPathClassName={classNames({
              '!fill-indigo-300': !(!isReordered || disableButtons),
              '!fill-gray-600': !isReordered || disableButtons,
            })}
            onClick={onReorderClick}
            className={classNames('ms-auto', {
              '!bg-indigo-950 !text-indigo-300': !(!isReordered || disableButtons),
              '!bg-gray-800 !text-gray-600': !isReordered || disableButtons,
            })}>
            Save
          </Button>
        )}
      </div>
      <ConfirmationModal />
      <DocumentPreviewModal
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
        documentPreviewModal={isPreview && !!selectedFile?.file}
        setDocumentPreviewModal={onCloseModal}
        selectedFile={selectedFile?.file || undefined}
      />
      <UploadOtherFilesModal key={String(newUploadModal)} isOpen={newUploadModal} onClose={onCloseModal} />
      {!!selectedFile && isEdit && (
        <EditOtherAppendixFile
          key={String(isEdit)}
          selectedDocument={selectedFile}
          isOpen={isEdit}
          onClose={onCloseModal}
        />
      )}
    </BorderlessBox>
  )
}

export default OtherAppendicesTab
