import { IconProps } from 'assets'
import React from 'react'

const RotateRight = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="rotate_right">
        <path
          className={pathClassName}
          id="Vector"
          d="M13.0498 21.9998V19.9498C13.6165 19.8665 14.1706 19.7165 14.7123 19.4998C15.254 19.2831 15.7665 18.9998 16.2498 18.6498L17.6498 20.0998C16.9498 20.6331 16.2165 21.0623 15.4498 21.3873C14.6831 21.7123 13.8831 21.9165 13.0498 21.9998ZM11.0498 21.9998C8.7498 21.6998 6.84147 20.704 5.3248 19.0123C3.80814 17.3206 3.0498 15.3331 3.0498 13.0498C3.0498 11.7998 3.2873 10.629 3.7623 9.5373C4.2373 8.44564 4.87897 7.49564 5.6873 6.6873C6.49564 5.87897 7.44564 5.2373 8.5373 4.7623C9.62897 4.2873 10.7998 4.0498 12.0498 4.0498H12.1998L10.6498 2.4998L12.0498 1.0498L16.0498 5.0498L12.0498 9.0498L10.6498 7.6498L12.2498 6.0498H12.0498C10.0998 6.0498 8.44564 6.72897 7.0873 8.0873C5.72897 9.44564 5.0498 11.0998 5.0498 13.0498C5.0498 14.7831 5.61647 16.304 6.7498 17.6123C7.88314 18.9206 9.31647 19.6998 11.0498 19.9498V21.9998ZM19.0998 18.6498L17.6498 17.2498C17.9998 16.7665 18.2831 16.254 18.4998 15.7123C18.7165 15.1706 18.8665 14.6165 18.9498 14.0498H20.9998C20.9165 14.8831 20.7123 15.6831 20.3873 16.4498C20.0623 17.2165 19.6331 17.9498 19.0998 18.6498ZM20.9998 12.0498H18.9498C18.8665 11.4831 18.7165 10.929 18.4998 10.3873C18.2831 9.84564 17.9998 9.33314 17.6498 8.8498L19.0998 7.4498C19.6331 8.0998 20.0581 8.81647 20.3748 9.5998C20.6915 10.3831 20.8998 11.1998 20.9998 12.0498Z"
          fill="black"
        />
      </g>
    </svg>
  )
}

export default RotateRight
