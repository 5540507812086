import { IconsType } from 'assets/types'
import Input, { IconPlacement } from 'components/input'
import { EMPTY_ARRAY } from 'constants/app.constants'
import { useCallback, useMemo, useState } from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'

import { InputType } from '../types/types'

function PASSWORD_FIELDS<T>({
  control,
  label,
  id,
  defaultValue,
  placeholder,
  className,
  isAnimatedLabel,
}: InputFieldProps<T & FieldValues>) {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [inputType, setInputType] = useState<InputType>(InputType.Password)

  const onPasswordVisibleHandler = useCallback(() => {
    setPasswordVisible(prevPasswordVisible => !prevPasswordVisible)
    setInputType(prevInputType => (prevInputType === InputType.Password ? InputType.Text : InputType.Password))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, EMPTY_ARRAY)

  const getIcon = useMemo(() => (passwordVisible ? IconsType.eyeVisible : IconsType.eyeInvisible), [passwordVisible])

  return (
    <Controller
      control={control}
      render={({ field: { onChange, onBlur, name, ref, value }, formState }) => {
        const { errors } = formState
        const isError = Boolean(errors[id]?.message)
        const descriptiveText = errors[id]?.message?.toString()
        return (
          <Input
            descriptiveText={descriptiveText}
            label={label}
            onBlur={onBlur}
            onChange={onChange}
            name={name}
            ref={ref}
            icon={getIcon}
            type={inputType}
            iconPlacement={IconPlacement.Right}
            onIconClickHandler={onPasswordVisibleHandler}
            placeholder={placeholder}
            error={isError}
            className={className}
            isAnimatedLabel={isAnimatedLabel}
            defaultValue={value}
          />
        )
      }}
      shouldUnregister={false}
      name={id}
      defaultValue={defaultValue}
    />
  )
}

interface InputFieldProps<T extends FieldValues> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FieldValues | T | any>
  label: string
  id: string
  defaultValue?: string
  placeholder?: string
  className?: string
  isAnimatedLabel?: boolean
}

export default PASSWORD_FIELDS
