import useFileUploader from 'hooks/useFileUploader'
import { useCallback } from 'react'
import { toast } from 'react-toastify'

import FileLabel from './components/fileLabel'
import UploadBox from './components/uploadBox'

const UploadMultipleDocuments = ({
  files,
  setFiles,
  label,
  handleValidateFile,
  allowedExtensions = '.pdf,.doc,.docx,.odt,.csv,.xlsx,.xlsm',
  isPreviewFiles = false,
}: UploadMultipleDocumentsProps): JSX.Element => {
  const validateFile = useCallback(
    async (file: File) => {
      if (handleValidateFile) {
        const error = await handleValidateFile(file)
        if (error) {
          return error
        }
      }
      return null
    },
    [handleValidateFile]
  )

  const handleFileUpload = useCallback(
    async (file: File) => {
      const error = await validateFile(file)
      if (!error) {
        setFiles(prev => [...prev, file])
      } else {
        toast.error(error, { type: 'warning' })
      }
    },
    [setFiles, validateFile]
  )

  const { handleClick, ref, progress } = useFileUploader({
    onFileSelected: handleFileUpload,
    accept: allowedExtensions,
    isMultiple: true,
  })

  const handleDeleteFile = useCallback(
    (fileName: string) => () => {
      setFiles(prev => prev.filter(file => file.name !== fileName))
    },
    [setFiles]
  )

  return (
    <div className="w-full text-start">
      <UploadBox label={label} allowedExtensions={allowedExtensions} handleClick={handleClick} ref={ref} />
      {isPreviewFiles &&
        files.map((file, index) => (
          <FileLabel
            handleDeleteFile={handleDeleteFile}
            key={index}
            progress={progress}
            fileName={file.name}
            fileSize={file.size.toString()}
          />
        ))}
    </div>
  )
}

interface UploadMultipleDocumentsProps {
  files: File[]
  setFiles: React.Dispatch<React.SetStateAction<File[]>>
  isPreviewFiles?: boolean
  label?: string
  allowedExtensions?: string
  handleValidateFile?: (file: File) => Promise<string | null>
  classes?: Partial<{ root: string; uploadBox: { root: string; label: string }; files: '' }>
}

export default UploadMultipleDocuments
