import { CountryResponse } from 'api/common'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import Image from 'next/image'
import { memo } from 'react'

const FlagReader = ({
  country,
  width = 18,
  height = 10,
  isNameVisible = false,
  otherText,
  textClassName,
  className,
}: Props) => {
  const resolution = width >= 16 && height >= 12 ? 'w160' : '16x12'

  return (
    <div className={cx('flex items-center gap-2', className)}>
      {country && (
        <Image
          src={`https://flagcdn.com/${resolution}/${country.code?.toLowerCase() || country.code?.toLowerCase()}.png`}
          width={width}
          height={height}
          alt={`contry-flag-${country.code}`}
        />
      )}

      {isNameVisible && country?.name && (
        <Typography variant={Variant.Callout} type="semibold" className={cx('text-blue800', textClassName)}>
          {country.name}
        </Typography>
      )}
      {otherText && (
        <Typography variant={Variant.Callout} className={textClassName} type="semibold">
          {otherText}
        </Typography>
      )}
    </div>
  )
}

interface Props {
  width?: number
  height?: number
  country?: CountryResponse
  isNameVisible?: boolean
  otherText?: string
  textClassName?: string
  className?: string
}

export default memo(FlagReader)
