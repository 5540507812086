import { Tooltip } from '@mui/material'
import { ColumnDef } from '@tanstack/react-table'
import cx from 'classnames'
import FlagReader from 'components/flagReader/FlagReader'
import Typography, { Variant } from 'components/typography'
import { capitalizeFirstLetter, truncateString } from 'utils/utils'

import { TransactionAppendixTabRow } from './TransactionAppendixTab'

export const getTXNAppendixColumns = ({
  handlePreview,
}: {
  handlePreview: (data: TransactionAppendixTabRow) => void
}): ColumnDef<TransactionAppendixTabRow>[] => {
  return [
    {
      header: 'Document Type',
      accessorKey: 'fileType',
      sortingFn: 'alphanumeric',
      cell(props) {
        const data = props.row.original
        return (
          <Typography
            onClick={() => handlePreview(data)}
            variant={Variant.Callout}
            className="text-white"
            type="semibold">
            {data.fileType}
          </Typography>
        )
      },
      size: 200,
    },
    {
      header: 'Name',
      accessorKey: 'file_name',
      sortingFn: 'alphanumeric',
      cell(props) {
        const data = props.row.original
        return (
          <Typography
            onClick={() => handlePreview(data)}
            variant={Variant.Callout}
            className="text-indigo-500 cursor-pointer"
            type="semibold">
            {data.file_name}
          </Typography>
        )
      },
      size: 200,
    },
    {
      header: 'Tested Parties',
      size: 100,
      cell(props) {
        const data = props.row.original
        const entities = data.testedParties
        if (!entities?.length) {
          return (
            <Typography variant={Variant.Callout} className="text-neutral-600" type="semibold">
              NA
            </Typography>
          )
        }
        const firstEntity = entities[0]
        const firstEntityName = firstEntity.name
        const firstEntityAbbr = firstEntity.name_abbreviation
        return (
          <div className="flex">
            <div className="flex flex-col items-start ml-2">
              <Tooltip title={capitalizeFirstLetter(firstEntityName)} placement="top">
                <div className={'text-white max-w-[12.0625rem] truncate flex'}>
                  <FlagReader
                    country={firstEntity.country}
                    otherText={truncateString(capitalizeFirstLetter(firstEntityAbbr || firstEntityName), 15)}
                    className={'text-white'}
                  />
                </div>
              </Tooltip>
            </div>
            {entities.length > 1 && (
              <Tooltip
                title={
                  <>
                    {entities.map((entity, idx) => {
                      const entityName = entity.name_abbreviation || entity.name
                      return (
                        idx !== 0 && (
                          <div
                            className={cx('flex flex-col py-2 self-stretch px-3 gap-1', {
                              'border-0 border-t border-gray-700 border-solid': idx > 1,
                            })}>
                            <Typography
                              key={`relatedParty-${entity.id}`}
                              variant={Variant.Callout}
                              type="semibold"
                              className={cx('text-gray-300 max-w-[100%] truncate gap-1 flex')}>
                              <FlagReader country={entity.country} />
                              {capitalizeFirstLetter(entityName)}
                            </Typography>
                          </div>
                        )
                      )
                    })}
                  </>
                }
                classes={{
                  tooltip:
                    'p-0 bg-[#1A193B] w-[200px] max-h-[258px] overflow-y-auto rounded-lg border border-solid border-neutral-500',
                }}
                placement="top">
                <span className="flex items-center cursor-pointer ml-2">
                  {entities.length > 1 ? (
                    <span className="bg-orange-100 rounded-[0.25rem] px-1 py-[2px] text-orange-700 text-footnote">
                      +{entities.length - 1}
                    </span>
                  ) : (
                    <></>
                  )}
                </span>
              </Tooltip>
            )}
          </div>
        )
      },
    },
  ]
}
