import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import IconPlaceHolder from 'components/iconPlaceholder'
import ProgressBar from 'components/progressBar'
import Typography, { Variant } from 'components/typography'
import { formatBytes } from 'utils/utils'

const FileLabel = ({ progress, fileName, fileSize, handleDeleteFile }: FileLabelProps) => {
  return (
    <div className="flex items-start gap-3 mt-10">
      <IconPlaceHolder icon={IconsType.file} />
      <div className="flex flex-col items-start w-full">
        <div className="flex w-full justify-between">
          <Typography variant={Variant.Callout} type="semibold">
            {fileName}
          </Typography>
          <div onClick={handleDeleteFile(fileName)}>
            {getIcons(IconsType.delete, { pathClassName: 'fill-red600 ml-2', className: 'cursor-pointer' })}
          </div>
        </div>

        <Typography variant={Variant.Body} className="mt-1 text-neutral600">
          {formatBytes(Number(fileSize))}
        </Typography>
        {progress !== 100 && <ProgressBar progress={progress} />}
      </div>
    </div>
  )
}

interface FileLabelProps {
  progress: number
  fileName: string
  fileSize: string
  handleDeleteFile: (fileName: string) => () => void
}

export default FileLabel
