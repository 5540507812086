import { Tooltip } from '@mui/material'
import cx from 'classnames'
import React, { forwardRef, ReactNode, useEffect, useRef } from 'react'

const Checkbox = (
  {
    label,
    onBlur,
    onChange,
    name,
    checked,
    indeterminate,
    id,
    value,
    disabled = false,
    className = '',
    tooltipTitle,
    labelIcon,
  }: CheckboxProps,
  ref: React.LegacyRef<HTMLInputElement>
): JSX.Element => {
  const defaultRef = useRef<HTMLInputElement>(null)
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    if (typeof resolvedRef !== 'function' && typeof resolvedRef !== 'string' && resolvedRef.current) {
      resolvedRef.current.indeterminate = indeterminate || false
    }
  }, [resolvedRef, indeterminate])

  return (
    <Tooltip placement="top" title={tooltipTitle}>
      <div className="flex items-center gap-[0.65rem] w-fit">
        <input
          id={id}
          onBlur={onBlur}
          onChange={onChange}
          ref={resolvedRef}
          name={name}
          type="checkbox"
          value={value}
          checked={checked}
          disabled={disabled}
          className={cx(
            `indeterminate: w-[1.2rem] h-[1.2rem] accent-blue800 border-2 rounded border-solid border-neutral300 cursor-pointer`,
            className,
            {
              'pointer-events-none': disabled,
            }
          )}
        />
        {(label || labelIcon) && (
          <label
            className={cx(
              'font-semibold text-callout cursor-pointer flex items-center gap-1',
              !disabled ? 'text-neutral800' : 'text-neutral500 pointer-events-none'
            )}
            htmlFor={name}>
            {label} {labelIcon}
          </label>
        )}
      </div>
    </Tooltip>
  )
}

interface CheckboxProps {
  name: string
  id: string
  label: ReactNode
  tooltipTitle?: ReactNode
  disabled?: boolean
  className?: string
  indeterminate?: boolean
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
  checked?: boolean
  labelIcon?: ReactNode
}

export default forwardRef(Checkbox)
