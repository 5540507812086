import Papa, { ParseConfig } from 'papaparse'
// import { read, utils } from 'xlsx'

export const getFileExtension = (fileName = '') => {
  const fileNameParts = fileName.split('.')
  if (!fileNameParts?.length) {
    return ''
  }
  const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase()
  return fileExtension
}

const parseCSVData = (data: ArrayBuffer, onlyHeaders?: boolean) => {
  return new Promise((resolve, reject) => {
    const csvContent = new TextDecoder('utf-8').decode(data)

    const parseOptions: ParseConfig = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: results => {
        if (results.errors.length === 0) {
          const { data: rows } = results
          const headers = results.meta.fields || []

          if (onlyHeaders) {
            resolve(headers)
          } else {
            resolve({ headers, rows })
          }
        } else {
          reject(new Error('Failed to parse CSV file.'))
        }
      },
    }

    if (onlyHeaders) parseOptions.preview = 1

    Papa.parse<typeof csvContent>(csvContent, parseOptions)
  })
}

// const parseXLSXData = (data: ArrayBuffer, onlyHeaders?: boolean) => {
//   const workbook = read(data, { type: 'array' })
//   const sheetName = workbook.SheetNames[0]
//   const worksheet = workbook.Sheets[sheetName]
//   const headers = utils.sheet_to_json(worksheet, { header: 1 })[0]

//   if (onlyHeaders) return headers

//   return worksheet
// }

type ParsedFileHeaders = string[]

interface ParsedFileData {
  headers: string[]
  rows: Record<string, unknown>[]
}

export const parseFileFromPath = <T extends { file: File; onlyHeaders?: boolean }>({
  file,
  onlyHeaders,
}: T): Promise<T extends { onlyHeaders: true } ? ParsedFileHeaders : ParsedFileData> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = async event => {
      const arrayBuffer = event.target?.result

      try {
        // Detect file type
        const fileType = getFileExtension(file?.name)

        let data
        if (arrayBuffer instanceof ArrayBuffer && fileType === 'csv') {
          data = await parseCSVData(arrayBuffer, !!onlyHeaders)
        } else if (arrayBuffer instanceof ArrayBuffer && fileType === 'xlsx') {
          // data = parseXLSXData(arrayBuffer, !!onlyHeaders)
        } else {
          reject(new Error('Unsupported file type.'))
          return
        }

        resolve(data as unknown as T extends { onlyHeaders: true } ? ParsedFileHeaders : ParsedFileData)
      } catch (error) {
        reject(new Error('Failed to parse file headers.'))
      }
    }

    reader.onerror = function () {
      reject(new Error('Failed to read file.'))
    }

    reader.readAsArrayBuffer(file)
  })
}
