import { Tooltip } from '@mui/material'
import GreenTick from 'assets/icons/greenTick'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import React, { ReactNode } from 'react'

import { moduleKeyType } from '../ReportWizard.helpers'

type Props = {
  icon: ReactNode
  title: string
  completed: boolean
  active: boolean
  moduleKey: moduleKeyType
  setActiveModule: React.Dispatch<React.SetStateAction<moduleKeyType>>
  disabled?: boolean
  disabledTooltipText?: string
}

const ModuleCard = ({
  icon,
  moduleKey,
  title,
  completed,
  active,
  setActiveModule,
  disabled,
  disabledTooltipText,
}: Props) => {
  return (
    <Tooltip title={disabled ? disabledTooltipText : undefined}>
      <div
        onClick={() => !disabled && setActiveModule(moduleKey)}
        className={cx(
          'rounded-lg flex flex-col justify-between w-full gap-5 h-[108px] p-4 transition-all	duration-300',
          {
            'max-w-[22%] bg-[rgb(16,15,46)]': !active,
            'max-w-[31%] bg-[#1A193B]': active,
            'cursor-not-allowed opacity-50 saturate-50 contrast-75': disabled,
            'cursor-pointer': !disabled,
          }
        )}>
        {icon}
        <Typography variant={Variant.Callout} className="whitespace-pre-line text-white" type="semibold">
          {title}
          {!!completed && (
            <GreenTick className="inline w-4 h-4 -mr-2 relative top-1 left-1" pathClassName="fill-green-600" />
          )}
        </Typography>
      </div>
    </Tooltip>
  )
}
export default ModuleCard
