import Typography, { Variant } from 'components/typography'
import React from 'react'

const InfoLabel = ({ newInput = true, label, required = false }: InfoLabelProps) => {
  if (!label && !required) return null
  return newInput ? (
    <Typography
      variant={Variant.ContainerText}
      type="semibold"
      className="mb-[0.125rem] flex items-center text-slate-600">
      <span className="my-1">{label}</span>
      {required && <span className="mb-2 text-red-500">*</span>}
    </Typography>
  ) : (
    <Typography variant={Variant.Callout} type="semibold" className="mb-[0.125rem] flex items-center">
      <span className="my-1 text-neutral-800">{label}</span>
      {required && <span className="mb-2 text-red-500">*</span>}
    </Typography>
  )
}

interface InfoLabelProps {
  label: string
  required?: boolean
  newInput?: boolean
}

export default InfoLabel
