import { getIcons } from 'assets'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import { capitalizeFirstLetter } from 'utils/utils'

import { IconsTypeMapper } from './helpers'

const IconWithText = ({
  type,
  className,
  textClassName,
  iconClassName = 'w-[0.9375rem] h-[0.9375rem]',
  iconPathClassName,
  showIcon = true,
  renameTangibles = false,
}: Props): JSX.Element => {
  return (
    <div className={cx('flex gap-3 items-center', className)}>
      {!!showIcon && getIcons(IconsTypeMapper[type], { className: iconClassName, pathClassName: iconPathClassName })}
      <Typography className={cx('text-neutral800', textClassName)} type="semibold" variant={Variant.Callout}>
        {renameTangibles && type == 'tangibles' ? 'Products/Goods' : capitalizeFirstLetter(type)}
      </Typography>
    </div>
  )
}

interface Props {
  type: string
  className?: string
  renameTangibles?: boolean
  textClassName?: string
  iconClassName?: string
  iconPathClassName?: string
  showIcon?: boolean
}

export default IconWithText
