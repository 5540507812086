import { camelToKebabCase, remToPx } from '../utils/utils'

type FontType =
  | 'Footnote'
  | 'ContainerText'
  | 'Callout'
  | 'Body'
  | 'Heading1'
  | 'Heading2'
  | 'Title1'
  | 'Title2'
  | 'Display1'
  | 'Display2'

type RemTypeProps = {
  [fontType in FontType]: {
    fontSize: `${number}rem`
    lineHeight: `${number}rem`
  }
}

type PixelTypeProps = {
  [fontType in FontType]: {
    fontSize: number
    lineHeight: number
  }
}

interface FontSizeProps {
  REM: Partial<RemTypeProps>
  PIXELS: Partial<PixelTypeProps>
}

interface TailwindFontSizeProps {
  [fontType: string]: [
    fontSize: string,
    configuration: Partial<{ lineHeight: string; letterSpacing: string; fontWeight: string | number }>
  ]
}

const FontSizes: FontSizeProps = {
  PIXELS: {},
  REM: {
    Footnote: {
      fontSize: '0.6875rem',
      lineHeight: '1rem',
    },
    ContainerText: {
      fontSize: '0.875rem',
      lineHeight: '1rem',
    },
    Callout: {
      fontSize: '0.8125rem',
      lineHeight: '1.1875rem',
    },
    Body: {
      fontSize: '0.875rem',
      lineHeight: '1.3125rem',
    },
    Heading1: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
    },
    Heading2: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    Title1: {
      fontSize: '1.625rem',
      lineHeight: '2.438rem',
    },
    Title2: {
      fontSize: '1.4375rem',
      lineHeight: '2.125rem',
    },
    Display1: {
      fontSize: '2.125rem',
      lineHeight: '3.188rem',
    },
    Display2: {
      fontSize: '1.875rem',
      lineHeight: '2.813rem',
    },
  },
}

Object.keys(FontSizes.REM).forEach(variant => {
  const fontVariant = variant as FontType
  const variantProps = FontSizes.REM[fontVariant]
  if (variantProps) {
    FontSizes.PIXELS[fontVariant] = {
      fontSize: remToPx(variantProps.fontSize),
      lineHeight: remToPx(variantProps.lineHeight),
    }
  }
})

export const tailwindFontSizes = Object.keys(FontSizes.REM).reduce((fontSizes: TailwindFontSizeProps, variant) => {
  const fontVariant = variant as FontType
  const variantProps = FontSizes.REM[fontVariant]
  if (variantProps) {
    fontSizes[camelToKebabCase(fontVariant)] = [
      variantProps.fontSize,
      {
        lineHeight: variantProps.lineHeight,
      },
    ]
  }
  return fontSizes
}, {})

export default FontSizes
