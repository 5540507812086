import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import { Variant } from 'components/typography'
import Typography from 'components/typography/Typography'
import React, { useMemo } from 'react'

import { baseClass } from './button.config'
import {
  getButtonSizeClass,
  getIconClassBasedOnVariant,
  getRestButtonConfig,
  VariantMappingToClass,
} from './button.helper'
import { ButtonProps, ButtonSize, ButtonVariant, IconPlacement } from './button.types'

const Button = ({
  children,
  isFullWidth = false,
  icon,
  iconPlacement = IconPlacement.Left,
  variant = ButtonVariant.Primary,
  disabled = false,
  buttonType = 'button',
  onClick,
  size = ButtonSize.Small,
  className,
  iconCLass,
  count,
  primaryColor,
  secondaryColor,
  ref,
  isDefaultSize = true,
  iconPathClassName,
  successState = false,
}: ButtonProps): JSX.Element => {
  const variantClass = VariantMappingToClass[variant]
  const paddingClass = useMemo(() => getButtonSizeClass(size), [size])

  const restButtonConfig = useMemo(
    () =>
      getRestButtonConfig({
        isFullWidth,
        icon,
        iconPlacement,
      }),
    [isFullWidth, iconPlacement, icon]
  )

  const iconClassName = useMemo(
    () =>
      isDefaultSize
        ? getIconClassBasedOnVariant(variant, size, disabled)
        : {
            className: iconCLass,
            pathClassName: iconPathClassName,
            primaryColor: primaryColor,
            secondaryColor: secondaryColor,
          },
    [isDefaultSize, primaryColor, secondaryColor, variant, size, disabled, iconCLass, iconPathClassName]
  )

  return (
    <button
      ref={ref}
      onClick={onClick}
      disabled={disabled}
      type={buttonType}
      className={cx(
        baseClass,
        variantClass,
        restButtonConfig,
        paddingClass,
        { 'px-[0rem]': variant === ButtonVariant.Tertiary },
        { 'relative !px-4 !py-[0.38rem]': count || successState },
        className
      )}>
      {successState ? (
        <div className=" absolute top-[0] right-0 border-solid border-2 border-blue-50 w-12px h-12px rounded-lg bg-green-500 ">
          {getIcons(IconsType.tick, {
            className: 'flex items-center justify-center h-2.5 w-2.5 ',
            pathClassName: '',
          })}
        </div>
      ) : (
        !!count &&
        count > 0 && (
          <div
            style={{ minWidth: '1.25rem' }}
            className="flex justify-center items-center absolute h-5 top-[-8px] p-[2px] flex-shrink-0 right-[-10px] border-solid border-2 border-white rounded-full bg-green-500 ">
            <Typography variant={Variant.Footnote} className="text-white">
              {count}
            </Typography>
          </div>
        )
      )}

      {children}
      {icon && getIcons(icon, iconClassName)}
    </button>
  )
}

Button.Variant = ButtonVariant

export default Button
