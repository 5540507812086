import { Row } from '@tanstack/react-table'
import Checkbox from 'components/checkbox'
import { useRef } from 'react'

function SelectCheckbox<T>({ row }: SelectCheckboxProps<T>) {
  const inputRef = useRef<HTMLInputElement>(null)
  const isRowSelected = row.getIsSelected()
  const canSelectRow = row.getCanSelect()
  const toggleHandler = row.getToggleSelectedHandler()

  return (
    <Checkbox
      id={`t-select-${row.id}`}
      ref={inputRef}
      name=""
      label=""
      checked={isRowSelected}
      disabled={!canSelectRow}
      indeterminate={!canSelectRow}
      onChange={toggleHandler}
    />
  )
}

interface SelectCheckboxProps<T> {
  row: Row<T>
}

export default SelectCheckbox
