import axios from 'api'

import { ArmsLengthRange, LegalEntityMetricsResponse } from './types'

export const getLegalEntityMetricsData = (year?: number, country?: string) => {
  const params: { [key: string]: number | string | [] } = {}
  if (year) {
    params.year = year
  }
  if (country) {
    params.country = country
  }

  return axios
    .get<LegalEntityMetricsResponse>(`/api/v1/org/{organization}/metrics/legal_entity_metrics/get_metrics/`, { params })
    .then(res => res.data)
}

export const getArmsLengthRangeData = (year?: number, country?: string) => {
  const params: { [key: string]: number | string | [] } = {}
  if (year) {
    params.year = year
  }
  if (country) {
    params.country = country
  }

  return axios
    .get<ArmsLengthRange>(`/api/v1/org/{organization}/metrics/legal_entity_metrics/get_arms_length_range/`, {
      params,
    })
    .then(res => res.data)
}
