import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { download_local_file } from 'api/documents'
import { ChecklistResponse } from 'api/documents/types'
import { AxiosError } from 'axios'
import { QUERIES } from 'constants/query'
import { ROUTES } from 'constants/routes'
import useConfirmationModal from 'hooks/useConfirmationModal'
import { isNaN } from 'lodash'
import { useRouter } from 'next/router'
import { moduleKeyType } from 'organisms/reportWizard/ReportWizard.helpers'
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Control, FieldValues, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { SelectOptions } from 'types/common.types'
import { capitalizeFirstLetter, getToastErrorMessage } from 'utils/utils'

type ReportWizardContextType = {
  orgId?: number
  setOrgId: React.Dispatch<React.SetStateAction<number | undefined>>
  legalEntity?: number
  setLegalEntity: React.Dispatch<React.SetStateAction<number | undefined>>
  reportId?: number
  setReportId: React.Dispatch<React.SetStateAction<number | undefined>>
  isActive: boolean
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>
  isExpanded: boolean
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
  control: Control<ReportWizardFieldValues>
  templateOptions: SelectOptions[]
  setActiveModule: React.Dispatch<React.SetStateAction<moduleKeyType>>
  activeModule: moduleKeyType
  checklistData?: ChecklistResponse
  refetchChecklist: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<ChecklistResponse, unknown> | undefined>
  handleGenerateClick: () => void
  loading: boolean
  isFetching: boolean
  stickyFooterHeight: number
  setStickyFooterHeight: React.Dispatch<React.SetStateAction<number>>
  handleTemplateChange: (val: SelectOptions | null) => Promise<void>
  selectedTemplate: SelectOptions | undefined
  setSelectedTemplate: React.Dispatch<React.SetStateAction<SelectOptions | undefined>>
  selectedTransactions: number[] | undefined
  setSelectedTransactions: React.Dispatch<React.SetStateAction<number[] | undefined>>
  legalEntityName: string
  setlegalEntityName: React.Dispatch<React.SetStateAction<string>>
  generating: boolean
}

const ReportWizardContext = React.createContext<ReportWizardContextType | undefined>(undefined)

export const ReportWizardContextProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter()
  const [isActive, setIsActive] = useState(false)
  const [isExpanded, setIsExpanded] = useState(true)
  const [orgId, setOrgId] = useState<number>()
  const [legalEntity, setLegalEntity] = useState<number>()
  const [legalEntityName, setlegalEntityName] = useState<string>('')
  const [reportId, setReportId] = useState<number>()
  const [stickyFooterHeight, setStickyFooterHeight] = useState(0)
  const [activeModule, setActiveModule] = useState<moduleKeyType>('company_overview')
  const [selectedTransactions, setSelectedTransactions] = useState<number[]>()

  const [selectedTemplate, setSelectedTemplate] = useState<SelectOptions>()

  const { control, setValue, reset, getValues } = useForm<ReportWizardFieldValues>({
    defaultValues: {},
  })

  const queryClient = useQueryClient()

  const routerOrgId = router.query.orgId

  const queryEnabled = !!(legalEntity || reportId) && !!isActive && !(!selectedTemplate?.value && !reportId)

  const txnKey = (selectedTransactions || []).join('-')

  const {
    data: checklistData,
    refetch,
    isFetching,
  } = useQuery([QUERIES.GET_CHECKLIST.key, routerOrgId, reportId, legalEntity, selectedTemplate?.value, txnKey], {
    queryFn: () =>
      QUERIES.GET_CHECKLIST.function({
        entityId: legalEntity,
        localFileIndex: reportId,
        template: String(selectedTemplate?.value || '') || undefined,
        transactions: selectedTransactions,
      }),
    enabled: queryEnabled,
    onSuccess(data) {
      if (isActive) {
        if (!reportId) {
          queryClient.refetchQueries({
            type: 'active',
            queryKey: [QUERIES.GET_LEGAL_ENTITY_BY_ID.key],
          })
        }
        data.legal_entity && setLegalEntity(data.legal_entity.id)
        data.id && setReportId(data.id)
        data.transactions_order && setSelectedTransactions(data.transactions_order)
        setlegalEntityName(data.legal_entity?.name || '')
        if (data.template_name != selectedTemplate?.value) {
          setValue('template', {
            label: capitalizeFirstLetter(data.template_name),
            value: data.template_name,
          })
          setSelectedTemplate({
            label: capitalizeFirstLetter(data.template_name),
            value: data.template_name,
          })
        }
      }
    },
    onError(err) {
      getToastErrorMessage(err as AxiosError)
    },
  })

  const generating =
    checklistData?.local_file_creation_status == 'in_progress' || checklistData?.local_file_creation_status == 'pending'
  useQuery(
    [QUERIES.GET_CHECKLIST.key, routerOrgId, reportId, legalEntity, selectedTemplate?.value, txnKey, 'polling'],
    {
      queryFn: () =>
        QUERIES.GET_CHECKLIST.function({
          entityId: legalEntity,
          localFileIndex: reportId,
          template: String(selectedTemplate?.value || '') || undefined,
          transactions: selectedTransactions,
        }),
      enabled: generating,
      onSuccess(data) {
        if (
          generating &&
          data?.local_file_creation_status &&
          !(data?.local_file_creation_status == 'in_progress' || data?.local_file_creation_status == 'pending')
        ) {
          refetch()
        }
      },
      retry: 10,
      refetchInterval(data, query) {
        const pending =
          data?.local_file_creation_status == 'in_progress' || data?.local_file_creation_status == 'pending'
        const count = query.state.dataUpdateCount
        const interval = 10 * 1000
        if (pending && count < 200) {
          return interval
        } else if (!pending && count) {
          query.reset()
        }
        return false
      },
    }
  )

  const refetchChecklist = useCallback(
    async <TPageData,>(options?: RefetchOptions & RefetchQueryFilters<TPageData>) => {
      if (queryEnabled) {
        return refetch(options)
      }
    },
    [queryEnabled, refetch]
  )

  const resetState = useCallback(() => {
    setIsActive(false)
    setIsExpanded(true)
    setOrgId(undefined)
    setLegalEntity(undefined)
    setReportId(undefined)
    setActiveModule('company_overview')
    setSelectedTemplate(undefined)
    setSelectedTransactions(undefined)
    setlegalEntityName('')
    reset({
      template: {
        label: '',
        value: '',
      },
    })
  }, [reset])

  useEffect(() => {
    if (!isActive) {
      resetState()
    }
  }, [isActive, resetState])

  useEffect(() => {
    if (
      typeof orgId == 'number' &&
      !isNaN(Number(routerOrgId)) &&
      Number(routerOrgId) &&
      orgId !== Number(routerOrgId)
    ) {
      resetState()
    }
  }, [orgId, resetState, router.query.orgId, routerOrgId])

  const { data } = useQuery([QUERIES.GET_ALLOWED_TEMPLATES.key, routerOrgId], {
    queryFn: QUERIES.GET_ALLOWED_TEMPLATES.function,
    enabled: !!routerOrgId && !isNaN(Number(routerOrgId)),
  })

  const templateOptions: SelectOptions[] = useMemo(() => {
    if (!data?.length) {
      return []
    } else {
      return data.map(template => {
        return {
          label: capitalizeFirstLetter(template.label),
          value: template.name,
          additionalData: { complianceRegion: template.compliance_region },
        }
      })
    }
  }, [data])

  useEffect(() => {
    if (selectedTemplate?.value && !selectedTemplate?.additionalData?.complianceRegion && templateOptions.length) {
      const foundTemplate = templateOptions.find(opt => opt.value == selectedTemplate.value)
      if (foundTemplate) {
        setSelectedTemplate(foundTemplate)
        setValue('template', foundTemplate)
      }
    }
  }, [selectedTemplate?.additionalData?.complianceRegion, selectedTemplate?.value, setValue, templateOptions])

  const { getConfirmation, ConfirmationModal } = useConfirmationModal()

  const handleTemplateChange = useCallback(
    async (val: SelectOptions | null) => {
      if (
        val?.additionalData?.complianceRegion &&
        selectedTemplate?.additionalData?.complianceRegion &&
        val?.additionalData?.complianceRegion !== selectedTemplate?.additionalData?.complianceRegion &&
        checklistData?.transactions_order?.length
      ) {
        const confirmation = await getConfirmation(
          'Changing template will clear the previous transaction selection. Are you sure you want to proceed?'
        )
        if (!confirmation) {
          setValue('template', selectedTemplate)
        } else {
          setValue('transactions', [])
          setSelectedTemplate(getValues('template'))
        }
        return
      } else {
        setSelectedTemplate(getValues('template'))
      }
    },
    [checklistData?.transactions_order?.length, getConfirmation, getValues, selectedTemplate, setValue]
  )

  const generateLocalFileMutation = useMutation(download_local_file, {
    onError: (error: AxiosError) => {
      getToastErrorMessage(error)
    },
    onSuccess() {
      toast.success('Report added to queue!!')
      queryClient.refetchQueries([QUERIES.GET_LEGAL_ENTITY_BY_ID.key, legalEntity], { type: 'active' })
      router.push(`${ROUTES.LEGAL_ENTITY_MANAGEMENT}/${checklistData?.legal_entity?.id || state.legalEntity}`)
      setIsActive(false)
    },
  })

  const handleGenerateClick = useCallback(async () => {
    if (!selectedTransactions?.length) {
      toast.info('Please select transactions to generate a report')
      setActiveModule('transactions')
      return
    }
    if (checklistData?.edited) {
      const confirmation = await getConfirmation(
        'This report has been manually edited. You will lose the changes if you regenerate the report. Are you sure you want to proceed?'
      )
      if (!confirmation) return
    }
    reportId &&
      generateLocalFileMutation.mutate({
        reportId,
      })
  }, [checklistData?.edited, generateLocalFileMutation, getConfirmation, reportId, selectedTransactions?.length])

  const loading = generateLocalFileMutation.isLoading

  const state: ReportWizardContextType = useMemo(() => {
    return {
      orgId,
      legalEntity,
      reportId,
      isActive,
      isExpanded,
      setIsActive,
      setIsExpanded,
      setOrgId,
      setLegalEntity,
      setReportId,
      control,
      templateOptions,
      activeModule,
      setActiveModule,
      checklistData,
      refetchChecklist,
      handleGenerateClick,
      loading,
      isFetching,
      setStickyFooterHeight,
      stickyFooterHeight,
      handleTemplateChange,
      selectedTemplate,
      setSelectedTemplate,
      setSelectedTransactions,
      selectedTransactions,
      legalEntityName,
      setlegalEntityName,
      generating,
    }
  }, [
    orgId,
    legalEntity,
    reportId,
    isActive,
    isExpanded,
    control,
    templateOptions,
    activeModule,
    checklistData,
    refetchChecklist,
    handleGenerateClick,
    loading,
    isFetching,
    stickyFooterHeight,
    handleTemplateChange,
    selectedTemplate,
    selectedTransactions,
    legalEntityName,
    generating,
  ])

  return (
    <ReportWizardContext.Provider value={state}>
      {children}
      <ConfirmationModal />
    </ReportWizardContext.Provider>
  )
}

export const useReportWizardContext = () => {
  const context = useContext(ReportWizardContext)
  if (!context) {
    throw new Error('ReportWizardContext used without provider')
  }
  return context
}

export type ReportWizardFieldValues = FieldValues & {
  template?: SelectOptions
}
