import { IconProps } from 'assets'

const FileMaximize = ({ className, pathClassName = 'stroke-[#D1D5DB]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Group 2">
        <path
          className={pathClassName}
          id="Vector"
          d="M16.5008 11.5002V16.5H11.501"
          stroke="#D1D5DB"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          className={pathClassName}
          id="Vector_2"
          d="M16.4991 16.5L10.666 10.6669"
          stroke="#D1D5DB"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          className={pathClassName}
          id="Vector_3"
          d="M1.5 6.5L1.5 1.50019H6.49981"
          stroke="#D1D5DB"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          className={pathClassName}
          id="Vector_4"
          d="M1.5 1.50283L7.33311 7.33594"
          stroke="#D1D5DB"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  )
}

export default FileMaximize
