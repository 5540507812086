import { HomeWorkOutlined } from '@mui/icons-material'
import { ChecklistResponse } from 'api/documents/types'
import ContextualToken from 'assets/icons/contextualToken'
import File from 'assets/icons/file'
import TransactionManagement from 'assets/icons/transactionManagement'
import { ROUTES } from 'constants/routes'
import { ReactNode } from 'react'
import { createQueryParams } from 'utils/utils'

import { ChecklistAccordionProps } from './components/ChecklistAccordion'
import { ChecklistItemProps } from './components/ChecklistItem'
import { ChecklistParentAccordionProps } from './components/ChecklistParentAccordion'

export const getReportWizardModules = ({
  active,
  complete,
  disabled,
}: {
  active: moduleKeyType
  complete: Record<moduleKeyType, boolean>
  disabled: Partial<Record<moduleKeyType, boolean>>
}): {
  icon: ReactNode
  title: string
  completed: boolean
  active: boolean
  key: moduleKeyType
  disabled?: boolean
  disabledTooltipText?: string
}[] => {
  return [
    {
      key: 'company_overview',
      title: 'Company \nOverview',
      completed: complete.company_overview,
      disabled: disabled.company_overview,
      active: active == 'company_overview',
      icon: <ContextualToken className="w-[18px] h-[18px]" />,
    },
    {
      key: 'local_entity_overview',
      title: 'Legal Entity \nOverview',
      completed: complete.local_entity_overview,
      disabled: disabled.local_entity_overview,
      active: active == 'local_entity_overview',
      icon: <HomeWorkOutlined className="w-[18px] h-[18px] text-fuchsia-400" />,
    },
    {
      key: 'transactions',
      title: 'Transactions',
      completed: complete.transactions,
      disabled: disabled.transactions,
      disabledTooltipText: 'Please select template before selecting transactions',
      active: active == 'transactions',
      icon: <TransactionManagement pathClassName="fill-teal-400" className="w-[18px] h-[18px] " />,
    },
    {
      key: 'appendices',
      title: 'Appendices',
      completed: complete.appendices,
      disabled: disabled.appendices,
      disabledTooltipText: 'Please select transactions',
      active: active == 'appendices',
      icon: <File className="w-[18px] h-[18px]" pathClassName="fill-orange-500" />,
    },
  ]
}

export const getOverviewConfig = (
  checklistData: ChecklistResponse | undefined
): {
  tabConfig: {
    isComplete: boolean
    totalTasks: number
    completedTasks: number
  }
  listConfig: (
    | {
        type: 'expandable'
        props: ChecklistAccordionProps
      }
    | {
        type: 'single'
        props: ChecklistItemProps
      }
  )[]
} => {
  const companyOverview = checklistData?.checklist['Company Overview']
  return {
    tabConfig: {
      isComplete: !!(
        companyOverview?.['Business Strategy and Group Competitor Factors'].is_present &&
        companyOverview?.['Description of Products and Services'].is_present &&
        companyOverview?.['Group Business Overview'].is_present &&
        companyOverview?.['Group FAR Information'].is_present &&
        companyOverview?.['Industry Analysis']['Industry Description'].is_present &&
        companyOverview?.['Industry Analysis']['Industry Attributes Description'].is_present &&
        companyOverview?.['Industry Analysis']['Implication of Industry Attributes on the Controlled Transactions']
          .is_present &&
        companyOverview?.['Industry Analysis']['Industry Name'].is_present
      ),
      totalTasks: 5,
      completedTasks: [
        companyOverview?.['Business Strategy and Group Competitor Factors'].is_present,
        companyOverview?.['Description of Products and Services'].is_present,
        companyOverview?.['Group Business Overview'].is_present,
        companyOverview?.['Group FAR Information'].is_present,
        !!(
          companyOverview?.['Industry Analysis']['Industry Description'].is_present &&
          companyOverview?.['Industry Analysis']['Industry Attributes Description'].is_present &&
          companyOverview?.['Industry Analysis']['Implication of Industry Attributes on the Controlled Transactions']
            .is_present &&
          companyOverview?.['Industry Analysis']['Industry Name'].is_present
        ),
      ].filter(val => !!val).length,
    },
    listConfig: [
      {
        type: 'single',
        props: {
          added: !!companyOverview?.['Group Business Overview'].is_present,
          label: 'Organization Business Overview',
          href: ROUTES.ORGANIZATION,
        },
      },
      {
        type: 'single',
        props: {
          added: !!companyOverview?.['Description of Products and Services'].is_present,
          label: 'Description of Products & Services',
          href: ROUTES.ORGANIZATION,
        },
      },
      {
        type: 'single',
        props: {
          added: !!companyOverview?.['Business Strategy and Group Competitor Factors'].is_present,
          label: 'Business Strategy & Group Competitor Factors',
          href: ROUTES.ORGANIZATION,
        },
      },
      {
        type: 'single',
        props: {
          added: !!companyOverview?.['Group FAR Information'].is_present,
          label: 'Org FAR Overview',
          href: ROUTES.ORGANIZATION,
        },
      },
      {
        type: 'expandable',
        props: {
          added: !!(
            companyOverview?.['Industry Analysis']['Industry Description'].is_present &&
            companyOverview?.['Industry Analysis']['Industry Attributes Description'].is_present &&
            companyOverview?.['Industry Analysis']['Implication of Industry Attributes on the Controlled Transactions']
              .is_present &&
            companyOverview?.['Industry Analysis']['Industry Name'].is_present
          ),
          label: 'Industry Analysis',
          href: ROUTES.ORGANIZATION,
          isOpen: false,
          subSections: [
            {
              label: 'Industry Name',
              added: !!companyOverview?.['Industry Analysis']['Industry Name'].is_present,
              href: ROUTES.ORGANIZATION,
            },
            {
              label: 'Industry Description',
              added: !!companyOverview?.['Industry Analysis']['Industry Description'].is_present,
              href: ROUTES.ORGANIZATION,
            },
            {
              label: 'Industry Attributes',
              added: !!companyOverview?.['Industry Analysis']['Industry Attributes Description'].is_present,
              href: ROUTES.ORGANIZATION,
            },
            {
              label: 'Implication of Industry Attributes on Controlled Transaction',
              added:
                !!companyOverview?.['Industry Analysis'][
                  'Implication of Industry Attributes on the Controlled Transactions'
                ].is_present,
              href: ROUTES.ORGANIZATION,
            },
          ],
        },
      },
    ],
  }
}

export const getLEOverviewConfig = (
  checklistData: ChecklistResponse | undefined,
  leId: number | undefined
): {
  tabConfig: {
    isComplete: boolean
    totalTasks: number
    completedTasks: number
  }
  listConfig: (
    | {
        type: 'expandable'
        props: ChecklistAccordionProps
      }
    | {
        type: 'single'
        props: ChecklistItemProps
      }
  )[]
} => {
  const legalEntityCheckilist = checklistData?.checklist['Legal Entity']

  const basicInfoComplete = !!legalEntityCheckilist?.['Basic Info'].is_present
  const managementStructureComplete = !!(
    legalEntityCheckilist?.['Management Structure']['Corporate/Group Legal Entity Organization Chart'].is_present &&
    legalEntityCheckilist?.['Management Structure']['Legal Entity Ownership Chart'].is_present &&
    legalEntityCheckilist?.['Management Structure']['Team Structure'].is_present
  )

  const coreBusionessInfoComplete = !!(
    legalEntityCheckilist?.['Core Business Info']['Business Activities Description'].is_present &&
    legalEntityCheckilist?.['Core Business Info'][
      'Business Restructuring, Intangibles Transfers and Other Relevant Considerations'
    ].is_present &&
    legalEntityCheckilist?.['Core Business Info']['Entity FAR Information'].is_present &&
    legalEntityCheckilist?.['Core Business Info']['Key Competitors'].is_present &&
    legalEntityCheckilist?.['Core Business Info']['Local Industry Analysis'].is_present &&
    legalEntityCheckilist?.['Core Business Info']['Local Market Business Strategy'].is_present &&
    legalEntityCheckilist?.['Core Business Info']['Local Market and Economic Circumstances'].is_present &&
    legalEntityCheckilist?.['Core Business Info']['Tax Structure'].is_present
  )
  return {
    tabConfig: {
      isComplete: !!(basicInfoComplete && managementStructureComplete && coreBusionessInfoComplete),
      totalTasks: 3,
      completedTasks: [basicInfoComplete, managementStructureComplete, coreBusionessInfoComplete].filter(val => !!val)
        .length,
    },
    listConfig: [
      {
        type: 'single',
        props: {
          added: !!basicInfoComplete,
          label: 'Basic Legal Entity Info',
          href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
            entityId: leId,
            activeLEInfoTab: 0,
          })}`,
        },
      },
      {
        type: 'expandable',
        props: {
          added: !!managementStructureComplete,
          label: 'Management Structure',
          href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
            entityId: leId,
            activeLEInfoTab: 1,
          })}`,
          isOpen: false,
          subSections: [
            {
              label: 'LE ownership chart',
              added: !!legalEntityCheckilist?.['Management Structure']['Legal Entity Ownership Chart'].is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 1,
              })}`,
            },
            {
              label: 'Corporate/Group Legal Entity Organization Chart',
              added:
                !!legalEntityCheckilist?.['Management Structure']['Corporate/Group Legal Entity Organization Chart']
                  .is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 1,
              })}`,
            },
            {
              label: 'Team Structure',
              added: !!legalEntityCheckilist?.['Management Structure']['Team Structure'].is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 1,
              })}`,
            },
          ],
        },
      },
      {
        type: 'expandable',
        props: {
          added: !!coreBusionessInfoComplete,
          label: 'Core Business info',
          href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
            entityId: leId,
            activeLEInfoTab: 2,
          })}`,
          isOpen: false,
          subSections: [
            {
              label: 'Business Activities Description',
              added: !!legalEntityCheckilist?.['Core Business Info']['Business Activities Description'].is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 2,
              })}`,
            },
            {
              label: 'Local Market Economic Circumstances',
              added:
                !!legalEntityCheckilist?.['Core Business Info']['Local Market and Economic Circumstances'].is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 2,
              })}`,
            },
            {
              label: 'Industry Analysis',
              added: !!legalEntityCheckilist?.['Core Business Info']['Local Industry Analysis'].is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 2,
              })}`,
            },
            {
              label: 'Key Competitors',
              added: !!legalEntityCheckilist?.['Core Business Info']['Key Competitors'].is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 2,
              })}`,
            },
            {
              label: 'Local Market Business Strategy',
              added: !!legalEntityCheckilist?.['Core Business Info']['Local Market Business Strategy'].is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 2,
              })}`,
            },
            {
              label: 'Business Restructuring, Intangibles Transfers & Other Relevant Considerations',
              added:
                !!legalEntityCheckilist?.['Core Business Info'][
                  'Business Restructuring, Intangibles Transfers and Other Relevant Considerations'
                ].is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 2,
              })}`,
            },
            {
              label: 'Tax Structures',
              added: !!legalEntityCheckilist?.['Core Business Info']['Tax Structure'].is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 2,
              })}`,
            },
            {
              label: 'Entity FAR Information',
              added: !!legalEntityCheckilist?.['Core Business Info']['Entity FAR Information'].is_present,
              href: `${ROUTES.CREATE_LEGAL_ENTITY}?${createQueryParams({
                entityId: leId,
                activeLEInfoTab: 2,
              })}`,
            },
          ],
        },
      },
    ],
  }
}

export const getTransactionConfig = (
  checklistData: ChecklistResponse | undefined
): {
  tabConfig: {
    isComplete: boolean
    totalTasks: number
    completedTasks: number
  }
  listConfig: (ChecklistParentAccordionProps & {
    subSections: (
      | {
          type: 'expandable'
          props: ChecklistAccordionProps
          hidden?: boolean
        }
      | {
          type: 'single'
          props: ChecklistItemProps
          hidden?: boolean
        }
    )[]
  })[]
} => {
  const tabConfig: {
    isComplete: boolean
    totalTasks: number
    completedTasks: number
  } = {
    isComplete: true,
    totalTasks: 0,
    completedTasks: 0,
  }
  const listConfig: (ChecklistParentAccordionProps & {
    subSections: (
      | {
          type: 'expandable'
          props: ChecklistAccordionProps
          hidden?: boolean
        }
      | {
          type: 'single'
          props: ChecklistItemProps
          hidden?: boolean
        }
    )[]
  })[] = []
  const txnChecklist = checklistData?.checklist['Controlled Transactions']

  if (txnChecklist?.length) {
    txnChecklist.forEach(txn => {
      const txnData: ChecklistParentAccordionProps & {
        subSections: (
          | {
              type: 'expandable'
              props: ChecklistAccordionProps
              hidden?: boolean
            }
          | {
              type: 'single'
              props: ChecklistItemProps
              hidden?: boolean
            }
        )[]
      } = {
        added: false,
        isOpen: false,
        label: txn.name,
        subSections: [
          {
            type: 'single',
            props: {
              added: !!txn['Basic Info'].is_present,
              label: 'Transaction Basic Info',
              href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                transactionId: txn?.id,
                activeTab: 'basicInfo',
              })}`,
            },
          },
          {
            type: 'single',
            props: {
              added: !!txn['Transaction Description'].is_present,
              label: 'Transaction Description',
              href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                transactionId: txn?.id,
                activeTab: 'basicInfo',
              })}`,
            },
          },
          {
            type: 'single',
            props: {
              added: !!txn['Intercompany Flows/Agreement'].is_present,
              label: 'Intercompany Flows and Agreements',
              href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                transactionId: txn?.id,
                activeTab: 'intercompanyAgreement',
              })}`,
            },
          },
          {
            type: 'expandable',
            props: {
              added: false,
              label: 'Functional Analysis',
              href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                transactionId: txn?.id,
                activeTab: 'functionalAnalysis',
              })}`,
              isOpen: false,
              subSections: [
                {
                  label: 'Functions',
                  added: !!txn['Functional Analysis'].Functions.length,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'functionalAnalysis',
                  })}`,
                },
                {
                  label: 'Assets',
                  added: !!txn['Functional Analysis'].Assets.length,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'functionalAnalysis',
                  })}`,
                },
                {
                  label: 'Risks',
                  added: !!txn['Functional Analysis'].Risks.length,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'functionalAnalysis',
                  })}`,
                },
                {
                  label: 'Conclusion',
                  added: !!txn['Functional Analysis'].Conclusion.is_present,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'functionalAnalysis',
                  })}`,
                },
              ],
            },
          },
          {
            type: 'expandable',
            props: {
              added: false,
              label: 'Economic Analysis',
              href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                transactionId: txn?.id,
                activeTab: 'economicAnalysis',
              })}`,
              isOpen: false,
              subSections: [
                {
                  label: 'Basic Info',
                  added: !!txn['Economic Analysis']['Basic Info'],
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'economicAnalysis',
                  })}`,
                },
                {
                  label: 'Method Selection',
                  added: !!txn['Economic Analysis']['Method Selection'].is_present,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'economicAnalysis',
                  })}`,
                },
                {
                  label: 'Tested Party (or Parties) Financials',
                  hidden: typeof txn['Economic Analysis']['Financial Data']?.is_present !== 'boolean',
                  added: !!txn['Economic Analysis']['Financial Data']?.is_present,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'economicAnalysis',
                  })}`,
                },
                {
                  label: 'Tested Party (or Parties) Benchmarking Analysis',
                  hidden: typeof txn['Economic Analysis']['Benchmarking Analysis']?.is_present !== 'boolean',
                  added: !!txn['Economic Analysis']['Benchmarking Analysis']?.is_present,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'economicAnalysis',
                  })}`,
                },
                {
                  label: 'Comparability Factor Selection',
                  hidden:
                    typeof txn['Economic Analysis']['Comparability Factors']?.['Comparability Factors'].is_present !==
                    'boolean',
                  added: !!txn['Economic Analysis']['Comparability Factors']?.['Comparability Factors'].is_present,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'economicAnalysis',
                  })}`,
                },
                {
                  label: 'Controlled Transaction Data',
                  hidden:
                    typeof txn['Economic Analysis']['Comparability Factors']?.['Controlled Transactions'].is_present !==
                    'boolean',
                  added: !!txn['Economic Analysis']['Comparability Factors']?.['Controlled Transactions'].is_present,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'economicAnalysis',
                  })}`,
                },
                {
                  label: 'Uncontrolled Transaction Data',
                  hidden:
                    typeof txn['Economic Analysis']['Comparability Factors']?.['Uncontrolled Transactions']
                      .is_present !== 'boolean',
                  added: !!txn['Economic Analysis']['Comparability Factors']?.['Uncontrolled Transactions'].is_present,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'economicAnalysis',
                  })}`,
                },
                {
                  label: 'Conclusion Results',
                  hidden:
                    typeof txn['Economic Analysis']['Comparability Factors']?.['Conclusion Result'].is_present !==
                    'boolean',
                  added: !!txn['Economic Analysis']['Comparability Factors']?.['Conclusion Result'].is_present,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'economicAnalysis',
                  })}`,
                },
                {
                  label: 'Conclusion',
                  added: !!txn['Economic Analysis'].Conclusion.is_present,
                  href: `${ROUTES.CREATE_TRANSACTION}/?${createQueryParams({
                    transactionId: txn?.id,
                    activeTab: 'economicAnalysis',
                  })}`,
                },
              ],
            },
          },
        ],
        href: `${ROUTES.TRANSACTION_MANAGEMENT}/${txn?.id}`,
      }
      let allAdded = true
      txnData.subSections.forEach(section => {
        if (section.hidden) return
        if (section.type === 'expandable') {
          let childrenAdded = true
          section.props.subSections.forEach(item => {
            if (!childrenAdded || item.hidden) return
            childrenAdded = childrenAdded && item.added
          })
          section.props.added = childrenAdded
          allAdded = allAdded && childrenAdded
        } else {
          allAdded = allAdded && section.props.added
        }
      })
      txnData.added = allAdded
      tabConfig.totalTasks = tabConfig.totalTasks + 1
      tabConfig.isComplete = tabConfig.isComplete && allAdded
      if (allAdded) {
        tabConfig.completedTasks = tabConfig.completedTasks + 1
      }
      listConfig.push(txnData)
    })
  } else {
    tabConfig.isComplete = false
  }

  return {
    tabConfig,
    listConfig,
  }
}

export const getAppendicesConfig = (checklistData: ChecklistResponse | undefined) => {
  function hasIsPresentTrue(jsonString: string): boolean {
    const regex = /"is_present"\s*:\s*true/
    return regex.test(jsonString)
  }

  return {
    'Relevant Tax Rulings': {
      hidden: !checklistData?.checklist.Appendices['Relevant Tax Rulings'],
      data: checklistData?.checklist.Appendices['Relevant Tax Rulings'],
    },
    'Legal Entity': {
      hidden: !hasIsPresentTrue(JSON.stringify(checklistData?.checklist.Appendices['Legal Entity'] || {})),
      data: checklistData?.checklist.Appendices['Legal Entity'],
    },
    Transactions: {
      hidden: !hasIsPresentTrue(JSON.stringify(checklistData?.checklist.Appendices.Transactions || {})),
      data: checklistData?.checklist.Appendices.Transactions.filter(txn => hasIsPresentTrue(JSON.stringify(txn))),
    },
    'Other Appendices': {
      hidden: false,
      data: checklistData?.checklist.Appendices['Other Appendices'],
    },
  }
}

export type moduleKeyType = 'company_overview' | 'local_entity_overview' | 'transactions' | 'appendices'
