import { IconProps } from 'assets'
import React from 'react'

const LoadingThree = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 22C18.0229 22 22.5 17.5229 22.5 12C22.5 6.47715 18.0229 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5229 6.97715 22 12.5 22Z"
        className={pathClassName}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.5 6V7.5" className={pathClassName} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M16.7423 7.75781L15.6816 8.81846"
        className={pathClassName}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M18.5 12H17" className={pathClassName} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M16.7423 16.2423L15.6816 15.1816"
        className={pathClassName}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.5 18V16.5" className={pathClassName} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.25781 16.2423L9.31846 15.1816"
        className={pathClassName}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.5 12H8" className={pathClassName} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.25781 7.75781L9.31846 8.81846"
        className={pathClassName}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LoadingThree
