import { Row, RowSelectionState } from '@tanstack/react-table'
import { TransactionResponse } from 'api/transactions/getTransaction/types'
import NewTable from 'components/newTable'
import { EMPTY_ARRAY } from 'constants/app.constants'
import { ReactNode, useMemo } from 'react'

import { columns } from './listTable.column'

const ListTable = ({
  transactionsData,
  isDataLoading = false,
  height = '18.75rem',
  setSelectedRows,
  onRowSelectionChange,
  enableRowSelection,
  rowSelection,
  noEntryText,
}: ListTableProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const transactionsColumns = useMemo(() => columns(), EMPTY_ARRAY)

  return (
    <div className="mt-3 w-full">
      <NewTable
        setSelectedRows={setSelectedRows}
        height={height}
        columns={transactionsColumns}
        data={transactionsData}
        enableRowSelection={enableRowSelection}
        isDataLoading={isDataLoading}
        rowSelection={rowSelection}
        onRowSelectionChange={onRowSelectionChange}
        noEntryText={noEntryText}
      />
    </div>
  )
}

interface ListTableProps {
  isDataLoading?: boolean
  transactionsData: TransactionResponse[]
  height?: string
  enableRowSelection?: boolean | ((row: Row<TransactionResponse>) => boolean)
  setSelectedRows?: React.Dispatch<React.SetStateAction<Row<TransactionResponse>[]>>
  onRowSelectionChange?: React.Dispatch<React.SetStateAction<RowSelectionState>>
  rowSelection?: RowSelectionState
  noEntryText?: ReactNode
}

export default ListTable
