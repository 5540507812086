import { IconsType } from 'assets/types'
import Button, { ButtonVariant } from 'components/button'
import IconPlaceHolder from 'components/iconPlaceholder'
import Typography, { Variant } from 'components/typography'
import React, { ForwardedRef } from 'react'

const UploadBox = (props: UploadBoxProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { label, handleClick, allowedExtensions } = props

  return (
    <div className="w-full">
      {label && (
        <Typography variant={Variant.ContainerText} type="semibold" className="mb-[0.5rem] text-slate-600">
          {label}
        </Typography>
      )}
      <div className=" rounded-xl  bg-neutral-50 flex items-center px-3 py-2">
        <IconPlaceHolder icon={IconsType.upload} className="!bg-blue-100 h-9 w-9" />
        <div onClick={handleClick} ref={ref} className="flex flex-col ms-2 items-start cursor-pointer">
          <Button className="p-[0rem]" variant={ButtonVariant.Tertiary}>
            Click to Upload
          </Button>
          <Typography className="pt-0" variant={Variant.Body}>
            {`${allowedExtensions} Max 5MB`}
          </Typography>
        </div>
      </div>
    </div>
  )
}

interface UploadBoxProps {
  label?: string
  allowedExtensions: string
  handleClick: VoidFunction
  ref: React.RefObject<HTMLDivElement>
}

export default React.forwardRef(UploadBox)
