import { IconProps } from 'assets'
import React from 'react'

const ContractDelete = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="contract_delete">
        <path
          className={pathClassName}
          id="Vector"
          d="M19 19.425L16.875 21.525L15.475 20.125L17.575 18L15.475 15.875L16.875 14.475L19 16.575L21.125 14.475L22.525 15.875L20.425 18L22.525 20.125L21.125 21.525L19 19.425ZM6 22C5.16667 22 4.45833 21.7083 3.875 21.125C3.29167 20.5417 3 19.8333 3 19V16H6V2H21V12.375C20.6833 12.2583 20.3583 12.1708 20.025 12.1125C19.6917 12.0542 19.35 12.025 19 12.025V4H8V16H13.35C13.2333 16.3167 13.1458 16.6417 13.0875 16.975C13.0292 17.3083 13 17.65 13 18H5V19C5 19.2833 5.09583 19.5208 5.2875 19.7125C5.47917 19.9042 5.71667 20 6 20H13.35C13.4833 20.3833 13.65 20.7417 13.85 21.075C14.05 21.4083 14.2833 21.7167 14.55 22H6ZM9 9V7H18V9H9ZM9 12V10H18V12H9Z"
          fill="black"
        />
      </g>
    </svg>
  )
}

export default ContractDelete
