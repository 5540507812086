import { useQuery } from '@tanstack/react-query'
import { TransactionResponse } from 'api/transactions/getTransaction/types'
import { QUERIES } from 'constants/query'
import { useErrorHandler } from 'hooks/useErrorHandler'
export const useAllTransactionList = (
  enabled = true,
  year?: number,
  filters?: FilterTransactionProps,
  additionalKeys?: string[]
) => {
  const queryKey = [QUERIES.USE_ALL_TRANSACTION_LIST.key, year]
  if (additionalKeys) {
    additionalKeys.forEach(k => {
      queryKey.push(k)
    })
  }

  const {
    data: transactionsList,
    isLoading,
    refetch,
    isFetched,
    isError,
    error,
  } = useQuery<TransactionResponse[]>(queryKey, {
    queryFn: () => QUERIES.USE_ALL_TRANSACTION_LIST.function(year, filters),
    enabled: enabled,
  })

  useErrorHandler({ isError, error, enabled: true })

  return { transactionsList, refetch, isLoading, isFetched }
}

interface FilterTransactionProps {
  legal_entity?: number
  intercompany_agreement?: number
  compliance_region?: string
}
