import { ColumnDef } from '@tanstack/react-table'
import { TransactionResponse } from 'api/transactions/getTransaction/types'
import Checkbox from 'components/checkbox'
import IconWithText from 'components/iconWithText'
import SelectCheckbox from 'components/table/components/selectCheckbox'
import { fuzzySort } from 'components/table/helpers'
import Typography, { Variant } from 'components/typography'
import TransactionPartyCell from 'views/transactions/components/transactionPartyCell'
import TxnNameCell from 'views/transactions/components/txnNameCell'

export const columns = () => {
  const columns: ColumnDef<TransactionResponse>[] = [
    {
      header: ({ table }) => (
        <Checkbox
          id="listTable-transaction-selectAll"
          name="listTable-transaction-selectAll"
          label=""
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      id: 'action',
      size: 64,
      cell: ({ row }) => <SelectCheckbox row={row} />,
    },
    {
      header: 'TXN Name',
      id: 'name',
      accessorKey: 'name',
      sortingFn: fuzzySort,
      size: 170,
      cell: data => <TxnNameCell disableClick data={data.row.original} />,
    },
    {
      header: 'TXN Type',
      id: 'transaction_type',
      accessorKey: 'transaction_type',
      sortingFn: fuzzySort,
      cell: data => {
        return <IconWithText className="ml-2" type={String(data.getValue())} />
      },
    },
    {
      header: 'Reporting Party',
      accessorFn: original => {
        return original?.primary_entity?.name || original?.source_characterisation
      },
      size: 200,
      sortingFn: fuzzySort,
      cell: data => {
        const partyIdentification = data.row.original.is_primary_le_source
          ? data.row.original?.functional_profile.source_party_identification
          : data.row.original?.functional_profile.target_party_identification
        return (
          <TransactionPartyCell
            partyIdentification={partyIdentification}
            entities={[data.row.original.primary_entity]}
            transactionNature={data.row.original.functional_profile?.transaction_nature}
          />
        )
      },
    },
    {
      header: 'Related Party',
      id: 'individual_transactions.name',
      sortingFn: fuzzySort,
      accessorKey: 'individual_transactions',
      accessorFn: data => data.individual_transactions[0].associated_entity.name,
      size: 200,
      cell: data => {
        const partyIdentification = data.row.original.is_primary_le_source
          ? data.row.original?.functional_profile.target_party_identification
          : data.row.original?.functional_profile.source_party_identification

        return (
          <TransactionPartyCell
            partyIdentification={partyIdentification}
            entities={data.row.original.individual_transactions.map(txn => txn.associated_entity)}
            transactionNature={data.row.original.functional_profile?.transaction_nature}
          />
        )
      },
    },
    {
      header: 'TP Method',
      id: 'economic_analysis.transfer_pricing_method_abbreviation',
      accessorKey: 'economic_analysis.transfer_pricing_method_abbreviation',
      accessorFn: data => {
        return data.economic_analysis?.transfer_pricing_method_abbreviation || 'NA'
      },
      sortingFn: fuzzySort,
      cell: data => {
        return (
          <Typography type="semibold" variant={Variant.Callout}>
            {String(data.getValue())}
          </Typography>
        )
      },
    },
  ]
  return columns
}

export const selectedTransactionsColumns = () => {
  const columns: ColumnDef<TransactionResponse>[] = [
    {
      header: 'TXN Name',
      id: 'name',
      accessorKey: 'name',
      sortingFn: fuzzySort,
      size: 170,
      cell: data => <TxnNameCell disableClick data={data.row.original} />,
    },
    {
      header: 'TXN Type',
      id: 'transaction_type',
      accessorKey: 'transaction_type',
      sortingFn: fuzzySort,
      cell: data => {
        return <IconWithText className="ml-2" type={String(data.getValue())} />
      },
    },
    {
      header: 'Reporting Party',
      accessorFn: original => {
        return original?.primary_entity?.name || original?.source_characterisation
      },
      size: 200,
      sortingFn: fuzzySort,
      cell: data => {
        const partyIdentification = data.row.original.is_primary_le_source
          ? data.row.original?.functional_profile.source_party_identification
          : data.row.original?.functional_profile.target_party_identification
        return (
          <TransactionPartyCell
            partyIdentification={partyIdentification}
            entities={[data.row.original.primary_entity]}
            transactionNature={data.row.original.functional_profile?.transaction_nature}
          />
        )
      },
    },
    {
      header: 'Related Party',
      id: 'individual_transactions.name',
      sortingFn: fuzzySort,
      accessorKey: 'individual_transactions',
      accessorFn: data => data.individual_transactions[0].associated_entity.name,
      size: 200,
      cell: data => {
        const partyIdentification = data.row.original.is_primary_le_source
          ? data.row.original?.functional_profile.target_party_identification
          : data.row.original?.functional_profile.source_party_identification

        return (
          <TransactionPartyCell
            partyIdentification={partyIdentification}
            entities={data.row.original.individual_transactions.map(txn => txn.associated_entity)}
            transactionNature={data.row.original.functional_profile?.transaction_nature}
          />
        )
      },
    },
    {
      header: 'TP Method',
      id: 'economic_analysis.transfer_pricing_method_abbreviation',
      accessorKey: 'economic_analysis.transfer_pricing_method_abbreviation',
      accessorFn: data => {
        return data.economic_analysis?.transfer_pricing_method_abbreviation || 'NA'
      },
      sortingFn: fuzzySort,
      cell: data => {
        return (
          <Typography type="semibold" variant={Variant.Callout}>
            {String(data.getValue())}
          </Typography>
        )
      },
    },
  ]
  return columns
}
