import { IconProps } from 'assets'
import React from 'react'

const Compare = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M10 23V21H5C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H10V1H12V23H10ZM5 19H10V17H7V15H10V13H7V11H10V9H7V7H10V5H5V19ZM14 5V3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H14V19H19V5H14ZM14 13V11H17V13H14ZM14 9V7H17V9H14Z"
        fill="black"
      />
    </svg>
  )
}

export default Compare
