/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pagination, PaginationItem, Skeleton } from '@mui/material'
import {
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  RowSelectionState,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import Fuse from 'fuse.js'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { getRemValue } from 'utils/utils'

import ColumnSelectionModal from './components/columnSelectionModal/ColumnSelectionModal'
import NoEntryFoundContainer from './components/noEntryFoundContainer/NoEntryFoundContainer'
import TableHeadRow from './components/tableHeadRow'
import TableRow from './components/tableRow/TableRow'

const DEFAULT_PAGINATION_SIZE = 10

function NewTable<T>({
  columns,
  data = [],
  showHeader = true,
  borderedRows = true,
  globalFilter,
  sortPathClassname,
  classes,
  setGlobalFilter,
  isSort = true,
  enableRowSelection = true,
  pageSize = DEFAULT_PAGINATION_SIZE,
  isPaginationEnabled = false,
  isDataLoading = false,
  tableHeadClassName,
  tableRowClassName,
  handlePaginationClick,
  totalCount,
  height,
  setSelectedRows,
  onRowSelectionChange,
  rowSelection,
  reorderRow,
  title,
  showFilterModal,
  toggleFilterModal,
  initialVisibleColumns,
  noEntryText,
  tableCellClassname,
}: TableProps<T>) {
  const [page, setPage] = useState(0)
  const fuzzyFilter: FilterFn<T> = (row, columnId, value) => {
    let columnIds = []
    if (columnId.includes('|')) {
      columnIds = [...columnId.split('|')]
    } else {
      columnIds.push(columnId)
    }
    const fuse = new Fuse([row.original], {
      keys: [...columnIds],
      threshold: 0.3,
      includeMatches: true,
    })

    const matches = fuse.search(value)

    if (matches.length === 0) {
      return false
    }

    return true
  }

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [sorting, setSorting] = useState<SortingState>([])

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    event.preventDefault()
    event.stopPropagation()
    setPage(value - 1)
    handlePaginationClick && handlePaginationClick(value - 1)
  }

  const table = useReactTable({
    data,
    columns,
    pageCount: data.length,
    initialState: {
      pagination: { pageIndex: page, pageSize },
      globalFilter,
      columnVisibility: initialVisibleColumns,
    },
    state: {
      columnFilters,
      sorting,
      globalFilter,
      rowSelection,
    },
    enableRowSelection: enableRowSelection,
    onRowSelectionChange: onRowSelectionChange,
    enableGlobalFilter: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    manualPagination: !isPaginationEnabled,
  })

  useEffect(() => {
    if (isPaginationEnabled)
      table.setPagination({
        pageIndex: page,
        pageSize,
      })
  }, [isPaginationEnabled, page, table, pageSize])

  useEffect(() => {
    if (setSelectedRows && table?.getSelectedRowModel()?.flatRows) {
      setSelectedRows(table?.getSelectedRowModel().flatRows as any)
    }
  }, [setSelectedRows, table, rowSelection])

  const preTotalCount = totalCount
    ? Math.ceil(totalCount / pageSize)
    : Math.ceil(table.getPrePaginationRowModel().rows.length / pageSize)

  const onCloseFilter = useCallback(() => {
    toggleFilterModal && toggleFilterModal()
  }, [toggleFilterModal])

  return (
    <div className={`w-full ${classes?.root || ''}`}>
      {/* Table Container */}
      <ColumnSelectionModal tableInstance={table} showModal={!!showFilterModal} toggleModal={onCloseFilter} />
      <div
        style={{ maxHeight: height ? `${height}` : 'auto' }}
        className={`relative w-full overflow-auto ${classes?.container || ''}`}>
        {title && (
          <div
            className={cx(
              'bg-blue50 h-11 flex flex-col items-center justify-center border-0 border-solid border-neutral300 border-b sticky top-0'
            )}>
            <Typography variant={Variant.Callout} type="semibold">
              {title}
            </Typography>
          </div>
        )}
        <table
          rules="none"
          className={`w-full border-separate border-spacing-x-0 border-spacing-y-1.5 ${classes?.table?.root || ''}`}>
          {showHeader && (
            <thead
              className={cx(
                height && 'sticky top-0 z-10 shadow-sm',
                'border border-solid border-neutral300',
                classes?.table?.head
              )}>
              {table.getHeaderGroups().map(headerGroup => (
                <TableHeadRow
                  sortPathClassname={sortPathClassname}
                  tableHeadClassName={tableHeadClassName}
                  key={headerGroup.id}
                  headerGroup={headerGroup}
                  reorderRow={reorderRow}
                  isSort={isSort}
                />
              ))}
            </thead>
          )}
          {isDataLoading ? (
            <tbody className={classes?.table?.body}>
              {/* Render skeleton rows */}
              {Array.from({ length: pageSize }).map((_, index) => (
                <tr key={index}>
                  {columns.map(column => (
                    <td key={column.id} className="h-[4rem] p-4">
                      <Skeleton height={2 * getRemValue()} />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ) : table.getRowModel()?.rows.length ? (
            <tbody className={classes?.table?.body}>
              {/* Render actual data rows */}
              {table.getRowModel()?.rows?.map(row => (
                <TableRow
                  key={row.id}
                  row={row}
                  borderedRows={borderedRows}
                  reorderRow={reorderRow}
                  tableRowClassName={tableRowClassName}
                  tableCellClassname={tableCellClassname}
                />
              ))}
            </tbody>
          ) : (
            // Render "No entries found" message
            <NoEntryFoundContainer
              noEntryText={noEntryText}
              columns={columns}
              classes={{ body: classes?.table?.body }}
            />
          )}
        </table>
      </div>
      {/* Pagination Container */}
      {isPaginationEnabled && !!preTotalCount && (
        <div className="flex w-full bg-white">
          <Pagination
            shape="rounded"
            count={preTotalCount}
            page={page + 1}
            className="w-full justify-end flex my-2"
            sx={{
              '& .MuiPaginationItem-root': {
                color: '#1E3A8A',
                '&.Mui-selected': {
                  background: '#DBEAFE',
                  borderRadius: '0.5rem',
                },
              },
            }}
            renderItem={item => <PaginationItem {...item} classes={{ text: 'text-container-text font-semibold' }} />}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  )
}

interface TableProps<T> {
  columns: ColumnDef<T, unknown>[]
  data: T[]
  pageSize?: number
  showHeader?: boolean
  borderedRows?: boolean
  globalFilter?: string
  setGlobalFilter?: React.Dispatch<React.SetStateAction<string>>
  enableRowSelection?: boolean | ((row: Row<T>) => boolean)
  reorderRow?: (draggedRowIndex: number, targetRowIndex: number) => void
  isDataLoading?: boolean
  isSearchEnabled?: boolean
  isPaginationEnabled?: boolean
  tableHeadClassName?: string
  tableRowClassName?: string
  handlePaginationClick?: (page: number) => void
  totalCount?: number
  height?: string
  classes?: Partial<{ root?: string; container?: string; table?: { root?: string; body?: string; head?: string } }>
  setSelectedRows?: React.Dispatch<React.SetStateAction<Row<T>[]>>
  onRowSelectionChange?: React.Dispatch<React.SetStateAction<RowSelectionState>>
  rowSelection?: RowSelectionState
  title?: string
  showFilterModal?: boolean
  toggleFilterModal?: VoidFunction
  initialVisibleColumns?: VisibilityState | undefined
  isSort?: boolean
  noEntryText?: ReactNode
  tableCellClassname?: string
  sortPathClassname?: string
}

export default NewTable
