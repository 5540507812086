import cx from 'classnames'
import { InputVariant } from 'components/input'
import DescriptiveText from 'components/input/components/descriptiveText'
import Typography, { Variant } from 'components/typography'
import _get from 'lodash/get'
import React, { useMemo } from 'react'
import { Control, Controller, FieldError, FieldErrorsImpl, FieldValues, Merge, useFormState } from 'react-hook-form'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { formatNumberStringToUS } from 'utils/numberUtils'

import { selectStyle } from './styles'

function INPUT_WITH_ACCORDION<T>({
  control,
  label,
  id,
  placeholder = 'Select...',
  inputDefaultValue,
  dropdownPlaceholder,
  dropdownDefaultValue,
  options,
  textId,
  required,
  labelClassName,
  disableDropdown,
  newInputWithAccordian = false,
}: InputWithAccordion<T & FieldValues>) {
  const animatedComponents = makeAnimated()

  const { errors } = useFormState({
    control,
  })

  const descriptiveText = useMemo(() => {
    const dropdownError = _get(errors, `${String(id)}`) as
      | FieldError
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | Merge<FieldError, FieldErrorsImpl<any>>
      | undefined
    const dropdownValueError = _get(errors, `${String(id)}.value`) as
      | FieldError
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | Merge<FieldError, FieldErrorsImpl<any>>
      | undefined

    return (
      _get(errors, `${String(textId)}`)?.message?.toString() ||
      dropdownError?.message?.toString() ||
      dropdownValueError?.message?.toString() ||
      ''
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, id, textId, JSON.stringify(errors)])
  const parseNumberString = (input: string) => {
    return formatNumberStringToUS(input).replace(/([^0-9.+-])+/g, '')
  }
  return (
    <div className="flex flex-col items-start w-full">
      {(label || required) && newInputWithAccordian ? (
        <Typography
          variant={Variant.ContainerText}
          type="semibold"
          className={cx('mb-[0.125rem] flex items-center text-slate-600', labelClassName)}>
          <span className="my-1">{label}</span>
          {required && <span className="mb-2 text-red-500">*</span>}
        </Typography>
      ) : (
        <Typography
          variant={Variant.Callout}
          type="semibold"
          className={cx('mb-[0.125rem] flex items-center', labelClassName)}>
          <span className="my-1">{label}</span>
          {required && <span className="mb-2 text-red-500">*</span>}
        </Typography>
      )}
      <div
        className={cx(
          'flex items-center border border-solid rounded-xl p-1 h-[2.375rem] w-full',
          !!descriptiveText ? '!bg-red50 !border-red500' : '!border-neutral300 bg-white'
        )}>
        <Controller
          control={control}
          defaultValue={dropdownDefaultValue}
          name={id as string}
          render={({ field: { onBlur, onChange, value, ref } }) => {
            return (
              <Select
                ref={ref}
                // isRtl
                onChange={onChange}
                value={value}
                options={options}
                onBlur={onBlur}
                menuPosition="absolute"
                menuPlacement="auto"
                isDisabled={disableDropdown}
                components={animatedComponents}
                styles={selectStyle(!!descriptiveText, disableDropdown)}
                className={cx({ 'w-[125px] !p-0 !gap-0': !disableDropdown, 'w-[80px] mr-2': disableDropdown })}
                placeholder={dropdownPlaceholder}
              />
            )
          }}
        />
        <Controller
          defaultValue={inputDefaultValue}
          control={control}
          name={textId as string}
          render={({ field: { onBlur, onChange, value, ref } }) => {
            const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
              const numberValue = parseNumberString(e.target.value)

              onChange(numberValue)
            }
            const handleBlur = () => {
              onBlur()
            }

            return (
              <input
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                value={formatNumberStringToUS(value || '')}
                ref={ref}
                className="border-0 bg-inherit focus:outline-none focus:ring-0 w-full"
              />
            )
          }}
        />
      </div>
      {!!descriptiveText && (
        <DescriptiveText variant={InputVariant.Default} descriptiveText={descriptiveText} error={!!descriptiveText} />
      )}
    </div>
  )
}

interface InputWithAccordion<T extends FieldValues> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FieldValues | T | any>
  label: string
  id: keyof T
  placeholder?: string
  inputDefaultValue?: string
  dropdownPlaceholder?: string
  dropdownDefaultValue?: string
  options?: { value: string; label: string }[]
  textId?: keyof T
  required?: boolean
  labelClassName?: string
  disableDropdown?: boolean
  newInputWithAccordian?: boolean
}

export default INPUT_WITH_ACCORDION
