import { useMutation } from '@tanstack/react-query'
import { uploadBulkAppendices } from 'api/legalEntity/appendixFiles'
import { UpdateAppendixFile } from 'api/legalEntity/types/appendices'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import Button from 'components/button'
import DocumentPreviewModal from 'components/documentPreviewModal'
import Loading from 'components/loading'
import Modal from 'components/modal'
import NewTable from 'components/newTable'
import Typography, { Variant } from 'components/typography'
import UploadMultipleDocuments from 'components/uploadMutipleDocuments'
import { EMPTY_ARRAY } from 'constants/app.constants'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import _map from 'lodash/map'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { getToastErrorMessage } from 'utils/utils'

import { getUploadAppendixColumns } from './columns'

const UploadOtherFilesModal = ({ isOpen, onClose }: UploadOtherFilesModalProps) => {
  const { refetchChecklist, legalEntity } = useReportWizardContext()

  const [files, setFiles] = useState<File[]>([])

  const [isUploadDocumentsLoading, setIsUploadDocumentsLoading] = useState<boolean>(false)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showInFullScreen, setShowInFullScreen] = useState<boolean>(false)
  const [previewUrl, setPreviewUrl] = useState<string>()
  const { control, getValues, setValue } = useForm<Record<string, string>>({})

  const filteredFiles = useMemo(() => {
    const getFilesObjectArray = (filesArray: File[]) =>
      filesArray.map(file => {
        return {
          id: `${file.name}-${file.size}-${file.type}`.replace(/[^a-zA-Z0-9]/g, ''),
        }
      })
    return getFilesObjectArray(files)
  }, [files])

  useEffect(() => {
    files.length &&
      setFiles(files => {
        const uniqueKeys = new Set<string>()

        const uniqueFiles: File[] = []

        for (const file of files) {
          const key = `${file.name}-${file.size}-${file.type}`

          if (!uniqueKeys.has(key)) {
            uniqueKeys.add(key)
            uniqueFiles.push(file)
          }
        }

        return uniqueFiles
      })
  }, [files.length])

  useEffect(() => {
    const prev = getValues()
    for (const file of files) {
      const key = `${file.name}-${file.size}-${file.type}`.replace(/[^a-zA-Z0-9]/g, '')
      if (!prev?.[key]) {
        setValue(key, file.name)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  const setFileForPreview = useCallback(
    (key: string) => () => {
      const selectedFile = files.find(
        file => key == `${file.name}-${file.size}-${file.type}`.replace(/[^a-zA-Z0-9]/g, '')
      )
      if (!selectedFile) return

      const fileUrl = URL.createObjectURL(selectedFile)
      if (key.endsWith('applicationpdf')) {
        setShowPreviewModal(true)
        setPreviewUrl(fileUrl)
      } else {
        const link = document.createElement('a')
        link.href = fileUrl
        link.download = selectedFile.name
        link.click()
      }
    },
    [files]
  )

  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl)
      }
    }
  }, [previewUrl])

  const handleCloseModal = useCallback(() => {
    onClose()
  }, [onClose])

  const createBulkDocumentMutation = useMutation(uploadBulkAppendices, {
    onSuccess: () => {
      onClose()
      toast.success('Document uploaded successfully', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      })
      setIsUploadDocumentsLoading(false)
      refetchChecklist()
    },
    onError: (error: AxiosError) => {
      getToastErrorMessage(error)
    },
    onMutate: () => {
      setIsUploadDocumentsLoading(true)
    },
    onSettled: () => {
      setIsUploadDocumentsLoading(false)
    },
  })

  const reorderDocuments = (draggedRowIndex: number, targetRowIndex: number) => {
    const swappedFiles = [...files]
    swappedFiles.splice(targetRowIndex, 0, swappedFiles.splice(draggedRowIndex, 1)[0])
    setFiles([...swappedFiles])
  }

  const columns = useMemo(
    () => getUploadAppendixColumns({ setFiles, control, setFileForPreview }),
    [control, setFileForPreview, setFiles]
  )

  const handleSaveFileModal = useCallback(() => {
    const filesData = _map(
      files,
      (file, index) =>
        ({
          order: index + 1,
          legal_entity: legalEntity || 0,
          name: getValues(`${file.name}-${file.size}-${file.type}`.replace(/[^a-zA-Z0-9]/g, '')) || file.name,
        } as UpdateAppendixFile)
    )

    createBulkDocumentMutation.mutate({ filesData, files })
  }, [createBulkDocumentMutation, legalEntity, files, getValues])

  const renderLoaderContent = useMemo(() => {
    return (
      <div className="flex flex-col items-center justify-center max-h-[50.5rem] min-h-[20rem]">
        <Loading />
        <Typography variant={Variant.Body} className="text-blue800">
          Uploading documents...
        </Typography>
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, EMPTY_ARRAY)

  return (
    <Modal
      title="Upload Files"
      isOpen={isOpen}
      onClose={handleCloseModal}
      containerClassName="w-[50rem] max-h-[62.5rem] min-h-[250px]  p-6 !text-start"
      className="flex flex-col justify-between items-center ">
      {isUploadDocumentsLoading && renderLoaderContent}
      {!isUploadDocumentsLoading && (
        <div className="flex flex-col gap-4 w-full justify-between">
          <UploadMultipleDocuments
            label="Supporting Appendices"
            files={files}
            setFiles={setFiles}
            allowedExtensions=".pdf"
          />
          <form
            onSubmit={handleSaveFileModal}
            className="w-full flex flex-col justify-between items-center gap-6 h-full">
            {files.length > 0 && (
              <div className="w-full">
                <Typography variant={Variant.ContainerText} type="semibold" className="mb-[0.5rem] text-slate-600">
                  Uploaded Appendices
                </Typography>
                <NewTable
                  data={filteredFiles}
                  tableRowClassName="!bg-gray-50"
                  tableCellClassname="!border-0"
                  columns={columns}
                  reorderRow={reorderDocuments}
                  showHeader={!!0}
                />
              </div>
            )}
            <Button disabled={!files?.length} buttonType="submit" className="ms-auto" icon={IconsType.save}>
              Save
            </Button>
          </form>
          {previewUrl && showInFullScreen ? (
            <DocumentPreviewModal
              documentPreviewModal={showPreviewModal}
              isFullScreen={showInFullScreen}
              selectedFile={previewUrl}
              setDocumentPreviewModal={setShowPreviewModal}
              setIsFullScreen={setShowInFullScreen}
            />
          ) : null}
        </div>
      )}
    </Modal>
  )
}

interface UploadOtherFilesModalProps {
  isOpen: boolean
  onClose: () => void
}

export default UploadOtherFilesModal
