import { RowSelectionState } from '@tanstack/react-table'
import { TransactionResponse } from 'api/transactions/getTransaction/types'
import _filter from 'lodash/filter'
import _forEach from 'lodash/forEach'

const findIfItemInList = <T>(matchList: T[], matchPredicate: (key: T) => boolean) => {
  let shouldFilter = false
  _forEach(matchList, matchKey => {
    shouldFilter = shouldFilter || matchPredicate(matchKey)
  })
  return shouldFilter
}

export const getFilteredTransactions = (transactionsList: TransactionResponse[], filters: FilterProps) => {
  const { legalEntityIds, transactionTypes, transactionNatures, tpMethods } = filters

  return _filter(transactionsList, (transaction: TransactionResponse) => {
    let shouldFilter = true
    if (legalEntityIds && legalEntityIds.length) {
      const associatedEntities = transaction.individual_transactions.map(
        transaction => transaction.associated_entity.id
      )
      const associatedEntitiesSet = new Set(associatedEntities)
      shouldFilter =
        shouldFilter &&
        findIfItemInList(
          legalEntityIds,
          entityId => transaction.primary_entity.id === entityId || associatedEntitiesSet.has(entityId)
        )
    }
    if (transactionTypes && transactionTypes.length) {
      shouldFilter =
        shouldFilter && findIfItemInList(transactionTypes, tpType => transaction.transaction_type === tpType)
    }
    if (transactionNatures && transactionNatures.length) {
      shouldFilter =
        shouldFilter && findIfItemInList(transactionNatures, tpNature => transaction.functional_profile.id === tpNature)
    }
    if (tpMethods && tpMethods.length) {
      shouldFilter =
        shouldFilter &&
        findIfItemInList(
          tpMethods,
          tpMethod =>
            transaction.economic_analysis &&
            transaction.economic_analysis.transfer_pricing_method_abbreviation === tpMethod
        )
    }

    return shouldFilter
  })
}

export const mapSelectedTransactions =
  (selectedTransactions: Set<number>, transactions: TransactionResponse[]) => () => {
    const selectedRows: RowSelectionState = {}
    _forEach(transactions, (transaction, index) => {
      if (selectedTransactions.has(transaction.id)) {
        selectedRows[index] = true
      }
    })
    return selectedRows
  }

export const saveSelectedTransactions =
  (rowSelection: RowSelectionState, transactionsList: TransactionResponse[]) => (prevTransactions: Set<number>) => {
    const selectedTransactions = prevTransactions

    transactionsList.forEach((value, index) => {
      if (selectedTransactions.has(value.id) && !rowSelection[index]) {
        selectedTransactions.delete(value.id)
      }
    })

    // add any new entries which have come to the existing state
    _forEach(rowSelection, (isSelected, index) => {
      if (isSelected && transactionsList[parseInt(index)] && transactionsList[parseInt(index)].id) {
        selectedTransactions.add(transactionsList[parseInt(index)].id)
      }
    })
    return selectedTransactions
  }

interface FilterProps {
  legalEntityIds?: number[]
  transactionTypes?: string[]
  transactionNatures?: number[]
  tpMethods?: string[]
}
