import AppColors from 'constants/colors'
import FontSizes from 'constants/fonts'

export const customStyle = () => {
  return {
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiOutlinedInput-root': {
      height: '2.625rem',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      boxShadow: 'none',
      fontWeight: 400,
      color: '#1E293B',
      borderRadius: '0.75rem',
      border: '1px solid #CBD5E1',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #B3B3B3',
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      backgroundColor: AppColors.red50,
      borderRadius: '13px',
      border: '1px solid #F87171',
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #cbd5e1',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #B3B3B3',
    },
    '& .MuiInputBase-root': {
      color: '#111827',
      fontSize: '0.875rem',
    },
    '& .MuiFormHelperText-root': {
      margin: 0,
      paddingTop: '0.125rem',
      fontSize: FontSizes.REM.Callout?.fontSize,
      lineHeight: FontSizes.REM.Callout?.lineHeight,
      '&.Mui-error': {
        color: AppColors.red500,
      },
    },
  }
}
