import { Tooltip } from '@mui/material'
import { transaction_entity } from 'api/transactions/getTransaction/types'
import cx from 'classnames'
import FlagReader from 'components/flagReader/FlagReader'
import Typography, { Variant } from 'components/typography'
import { ROUTES } from 'constants/routes'
import { sortBy } from 'lodash'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { capitalizeFirstLetter, truncateString } from 'utils/utils'

const TransactionPartyCell = ({
  partyIdentification,
  transactionNature,
  maxLength = 20,
  disableClick = false,
  entities,
}: ReportingPartyProps) => {
  const router = useRouter()
  const handleLEClick = useCallback(
    (id: number) => () => {
      if (!disableClick) router.push(`${ROUTES.LEGAL_ENTITY_MANAGEMENT}/${id}`)
    },
    [disableClick, router]
  )

  const sortedEntitiesByActive = useMemo(
    () => sortBy(entities, et => (et.active_status == 'dormant' ? 1 : 0)),
    [entities]
  )
  if (!entities.length) return null
  const firstEntity = sortedEntitiesByActive[0]
  const firstEntityName = firstEntity.name
  const firstEntityAbbr = firstEntity.name_abbreviation
  return (
    <div className="flex">
      <div className="flex flex-col items-start ml-2">
        <Tooltip title={capitalizeFirstLetter(firstEntityName)} placement="top">
          <div
            onClick={handleLEClick(firstEntity.id)}
            className={cx('text-neutral800 max-w-[12.0625rem] truncate flex', { 'cursor-pointer': !disableClick })}>
            <FlagReader
              country={firstEntity.country}
              otherText={truncateString(capitalizeFirstLetter(firstEntityAbbr || firstEntityName), maxLength)}
              className={cx({ 'text-blue800': firstEntity.id && !disableClick })}
            />
          </div>
        </Tooltip>
        {firstEntity.active_status == 'dormant' && (
          <div className="bg-red-100 mt-0.5 min-h-[20px] py-0.5 flex items-center justify-center px-3 rounded-2xl text-footnote text-red-500 font-semibold">
            Inactive
          </div>
        )}
        <Typography variant={Variant.Callout} className={cx('text-neutral800 mt-1')}>
          <Tooltip title={`${transactionNature}-${partyIdentification}`}>
            <span>{truncateString(`${transactionNature}-${partyIdentification}`, maxLength)}</span>
          </Tooltip>
        </Typography>
      </div>
      {sortedEntitiesByActive.length > 1 && (
        <Tooltip
          title={
            <>
              {sortedEntitiesByActive.map((entity, idx) => {
                const entityName = entity.name_abbreviation || entity.name
                return (
                  idx !== 0 && (
                    <div
                      onClick={handleLEClick(entity.id)}
                      className={cx('flex flex-col py-2 self-stretch px-3 gap-1', {
                        'border-0 border-t border-gray-700 border-solid': idx > 1,
                        'cursor-pointer': !disableClick,
                      })}>
                      <Typography
                        key={`relatedParty-${entity.id}`}
                        variant={Variant.Callout}
                        type="semibold"
                        className={cx('text-gray-300 max-w-[100%] truncate gap-1 flex')}>
                        <FlagReader country={entity.country} />
                        {capitalizeFirstLetter(entityName)}
                      </Typography>
                      {entity.active_status == 'dormant' && (
                        <div className="bg-red-100 min-h-[20px] w-min py-0.5 flex items-center justify-center px-3 rounded-2xl text-footnote text-red-500 font-semibold">
                          Inactive
                        </div>
                      )}
                    </div>
                  )
                )
              })}
            </>
          }
          classes={{
            tooltip:
              'p-0 bg-[#1A193B] w-[200px] max-h-[258px] overflow-y-auto rounded-lg border border-solid border-neutral-500',
          }}
          placement="top">
          <span className="flex items-center cursor-pointer ml-2">
            {sortedEntitiesByActive.length > 1 ? (
              <span className="bg-orange-100 rounded-[0.25rem] px-1 py-[2px] text-orange-700 text-footnote">
                +{sortedEntitiesByActive.length - 1}
              </span>
            ) : (
              <></>
            )}
          </span>
        </Tooltip>
      )}
    </div>
  )
}

type ReportingPartyProps = {
  partyIdentification: string
  transactionNature: string
  disableClick?: boolean
  entities: transaction_entity[]
  maxLength?: number
}

export default TransactionPartyCell
