import GreenTick from 'assets/icons/greenTick'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { useRouter } from 'next/router'
import React, { ReactNode, useCallback, useState } from 'react'

export type ChecklistParentAccordionProps = {
  isOpen: boolean
  label: string
  href?: string
  skipped?: boolean
  added: boolean
  children?: ReactNode
}

const ChecklistParentAccordion = ({ skipped, href, label, added, isOpen, children }: ChecklistParentAccordionProps) => {
  const [open, setOpen] = useState(isOpen)
  const router = useRouter()
  const reportContext = useReportWizardContext()

  const isSkippable = typeof skipped == 'boolean'

  const handleClick = useCallback(() => {
    href && router.push(href)
    reportContext.setIsExpanded(false)
  }, [href, reportContext, router])

  return (
    <div className="py-3 px-4  bg-[rgb(16,15,46)]">
      <div className="w-full flex items-center">
        <svg
          onClick={() => setOpen(o => !o)}
          className={cx('me-2', {
            'rotate-180': !open,
          })}
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none">
          <rect y="12.5" width="12" height="12" rx="2" transform="rotate(-90 0 12.5)" fill="#A5B4FC" />
          <path
            d="M9.33398 8.16536L6.00065 4.83203L2.66732 8.16536"
            stroke="#312E81"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <Typography
          onClick={handleClick}
          variant={Variant.Callout}
          type="semibold"
          className={cx('text-indigo-400 max-w-[470px]', {
            'cursor-pointer': !!href,
          })}>
          {label}
          {added && <GreenTick className="inline w-4 h-4 ms-2 relative top-1" pathClassName="fill-green-600" />}
        </Typography>
        {added ? null : isSkippable ? (
          skipped ? (
            <Typography variant={Variant.Footnote} type="semibold" className="text-gray-500 ms-2">
              Skipped
            </Typography>
          ) : (
            <Typography variant={Variant.Footnote} type="semibold" className="text-indigo-400 ms-auto">
              Skip
            </Typography>
          )
        ) : (
          <></>
        )}
      </div>
      <div
        className={cx('space-y-3 transition-all duration-300', {
          'mt-3 max-h-[1000px] opacity-100': open,
          'mt-0 max-h-0 opacity-0 overflow-hidden': !open,
        })}>
        {children}
      </div>
    </div>
  )
}

export default ChecklistParentAccordion
