/* eslint-disable react/jsx-key */
import React, { ReactElement } from 'react'

import Activity from './icons/activity'
import ActivityLog from './icons/activityLog'
import AddDocument from './icons/addDocument'
import AddStickyNotes from './icons/addStickyNotes'
import AddUsers from './icons/addUsers'
import AlertTriangle from './icons/alertTriangle'
import Analysis from './icons/analysis'
import ApproveTick from './icons/approveTick'
import Arrow from './icons/arrow'
import ArrowRight from './icons/arrowRight'
import Assets from './icons/assets'
import AutoAwesomeCharacter from './icons/autoAwesomeCharacter'
import BarGraph from './icons/barGraph'
import BasicInfo from './icons/basicInfo'
import Benchmark from './icons/benchmark'
import Benchmarking from './icons/benchmarking'
import BenchmarkManagement from './icons/benchmarkManagement'
import BlueArrow from './icons/blueArrow'
import Building from './icons/building'
import Calendar from './icons/calendar'
import Camera from './icons/camera'
import CheckWithCircle from './icons/checkWithCircle'
import CloseIntegralLogo from './icons/closeIntegralLogo'
import CognitiveBrain from './icons/cognitiveBrain'
import CollapsibleSidebar from './icons/collapsibleSidebar'
import Compare from './icons/compare'
import CompetitorAnalysis from './icons/competitorAnalysis'
import ContextualToken from './icons/contextualToken'
import Contract from './icons/contract'
import ContractDelete from './icons/contractDelete'
import Cross from './icons/cross'
import Dangerous from './icons/dangerous'
import Dashboard from './icons/dashboard'
import Delete from './icons/delete'
import DocumentManagement from './icons/documentManagement'
import DollarInCircle from './icons/dollarInCircle'
import DotSearchTwo from './icons/dotSearchTwo'
import DoubleLeft from './icons/doubleLeft'
import DoubleRight from './icons/doubleRight'
import DoubleTick from './icons/doubleTick'
import Download from './icons/download'
import EconomicAnalysis from './icons/economicAnalysis'
import Edit from './icons/edit'
import Excel from './icons/excel'
import Eye from './icons/eye'
import EyeInvisible from './icons/eyeInvisible'
import EyeVisible from './icons/eyeVisible'
import FictionalLogo from './icons/fictionalLogo'
import File from './icons/file'
import File2 from './icons/file2'
import FileCopy from './icons/fileCopy'
import FileMaximize from './icons/fileMaximize'
import Files from './icons/files'
import Filter from './icons/filter'
import Flag from './icons/flag'
import Footnote from './icons/footnote'
import FunctionalAnalysis from './icons/functionalAnalysis'
import Functions from './icons/functions'
import Gear from './icons/gear'
import Graph from './icons/graph'
import GreenTick from './icons/greenTick'
import GreenTick2 from './icons/greenTick2'
import HalfArrow from './icons/halfArrow'
import HandShake from './icons/handShake'
import HeapSnapShot from './icons/heapSnapshot'
import HelpOutline from './icons/helpOutline'
import HelpSolid from './icons/helpSolid'
import HourGlassTop from './icons/hourGlassTop'
import Intangibles from './icons/intangibles'
import IntegralOpenLogo from './icons/integralOpenLogo'
import InterExchange from './icons/interExchange'
import LabelIcon from './icons/label'
import LeftParagraph from './icons/leftParagraph'
import LegalEntityManagement from './icons/legalEntityManagement'
import LightBulb from './icons/lightBulb'
import ListBullets from './icons/listBullets'
import Loading from './icons/loading'
import LoadingThree from './icons/loadingThree'
import LoadingTwo from './icons/loadingTwo'
import LocationPointer from './icons/locationPointer'
import Minimize from './icons/minimize'
import Minus from './icons/minus'
import ModernRightArrow from './icons/modernRightArrow'
import MoneyStack from './icons/moneyStack'
import NetworkTree from './icons/networkTree'
import NewFeedBack from './icons/newFeedBack'
import NewFilter from './icons/newFilter'
import NewsPaperFolding from './icons/newsPaperFolding'
import OnboardingIllustration from './icons/onboardingIllustration'
import Options from './icons/options'
import Organization from './icons/organization'
import Paragraph from './icons/paragraph'
import Percentage from './icons/percentage'
import PieChart from './icons/pieChart'
import Plus from './icons/plus'
import PrecisionManufacturing from './icons/precisionManufacturing'
import ProductCategory from './icons/productCategory'
import Region from './icons/region'
import Reset from './icons/reset'
import Risks from './icons/risks'
import Rocket from './icons/rocket'
import RotateRight from './icons/rotateRight'
import Save from './icons/save'
import Search from './icons/search'
import SearchActivity from './icons/searchActivity'
import Services from './icons/services'
import Settings from './icons/settings'
import ShoppingCart from './icons/shoppingCart'
import SideBarEclipse from './icons/sideBarEclips'
import SixDotDrag from './icons/sixDotDrag'
import Smile from './icons/smile'
import Sort from './icons/sort'
import SortDescending from './icons/sortDescending'
import SparkleStars from './icons/sparkleStars'
import StickyNotes from './icons/stickyNotes'
import StopCircle from './icons/stopCircle'
import Subtitles from './icons/subtitles'
import SuccessTick from './icons/successTick'
import TemplateManagement from './icons/templateManagement'
import TemplatesSubType from './icons/templatesSubType'
import ThreeUsers from './icons/threeUsers'
import ThumbsUp from './icons/thumbsUp'
import Tick from './icons/tick'
import Transaction from './icons/transaction'
import TransactionManagement from './icons/transactionManagement'
import TwoPagerStore from './icons/twoPagerStore'
import Upload from './icons/upload'
import UserBusinessCeo from './icons/userBusinessCeo'
import Users from './icons/users'
import Verified from './icons/verified'
import { IconsType } from './types'

const iconsMap = new Map<keyof typeof IconsType, ReactElement<IconProps>>([
  [IconsType.activity, <Activity />],
  [IconsType.activityLog, <ActivityLog />],
  [IconsType.addDocument, <AddDocument />],
  [IconsType.addStickyNotes, <AddStickyNotes />],
  [IconsType.addUsers, <AddUsers />],
  [IconsType.alertTriangle, <AlertTriangle />],
  [IconsType.analysis, <Analysis />],
  [IconsType.approveTick, <ApproveTick />],
  [IconsType.arrow, <Arrow />],
  [IconsType.arrowRight, <ArrowRight />],
  [IconsType.assets, <Assets />],
  [IconsType.autoAwesome, <AutoAwesomeCharacter />],
  [IconsType.barGraph, <BarGraph />],
  [IconsType.basicInfo, <BasicInfo />],
  [IconsType.benchmark, <Benchmark />],
  [IconsType.benchmarking, <Benchmarking />],
  [IconsType.benchmarkManagement, <BenchmarkManagement />],
  [IconsType.building, <Building />],
  [IconsType.calendar, <Calendar />],
  [IconsType.camera, <Camera />],
  [IconsType.checkWithCircle, <CheckWithCircle />],
  [IconsType.closeIntegralLogo, <CloseIntegralLogo />],
  [IconsType.cognitiveBrain, <CognitiveBrain />],
  [IconsType.collapsibleSidebar, <CollapsibleSidebar />],
  [IconsType.compare, <Compare />],
  [IconsType.contract, <Contract />],
  [IconsType.contractDelete, <ContractDelete />],
  [IconsType.competitorAnalysis, <CompetitorAnalysis />],
  [IconsType.contextualToken, <ContextualToken />],
  [IconsType.cross, <Cross />],
  [IconsType.dangerous, <Dangerous />],
  [IconsType.dashboard, <Dashboard />],
  [IconsType.delete, <Delete />],
  [IconsType.documentManagement, <DocumentManagement />],
  [IconsType.dollarInCircle, <DollarInCircle />],
  [IconsType.dotSeachTwo, <DotSearchTwo />],
  [IconsType.doubleLeft, <DoubleLeft />],
  [IconsType.doubleRight, <DoubleRight />],
  [IconsType.doubleTick, <DoubleTick />],
  [IconsType.download, <Download />],
  [IconsType.economicAnalysis, <EconomicAnalysis />],
  [IconsType.edit, <Edit />],
  [IconsType.excel, <Excel />],
  [IconsType.eye, <Eye />],
  [IconsType.eyeInvisible, <EyeInvisible />],
  [IconsType.eyeVisible, <EyeVisible />],
  [IconsType.fictionalLogo, <FictionalLogo />],
  [IconsType.file, <File />],
  [IconsType.file2, <File2 />],
  [IconsType.fileCopy, <FileCopy />],
  [IconsType.fileMaximize, <FileMaximize />],
  [IconsType.files, <Files />],
  [IconsType.filter, <Filter />],
  [IconsType.flag, <Flag />],
  [IconsType.footnote, <Footnote />],
  [IconsType.functionalAnalysis, <FunctionalAnalysis />],
  [IconsType.functions, <Functions />],
  [IconsType.gear, <Gear />],
  [IconsType.graph, <Graph />],
  [IconsType.greenTick, <GreenTick />],
  [IconsType.greenTick2, <GreenTick2 />],
  [IconsType.halfArrow, <HalfArrow />],
  [IconsType.handShake, <HandShake />],
  [IconsType.heapSnapshot, <HeapSnapShot />],
  [IconsType.helpOutline, <HelpOutline />],
  [IconsType.helpSolid, <HelpSolid />],
  [IconsType.hourGlassTop, <HourGlassTop />],
  [IconsType.intangible, <Intangibles />],
  [IconsType.integralOpenLogo, <IntegralOpenLogo />],
  [IconsType.interExchange, <InterExchange />],
  [IconsType.labelIcon, <LabelIcon />],
  [IconsType.leftParagraph, <LeftParagraph />],
  [IconsType.legalEntityManagement, <LegalEntityManagement />],
  [IconsType.lightBulb, <LightBulb />],
  [IconsType.listBullet, <ListBullets />],
  [IconsType.loading, <Loading />],
  [IconsType.loadingTwo, <LoadingTwo />],
  [IconsType.loadingThree, <LoadingThree />],
  [IconsType.locationPointer, <LocationPointer />],
  [IconsType.manufacturing, <PrecisionManufacturing />],
  [IconsType.minimize, <Minimize />],
  [IconsType.minus, <Minus />],
  [IconsType.modernRightArrow, <ModernRightArrow />],
  [IconsType.moneyStack, <MoneyStack />],
  [IconsType.networkTree, <NetworkTree />],
  [IconsType.newFilter, <NewFilter />],
  [IconsType.newsPaperFolding, <NewsPaperFolding />],
  [IconsType.onboardingIllustration, <OnboardingIllustration />],
  [IconsType.organization, <Organization />],
  [IconsType.options, <Options />],
  [IconsType.paragraph, <Paragraph />],
  [IconsType.percentage, <Percentage />],
  [IconsType.pieChart, <PieChart />],
  [IconsType.plus, <Plus />],
  [IconsType.productCategory, <ProductCategory />],
  [IconsType.region, <Region />],
  [IconsType.reset, <Reset />],
  [IconsType.risks, <Risks />],
  [IconsType.rocket, <Rocket />],
  [IconsType.rotateRight, <RotateRight />],
  [IconsType.save, <Save />],
  [IconsType.search, <Search />],
  [IconsType.searchActivity, <SearchActivity />],
  [IconsType.services, <Services />],
  [IconsType.settings, <Settings />],
  [IconsType.shoppingCart, <ShoppingCart />],
  [IconsType.sideBarEclipse, <SideBarEclipse />],
  [IconsType.sixDotDrag, <SixDotDrag />],
  [IconsType.Smile, <Smile />],
  [IconsType.sort, <Sort />],
  [IconsType.sortDescending, <SortDescending />],
  [IconsType.sparkle, <SparkleStars />],
  [IconsType.stopCircle, <StopCircle />],
  [IconsType.stickyNotes, <StickyNotes />],
  [IconsType.successTick, <SuccessTick />],
  [IconsType.subtitles, <Subtitles />],
  [IconsType.templateManagement, <TemplateManagement />],
  [IconsType.templateSubType, <TemplatesSubType />],
  [IconsType.threeUsers, <ThreeUsers />],
  [IconsType.thumbsUp, <ThumbsUp />],
  [IconsType.tick, <Tick />],
  [IconsType.transaction, <Transaction />],
  [IconsType.transactionManagement, <TransactionManagement />],
  [IconsType.twoPagerStore, <TwoPagerStore />],
  [IconsType.upload, <Upload />],
  [IconsType.userBusinessCeo, <UserBusinessCeo />],
  [IconsType.users, <Users />],
  [IconsType.verified, <Verified />],
  [IconsType.blueArrow, <BlueArrow />],
  [IconsType.newFeedBack, <NewFeedBack />],
])

export const getIcons = (icons: IconsType, iconsProp?: IconProps): ReactElement<IconProps> => {
  const icon = iconsMap.get(icons)
  if (icon) {
    return React.cloneElement(icon, iconsProp)
  } else {
    return <React.Fragment />
  }
}
export interface IconProps {
  className?: string
  pathClassName?: string
  primaryColor?: string
  secondaryColor?: string
}
