import cx from 'classnames'
import { MouseEventHandler } from 'react'

const BorderBoxWrapper = ({ children, className, onClick }: BorderBoxWrapperProps): JSX.Element => {
  return (
    <div
      onClick={onClick}
      className={cx('border border-solid border-gray-300 p-4 rounded-2xl overflow-y-auto', className)}>
      {children}
    </div>
  )
}

interface BorderBoxWrapperProps {
  children: React.ReactNode
  className?: string
  onClick?: MouseEventHandler<HTMLDivElement>
}

export default BorderBoxWrapper
