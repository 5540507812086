import { IconsType } from 'assets/types'
import cx from 'classnames'
import { getLabelClass } from 'components/input/input.helpers'
import { IconPlacement, InputVariant } from 'components/input/input.types'

import { labelBaseClass } from './label.config'

const Label = ({ variant, label, iconPlacement, icon, name }: LabelProps): JSX.Element => {
  const labelClass = getLabelClass(variant)

  return (
    <label
      htmlFor={name}
      className={cx(labelBaseClass, {
        'ml-7': icon && iconPlacement === IconPlacement.Left,
        labelClass,
      })}>
      {label}
    </label>
  )
}

interface LabelProps {
  variant: InputVariant
  label?: string
  iconPlacement: IconPlacement
  icon?: IconsType
  name?: string
}

export default Label
