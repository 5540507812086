import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles({
  textField: {
    borderLeft: '0',
    borderRight: '0',
    border: '0',
    '&:focus': {
      outline: 'none',
    },
  },
  inputProps: {
    fontSize: '0.875rem',
    fontWeight: 'regular',
    lineHeight: '1.313rem',
    maxHeight: '30rem',
  },
})
