import { IconProps } from 'assets'
import React from 'react'

const LocationPointer = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M12 22.5C12 22.5 19.5 16.5 19.5 10C19.5 5.85785 16.1421 2.5 12 2.5C7.85785 2.5 4.5 5.85785 4.5 10C4.5 16.5 12 22.5 12 22.5Z"
        stroke="#1E3A8A"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M12 13C13.6568 13 15 11.6568 15 10C15 8.34315 13.6568 7 12 7C10.3432 7 9 8.34315 9 10C9 11.6568 10.3432 13 12 13Z"
        stroke="#1E3A8A"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LocationPointer
