import { IconProps } from 'assets'

const Minimize = ({ className, pathClassName = 'stroke-[#D1D5DB]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Group 2">
        <g id="Group 7537">
          <path
            id="Vector"
            d="M10.1662 15.1678V10.168L15.166 10.168"
            className={pathClassName}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_2"
            d="M10.1679 10.168L16.001 16.0011"
            className={pathClassName}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <g id="Group 7536">
          <path
            id="Vector_3"
            d="M6.83301 1.83594L6.83301 6.83575L1.8332 6.83575"
            className={pathClassName}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_4"
            d="M1 0.998921L6.83311 6.83203"
            className={pathClassName}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
    </svg>
  )
}

export default Minimize
