import { ColumnDef } from '@tanstack/react-table'
import { IconsType } from 'assets/types'
import { ButtonVariant } from 'components/button'
import Button from 'components/button/Button'
import Typography, { Variant } from 'components/typography'
import { Control } from 'react-hook-form'

import { EditableFilenameCell } from './EditdableFilename'

export const getUploadAppendixColumns = ({ setFiles, control, setFileForPreview }: getUploadAppendixColumnProps) => {
  const columns: ColumnDef<ColumnType>[] = [
    {
      header: 'File Name',
      id: 'name',
      accessorKey: 'name',
      cell: cell => (
        <>
          <EditableFilenameCell key={cell?.row?.original?.id} control={control} id={cell?.row?.original?.id} />
        </>
      ),
      size: 500,
    },
    {
      header: () => <div className="flex justify-end w-full">Actions</div>,
      id: 'delete',
      cell: data => {
        const handleDeleteFile = (id: string) => () => {
          setFiles(files => {
            return files.filter(file => {
              const key = `${file.name}-${file.size}-${file.type}`.replace(/[^a-zA-Z0-9]/g, '')
              return key !== id
            })
          })
        }

        return (
          <div className="flex items-center gap-3 justify-end px-2">
            <Typography
              onClick={setFileForPreview(data.row.original.id)}
              variant={Variant.Callout}
              type="semibold"
              className="text-blue-900 cursor-pointer">
              Preview
            </Typography>
            <Button
              isDefaultSize={false}
              iconCLass="!w-[1.125rem] !h-[1.125rem]"
              iconPathClassName="stroke-orange-600 "
              onClick={handleDeleteFile(data.row.original.id)}
              variant={ButtonVariant.Tertiary}
              icon={IconsType.delete}></Button>
          </div>
        )
      },
      size: 1,
    },
  ]

  return columns
}

interface getUploadAppendixColumnProps {
  setFiles: React.Dispatch<React.SetStateAction<File[]>>
  control: Control<Record<string, string>>
  setFileForPreview: (key: string) => () => void
}

export interface ColumnType {
  id: string
}
