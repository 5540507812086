import { ColumnDef } from '@tanstack/react-table'
import Delete from 'assets/icons/delete'
import Typography, { Variant } from 'components/typography'
import React from 'react'

import { relevantTaxRulingTableData } from './RelavantTaxDocsTab'

export const getTaxRulingColumns = ({
  handleDeleteClick,
  handleEditClick,
  handlePreview,
}: {
  handleDeleteClick: (data: relevantTaxRulingTableData) => Promise<void>
  handleEditClick: (data: relevantTaxRulingTableData) => void
  handlePreview: (data: relevantTaxRulingTableData) => void
}): ColumnDef<relevantTaxRulingTableData>[] => [
  {
    header: 'Document Type',
    cell(props) {
      const data = props.row.original
      return (
        <Typography
          onClick={() => handlePreview(data)}
          variant={Variant.Callout}
          className="text-indigo-500 cursor-pointer"
          type="semibold">
          {data.label} - {data.file_name || data.label}
        </Typography>
      )
    },
    size: 400,
  },
  {
    header: 'Actions',
    cell(props) {
      const data = props.row.original
      return (
        <div className="flex items-center gap-4">
          <Typography
            onClick={() => handleEditClick(data)}
            variant={Variant.Callout}
            type="semibold"
            className="text-indigo-500 cursor-pointer">
            Edit
          </Typography>
          <div onClick={() => handleDeleteClick(data)} className="flex items-center cursor-pointer">
            <Delete className="w-4 h-4" pathClassName="stroke-orange-600" />
          </div>
        </div>
      )
    },
    size: 1,
  },
]
