import { InputVariant } from 'components/input'
import DescriptiveText from 'components/input/components/descriptiveText'
import TextArea from 'components/textArea'
import Typography, { Variant } from 'components/typography'
import _get from 'lodash/get'
import { ReactNode } from 'react'
import { Control, Controller, FieldValues, useFormState } from 'react-hook-form'

function TEXTAREA_FIELD<T>({
  control,
  label,
  id,
  rows,
  disabled,
  newTextArea = true,
  hide = false,
  defaultValue,
  placeholder = 'Type here...',
  description,
  inputClassName = '',
  containerClassName = '',
  required = false,
}: InputFieldProps<T & FieldValues>) {
  const { errors } = useFormState({
    control,
  })

  const errorText = _get(errors, `${String(id)}`)?.message?.toString() || ''
  return (
    <>
      {!hide && (
        <div className={containerClassName}>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, name, ref, value } }) => {
              return (
                <>
                  <TextArea
                    newInput={newTextArea}
                    error={!!errorText}
                    required={required}
                    key={id as string}
                    placeholder={placeholder}
                    onBlur={onBlur}
                    onChange={onChange}
                    id={id as string}
                    label={label}
                    name={name}
                    ref={ref}
                    rows={rows}
                    disabled={disabled}
                    value={value}
                    defaultValue={value}
                    classes={{ input: inputClassName }}
                  />

                  {!!errorText && (
                    <DescriptiveText variant={InputVariant.Default} descriptiveText={errorText} error={!!errorText} />
                  )}
                </>
              )
            }}
            shouldUnregister={false}
            name={String(id)}
            defaultValue={defaultValue}
          />

          {description && (
            <Typography variant={Variant.Callout} className="mt-[0.125rem] italic">
              {description}
            </Typography>
          )}
        </div>
      )}
    </>
  )
}

interface InputFieldProps<T extends FieldValues> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FieldValues | T | any>
  label?: string
  hide?: boolean
  required?: boolean
  id: keyof T
  rows?: number
  disabled?: boolean
  defaultValue?: string
  placeholder?: string
  type?: React.HTMLInputTypeAttribute
  description?: ReactNode
  inputClassName?: string
  containerClassName?: string
  newTextArea?: boolean
}

export default TEXTAREA_FIELD
