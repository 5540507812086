import { CalendarMonthOutlined } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { DateView } from '@mui/x-date-pickers'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import Typography, { Variant } from 'components/typography'
import dayjs, { Dayjs } from 'dayjs'
import { HTMLInputTypeAttribute, useEffect, useRef, useState } from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'

import { customStyle } from './styles'

interface CustomDatePickerProps extends DatePickerProps<Dayjs> {
  errors: boolean
}

const CustomDatePicker = styled(
  DatePicker,
  {}
)<CustomDatePickerProps>(({}) => ({
  ...customStyle(),
}))

function DATE_PICKER_FIELD<T>({
  control,
  label,
  id,
  hide = false,
  disabled = false,
  defaultValue,
  placeholder,
  format = 'MM-DD-YYYY',
  views = ['year', 'month', 'day'],
  required,
}: DatePickerFieldProps<T & FieldValues>) {
  const [minDate, setMinDate] = useState<Dayjs>()
  const [maxDate, setMaxDate] = useState<Dayjs>()
  const [open, setOpen] = useState(false)
  const datePickerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!views.includes('year')) {
      setMinDate(dayjs().startOf('year'))
      setMaxDate(dayjs().endOf('year'))
    }
  }, [views])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const calendarPopover = document.body.querySelector('.MuiPaper-root')
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node) &&
        calendarPopover &&
        !calendarPopover.contains(event.target as Node)
      ) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      {!hide && (
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, ref, value }, formState }) => {
            const { errors } = formState
            const descriptiveText = errors[id]?.message?.toString()
            const isError = Boolean(errors[id]?.message)

            return (
              <div ref={datePickerRef}>
                <Typography variant={Variant.Callout} type="semibold" className="mb-[0.125rem] flex items-center">
                  <span className="my-1">{label}</span>
                  {required && <span className="mb-2 text-red-500">*</span>}
                </Typography>
                <CustomDatePicker
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => {
                    setOpen(false)
                    onBlur()
                  }}
                  inputRef={ref}
                  disabled={disabled}
                  views={views}
                  format={format}
                  defaultValue={value}
                  className="bg-white rounded-xl"
                  value={value}
                  errors={false}
                  sx={{
                    svg: { color: disabled ? '#fff' : '#1E3A8A' },
                    input: { color: disabled ? '#fff' : '#4b5563' },
                  }}
                  slots={{
                    openPickerIcon: CalendarMonthOutlined,
                  }}
                  slotProps={{
                    textField: {
                      onBlur,
                      placeholder,
                      error: isError,
                      helperText: descriptiveText,
                    },
                    popper: {
                      placement: 'top-start',
                    },
                  }}
                  minDate={minDate}
                  maxDate={maxDate}
                  reduceAnimations={true}
                  onChange={onChange}
                />
              </div>
            )
          }}
          shouldUnregister={false}
          name={String(id)}
          defaultValue={defaultValue}
        />
      )}
    </>
  )
}

interface DatePickerFieldProps<T extends FieldValues> {
  control: Control<FieldValues | T>
  label: string
  id: keyof T
  format?: string
  views?: DateView[]
  defaultValue?: Dayjs
  placeholder?: string
  type?: HTMLInputTypeAttribute
  required?: boolean
  disabled?: boolean
  hide?: boolean
}

export default DATE_PICKER_FIELD
