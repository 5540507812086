import { Tooltip } from '@mui/material'
import { TransactionResponse } from 'api/transactions/getTransaction/types'
import cx from 'classnames'
import Button, { ButtonVariant } from 'components/button'
import Typography, { Variant } from 'components/typography'
import { ROUTES } from 'constants/routes'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useCallback } from 'react'
import { capitalizeFirstLetter, createQueryParams } from 'utils/utils'

const TxnNameCell = ({
  data,
  disableClick = false,
  buttonClassname = 'max-w-[10.5625rem] min-[1800px]:max-w-[40rem] min-[1600px]:max-w-[30rem] min-[1400px]:max-w-[20rem] ',
}: TxnNameCellProps): JSX.Element => {
  const Router = useRouter()
  const { year } = Router.query
  const handleTransactionModalClick = useCallback(() => {
    !disableClick &&
      Router.push(
        `${ROUTES.TRANSACTION_MANAGEMENT}/${data.id}/?${createQueryParams({
          txnName: String(data.name),
          year,
        })}`
      )
  }, [Router, data.id, data.name, disableClick, year])

  return (
    <div className=" pl-2">
      <Tooltip title={data.name as string} placement="top">
        <div>
          <Button
            onClick={handleTransactionModalClick}
            className={cx(buttonClassname, 'truncate text-start !py-0 !h-auto')}
            variant={ButtonVariant.Tertiary}>
            {capitalizeFirstLetter(data.name as string)}
          </Button>
        </div>
      </Tooltip>
      {!!data.mirrored_with_transaction?.id && (
        <Tooltip
          leaveDelay={1500}
          classes={{
            tooltip: 'p-0 bg-neutral-100 rounded border border-solid border-neutral-500',
          }}
          title={
            <Typography
              variant={Variant.Callout}
              className="max-w-[200px] text-center text-neutral-700 px-2 py-1 rounded ">
              This transaction is a mirror of{' '}
              <Link
                href={`${ROUTES.TRANSACTION_MANAGEMENT}/${
                  data.mirrored_with_transaction?.id
                }?txnName=${encodeURIComponent(data.mirrored_with_transaction.name)}`}>
                <span className="cursor-pointer text-blue-800 font-semibold underline decoration-blue-800">
                  {data.mirrored_with_transaction.name.slice(0, 25)}
                  {data.mirrored_with_transaction.name.length > 25 ? '...' : ''}
                </span>
              </Link>
            </Typography>
          }>
          <span className="flex items-center px-3 py-0.5 text-footnote bg-orange-100 text-orange-600 font-semibold rounded-full w-max">
            Mirrored
          </span>
        </Tooltip>
      )}
    </div>
  )
}

interface TxnNameCellProps {
  data: TransactionResponse
  disableClick?: boolean
  buttonClassname?: string
}

export default TxnNameCell
