import { Table } from '@tanstack/react-table'
import { IconsType } from 'assets/types'
import Button, { ButtonVariant } from 'components/button'
import Checkbox from 'components/checkbox/Checkbox'
import Modal from 'components/modal/Modal'
import { Variant } from 'components/typography'
import Typography from 'components/typography/Typography'
import React from 'react'
interface ColumnSelectionModalProps<T> {
  showModal: boolean
  toggleModal: VoidFunction
  tableInstance: Table<T>
}

const ColumnSelectionModal = <T,>({ showModal, toggleModal, tableInstance }: ColumnSelectionModalProps<T>) => {
  if (!tableInstance) return null

  const resetVisibility = () => {
    tableInstance.resetColumnVisibility(false)
  }

  return (
    <Modal
      className="gap-6 p-5"
      containerClassName="min-w-[23rem]"
      title="Filter Columns"
      isOpen={showModal}
      onClose={toggleModal}>
      <Typography className=" space-y-2" variant={Variant.Body}>
        {tableInstance.getAllLeafColumns().map(column => {
          if (!column.getCanHide()) return
          return (
            <Checkbox
              key={column.id}
              className="!accent-teal-600 mt-1"
              name={column.id}
              id={column.id}
              label={column.columnDef.meta?.filterLabel || column.id}
              checked={column.getIsVisible()}
              onChange={column.getToggleVisibilityHandler()}
            />
          )
        })}
      </Typography>
      <div className="flex justify-center w-full">
        <Button variant={ButtonVariant.Secondary} icon={IconsType.reset} onClick={resetVisibility}>
          Reset
        </Button>
      </div>
    </Modal>
  )
}

export default ColumnSelectionModal
