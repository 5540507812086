import { SvgIconProps } from '@mui/material'
import { getIcons } from 'assets'
import { IconsType } from 'assets/types'
import cx from 'classnames'
import React from 'react'

const IconPlaceHolder = ({ icon, iconPathClassName, iconClassName, className, MuiIcon }: IconPlaceHolderProps) => {
  return (
    <div
      className={cx(className, 'bg-blue50 rounded-full flex flex-col items-center justify-center shrink-0', {
        'h-9 w-9': !className,
      })}>
      {MuiIcon ? (
        <MuiIcon className="text-blue-900 w-[1.125rem]" />
      ) : (
        icon && getIcons(icon, { className: iconClassName, pathClassName: iconPathClassName })
      )}
    </div>
  )
}

interface IconPlaceHolderProps {
  icon?: IconsType
  MuiIcon?: React.ComponentType<SvgIconProps>
  iconPathClassName?: string
  iconClassName?: string
  className?: string
}

export default IconPlaceHolder
