import { TabData } from 'components/tabs/Tabs'
import { getAppendicesConfig } from 'organisms/reportWizard/ReportWizard.helpers'

import LocalEntityTab from './Components/LocalEntityTab/LocalEntityTab'
import OtherAppendicesTab from './Components/OtherAppendicesTab/OtherAppendicesTab'
import RelavantTaxDocsTab from './Components/RelavantTaxDocsTab/RelavantTaxDocsTab'
import TransactionAppendixTab from './Components/TransactionAppendixTab/TransactionAppendixTab'

export const getAppendixChecklistTabs = (data: ReturnType<typeof getAppendicesConfig>): TabData[] => {
  return [
    {
      label: 'Relevant Tax Rulings',
      hide: data['Relevant Tax Rulings'].hidden,
      content: <RelavantTaxDocsTab data={data['Relevant Tax Rulings'].data} />,
    },
    {
      label: 'Legal Entity',
      hide: data['Legal Entity'].hidden,
      content: <LocalEntityTab data={data['Legal Entity'].data} />,
    },
    {
      label: 'Transactions',
      hide: data.Transactions.hidden,
      content: <TransactionAppendixTab data={data.Transactions.data} />,
    },
    {
      label: 'Other Appendices',
      content: (
        <>
          <OtherAppendicesTab data={data['Other Appendices'].data} />
        </>
      ),
    },
  ]
}
