import Button from 'components/button'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { getTransactionConfig } from 'organisms/reportWizard/ReportWizard.helpers'
import React, { useCallback, useState } from 'react'

import ChecklistAccordion from '../ChecklistAccordion'
import ChecklistItem from '../ChecklistItem'
import ChecklistParentAccordion from '../ChecklistParentAccordion'
import TaskCompetionStatus from '../TaskCompetionStatus'
import TransactionSelectionModal from '../TransactionSelectionModal/TransactionSelectionModal'

type props = {
  txnConfig: ReturnType<typeof getTransactionConfig>
}

const TransactionsChecklist = ({ txnConfig }: props) => {
  const { checklistData } = useReportWizardContext()
  const [transactionSelectionModal, setTransactionSelectionModal] = useState(false)
  const [transactionReorderModal, setTransactionReorderModal] = useState(false)

  const handleModalClose: VoidFunction = useCallback(() => {
    setTransactionReorderModal(false)
    setTransactionSelectionModal(false)
  }, [])

  return (
    <div className="mt-6 space-y-3">
      <div className="flex w-full items-center ">
        <Button className="!bg-indigo-950 !text-indigo-300" onClick={() => setTransactionSelectionModal(true)}>
          Select Transactions
        </Button>
        {!!(checklistData?.transactions_order.length && checklistData?.transactions_order.length > 1) && (
          <Button onClick={() => setTransactionReorderModal(true)} className="ms-auto !bg-indigo-950 !text-indigo-300">
            Reorder Transactions
          </Button>
        )}
      </div>
      <TaskCompetionStatus
        completedTasks={txnConfig.tabConfig.completedTasks}
        totalTasks={txnConfig.tabConfig.totalTasks}
      />
      {txnConfig.listConfig.map(item => {
        return (
          <ChecklistParentAccordion
            added={item.added}
            label={item.label}
            key={item.href + item.label}
            href={item.href}
            isOpen={item.isOpen}>
            {item.subSections.map(item => {
              if (item.hidden) return null
              if (item.type == 'single') {
                return <ChecklistItem key={item.props.label} {...item.props} bgClass="bg-[#1A193B]" />
              }
              if (item.type == 'expandable') {
                return <ChecklistAccordion key={item.props.label} {...item.props} bgClass="bg-[#1A193B]" />
              }
            })}
          </ChecklistParentAccordion>
        )
      })}
      <TransactionSelectionModal
        key={String(transactionSelectionModal)}
        handleModalClose={handleModalClose}
        showModal={transactionSelectionModal}
      />
      <TransactionSelectionModal
        key={String(transactionReorderModal)}
        handleModalClose={handleModalClose}
        isReorder
        showModal={transactionReorderModal}
      />
    </div>
  )
}

export default TransactionsChecklist
