/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { compareItems } from '@tanstack/match-sorter-utils'
import { SortingFn, sortingFns } from '@tanstack/react-table'

export const fuzzySort: SortingFn<unknown | any> = (rowA, rowB, columnId) => {
  let dir = 0
  if (rowA.columnFiltersMeta[columnId]) {
    //@ts-ignore
    dir = compareItems(rowA.columnFiltersMeta[columnId]?.itemRank, rowB.columnFiltersMeta[columnId]?.itemRank)
  }

  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
}
export const sortByArrayLength: SortingFn<unknown | any> = (rowA, rowB, columnId) => {
  const lengthA = rowA.original[columnId]?.length || 0
  const lengthB = rowB.original[columnId]?.length || 0
  return lengthA - lengthB
}
