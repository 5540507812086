import { StylesConfig } from 'react-select'

export const selectStyle = (errors: boolean, disableDropdown?: boolean): StylesConfig => {
  return {
    control: base => {
      return {
        ...base,
        boxShadow: 'none',
        fontSize: '0.8125rem',
        fontWeight: 400,
        color: errors ? '#DC2626' : '#1E293B',
        border: 'none',
        backgroundColor: 'transparent',
      }
    },
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? '#EFF6FF' : 'white',
        color: '#1E293B',
        fontSize: '0.8125rem',
        zIndex: 20,
      }
    },
    valueContainer: base => {
      return { ...base, paddingRight: '0px' }
    },
    indicatorsContainer: base => {
      return { ...base, flexDirection: 'row-reverse' }
    },
    indicatorSeparator: base => {
      return { ...base, margin: '8px' }
    },
    dropdownIndicator: base => {
      return {
        ...base,
        display: disableDropdown ? 'none' : base.display,
        height: '18px',
        width: '18px',
        padding: '0px',
      }
    },
    singleValue(base) {
      return {
        ...base,
        color: disableDropdown ? '#333' : base.color,
      }
    },
  }
}
