import { useMutation } from '@tanstack/react-query'
import { setTaxRulingAppendices } from 'api/documents'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Button from 'components/button'
import DocumentPreviewModal from 'components/documentPreviewModal'
import NewTable from 'components/newTable'
import useConfirmationModal from 'hooks/useConfirmationModal'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { getAppendicesConfig } from 'organisms/reportWizard/ReportWizard.helpers'
import React, { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { SelectOptions } from 'types/common.types'
import { getToastErrorMessage } from 'utils/utils'

import UploadTaxRulingsDocument from './Components/UploadTaxRulingsDocument'
import { getTaxRulingColumns } from './RelavantTaxDocsTab.helpers'

type props = {
  data: ReturnType<typeof getAppendicesConfig>['Relevant Tax Rulings']['data']
}

export type relevantTaxRulingTableData = {
  file: string | null | undefined
  file_name: string | null | undefined
  documentType: string
  label: string
}

const RelavantTaxDocsTab = ({ data }: props) => {
  const { legalEntity, refetchChecklist, isFetching } = useReportWizardContext()

  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isPreview, setIsPreview] = useState(false)

  const [selectedDocument, setSelectedDocument] = useState<relevantTaxRulingTableData>()

  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false)

  const onClosePreview = useCallback(() => {
    setSelectedDocument(undefined)
    setIsPreview(false)
  }, [])

  const handlePreview = useCallback((data: relevantTaxRulingTableData) => {
    if (data.file) {
      setSelectedDocument(data)
      setIsPreview(true)
    }
  }, [])

  const toggleUploadModal = useCallback(() => {
    setUploadModalIsOpen(option => {
      if (option) {
        setSelectedDocument(undefined)
      }

      return !option
    })
  }, [])

  const tableData: relevantTaxRulingTableData[] = useMemo(() => {
    const returnData: relevantTaxRulingTableData[] = [
      {
        file: data?.['OECD Guidelines Appendix'].file,
        documentType: 'oecd_guidelines',
        file_name: data?.['OECD Guidelines Appendix'].file ? data?.['OECD Guidelines Appendix'].file_name : undefined,
        label: 'OECD Guidelines Appendix',
      },
      {
        file: data?.['Country Specific Guidelines'].file,
        documentType: 'country_specific_guidelines',
        file_name: data?.['Country Specific Guidelines'].file
          ? data?.['Country Specific Guidelines'].file_name
          : undefined,
        label: 'Country Specific Guidelines',
      },
    ]
    return returnData.filter(row => !!row.file)
  }, [data])

  const documentTypeOptions: SelectOptions[] = useMemo(() => {
    const options: SelectOptions[] = [
      {
        label: 'OECD Guidelines Appendix',
        value: 'oecd_guidelines',
      },
      {
        label: 'Country Specific Guidelines',
        value: 'country_specific_guidelines',
      },
    ].filter(opt => {
      if (data?.['Country Specific Guidelines'].is_present && opt.value == 'country_specific_guidelines') {
        return false
      }

      if (data?.['OECD Guidelines Appendix'].is_present && opt.value == 'oecd_guidelines') {
        return false
      }
      return true
    })
    return options
  }, [data])

  const handleNewUpload = useCallback(() => {
    setUploadModalIsOpen(true)
    setSelectedDocument(undefined)
  }, [])

  const deleteTaxRulingFileMutation = useMutation(setTaxRulingAppendices)

  const handleEditClick = useCallback((data: relevantTaxRulingTableData) => {
    setSelectedDocument(data)
    setUploadModalIsOpen(true)
  }, [])

  const { getConfirmation, ConfirmationModal } = useConfirmationModal()

  const handleDeleteClick = useCallback(
    async (data: relevantTaxRulingTableData) => {
      if (deleteTaxRulingFileMutation.isLoading) return
      if (await getConfirmation(`Are you sure you want to delete ${data.label} - ${data.file_name || data.label}?`)) {
        deleteTaxRulingFileMutation.mutate(
          {
            leId: legalEntity || 0,
            payload: {
              [data.documentType + '_file']: null,
              [data.documentType + '_file_name']: null,
            },
          },
          {
            onSuccess: () => {
              toast.success('Deleted the selected file!!')
              refetchChecklist()
            },
            onError: error => {
              getToastErrorMessage(error as AxiosError)
            },
          }
        )
      }
    },
    [deleteTaxRulingFileMutation, getConfirmation, legalEntity, refetchChecklist]
  )

  const columns = useMemo(() => {
    return getTaxRulingColumns({ handleDeleteClick, handleEditClick, handlePreview })
  }, [handleDeleteClick, handleEditClick, handlePreview])

  if (isFetching || deleteTaxRulingFileMutation.isLoading) return null

  return (
    <BorderlessBox bgClass="bg-[rgb(16,15,46)]" className="w-full py-3 gap-4 relative">
      <BorderlessBox bgClass="bg-[#1A193B]" className="w-full !px-2 !py-1">
        <NewTable
          tableRowClassName="!bg-[#141431] !border-none hover:!bg-[#141431]"
          tableCellClassname="!border-none"
          tableHeadClassName="!bg-[#212048] !text-white"
          data={tableData}
          columns={columns}
        />
      </BorderlessBox>
      {tableData.length < 2 && (
        <Button
          isDefaultSize={false}
          onClick={handleNewUpload}
          icon={IconsType.upload}
          iconPathClassName="stroke-indigo-300"
          className="!bg-indigo-950 !text-indigo-300 ms-auto">
          File
        </Button>
      )}
      <UploadTaxRulingsDocument
        documentTypeOptions={documentTypeOptions}
        leId={legalEntity || 0}
        selectedDocument={selectedDocument}
        isOpen={uploadModalIsOpen}
        onClose={toggleUploadModal}
      />
      <DocumentPreviewModal
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
        documentPreviewModal={isPreview && !!selectedDocument?.file}
        setDocumentPreviewModal={onClosePreview}
        selectedFile={selectedDocument?.file || undefined}
      />
      <ConfirmationModal />
    </BorderlessBox>
  )
}

export default RelavantTaxDocsTab
