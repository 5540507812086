import { ColumnDef } from '@tanstack/react-table'
import { otherAppendixType } from 'api/documents/types'
import Delete from 'assets/icons/delete'
import Typography, { Variant } from 'components/typography'

export const getOtherAppendicesColumns = ({
  handleDelete,
  handleEdit,
  handlePreview,
}: {
  handleDelete: (data: otherAppendixType) => void
  handleEdit: (data: otherAppendixType) => void
  handlePreview: (data: otherAppendixType) => void
}): ColumnDef<otherAppendixType>[] => {
  return [
    {
      header: 'Name',
      cell(props) {
        const data = props.row.original
        return (
          <Typography
            onClick={() => handlePreview(data)}
            variant={Variant.Callout}
            className="text-indigo-500 cursor-pointer"
            type="semibold">
            {data.file_name || `File - ${data.id}`}
          </Typography>
        )
      },
      size: 400,
    },
    {
      header: 'Actions',
      cell(props) {
        const data = props.row.original
        return (
          <div className="flex items-center gap-4">
            <Typography
              onClick={() => handleEdit(data)}
              variant={Variant.Callout}
              type="semibold"
              className="text-indigo-500 cursor-pointer">
              Edit
            </Typography>
            <div onClick={() => handleDelete(data)} className="flex items-center cursor-pointer">
              <Delete className="w-4 h-4" pathClassName="stroke-orange-600" />
            </div>
          </div>
        )
      },
      size: 1,
    },
  ]
}
