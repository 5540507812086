import { Box, MenuItem, Select } from '@mui/material'
import Typography, { Variant } from 'components/typography'
import _map from 'lodash/map'
import { Control, Controller, FieldValues } from 'react-hook-form'

function MUI_SELECT<T>({
  control,
  id,
  defaultValue,
  placeholder = 'Select...',
  description,
  options,
  labelClassName,
}: InputFieldProps<T & FieldValues>) {
  return (
    <div>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, name, ref, value } }) => {
          return (
            <Select
              size="small"
              fullWidth
              onChange={onChange}
              onBlur={onBlur}
              name={name}
              ref={ref}
              value={value}
              placeholder={placeholder}
              className="w-full"
              renderValue={selectedValue => {
                const selectedOption = options.find(option => option.value === selectedValue)
                return (
                  <Box>
                    <Typography style={labelClassName} variant={Variant.Callout}>
                      {selectedOption?.label}
                    </Typography>
                  </Box>
                )
              }}>
              {_map(options, option => (
                <MenuItem key={option.value} value={option.value}>
                  <Typography variant={Variant.Callout}>{option.label}</Typography>
                </MenuItem>
              ))}
            </Select>
          )
        }}
        shouldUnregister={false}
        name={String(id)}
        defaultValue={defaultValue}
      />
      {description && (
        <Typography variant={Variant.Callout} className="mt-[0.125rem] italic">
          {description}
        </Typography>
      )}
    </div>
  )
}

interface InputFieldProps<T extends FieldValues> {
  control: Control<FieldValues | T>
  id: keyof T
  defaultValue?: string
  placeholder?: string
  type?: React.HTMLInputTypeAttribute
  description?: string
  options: { label: string; value: string }[]
  labelClassName?: React.CSSProperties
}

export default MUI_SELECT
