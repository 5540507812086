import { StylesConfig } from 'react-select'

export const selectStyle = ({ height }: { height: string }): StylesConfig => {
  return {
    control: base => {
      return {
        ...base,
        boxShadow: 'none',
        fontSize: '0.75rem',
        fontWeight: 400,
        color: '#1E293B',
        border: `1px solid #CBD5E1`,
        borderRadius: '0.9rem',
        height: height,
        minHeight: height,
      }
    },

    indicatorsContainer: provided => ({
      ...provided,
      height: height,
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? '#F1F5F9' : 'white',
        color: '#1E293B',
        overflow: 'hidden',
        ':hover': {
          backgroundColor: '#F1F5F9',
          cursor: 'pointer',
        },
        ':active': {
          backgroundColor: '#F1F5F9',
        },
      }
    },
    menu: base => {
      return {
        ...base,
        borderRadius: '0.75rem',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
        overflow: 'hidden',
        border: `1px solid #CBD5E1`,
        padding: '1rem',
        zIndex: 20,
      }
    },
  }
}
