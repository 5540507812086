/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconsType } from 'assets/types'
import Input, { IconPlacement, InputVariant } from 'components/input'
import dayjs from 'dayjs'
import { dateFormatter } from 'formatters/dateFormatter'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { formatNumberStringToUS } from 'utils/numberUtils'

function INPUT_FIELDS<T>({
  control,
  label,
  id,
  defaultValue,
  placeholder = 'Type here...',
  type = 'text',
  isAnimatedLabel,
  required,
  disabled,
  variant,
  icon,
  newInput = true,
  iconPlacement,
  onHandleChange,
  onClick,
  className,
  characterLimit,
  autoComplete,
  onKeyDown,
  isCommaFormatted,
}: InputFieldProps<T & FieldValues>) {
  return (
    <Controller
      control={control}
      render={({ field: { onChange, onBlur, name, ref, value }, fieldState: { error } }) => {
        const descriptiveText = error?.message?.toString()
        const isError = Boolean(error?.message)

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (type === 'date') {
            e.target.value = dateFormatter(e.target.value)
            onChange(dayjs(e.target.value).format('YYYY-MM-DD'))
            onHandleChange && onHandleChange(e)
            return
          }
          const clonedEvent: React.ChangeEvent<HTMLInputElement> = {
            ...e,
            target: {
              ...e.target,
              value: isCommaFormatted ? e.target.value.replace(/([^0-9.+-])+/g, '') : e.target.value,
            },
          }
          onChange(clonedEvent)
          onHandleChange && onHandleChange(clonedEvent)
        }

        if (isCommaFormatted && typeof value == 'string') {
          value = formatNumberStringToUS(value)
        }

        return (
          <Input
            variant={variant}
            disabled={disabled}
            type={type}
            descriptiveText={descriptiveText}
            label={label}
            newInput={newInput}
            onBlur={onBlur}
            onChange={handleChange}
            name={name}
            ref={ref}
            error={isError}
            placeholder={placeholder}
            isAnimatedLabel={isAnimatedLabel}
            required={required}
            icon={icon}
            iconPlacement={iconPlacement}
            iconClassName="w-4 h-4"
            value={value || ''}
            onClick={onClick}
            className={className}
            characterLimit={characterLimit}
            defaultValue={value || ''}
            autoComplete={autoComplete}
            onKeyDown={onKeyDown}
          />
        )
      }}
      shouldUnregister={false}
      name={String(id)}
      defaultValue={defaultValue}
    />
  )
}

interface InputFieldProps<T extends FieldValues> {
  control: Control<FieldValues | T | any>
  label: string
  id: Path<T>
  newInput?: boolean
  defaultValue?: string
  placeholder?: string
  type?: React.HTMLInputTypeAttribute
  isAnimatedLabel?: boolean
  required?: boolean
  disabled?: boolean
  variant?: InputVariant
  icon?: IconsType
  iconPlacement?: IconPlacement
  onHandleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
  className?: string
  characterLimit?: number
  autoComplete?: 'off'
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  isCommaFormatted?: boolean
}

export default INPUT_FIELDS
