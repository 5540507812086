import axios from 'api'

import { DocumentResponse, LocalFileChecklistResponse, UpdateSelectedDocumentPayloadType } from '../types'

export const getLocalFileById = ({ documentId }: { documentId: number }) =>
  axios
    .get<DocumentResponse>(`/api/v1/org/{organization}/reports/local_file_document/${documentId}/`)
    .then(res => res.data)

export const getLocalFileCheckListById = ({ documentId }: { documentId: number }) =>
  axios
    .get<LocalFileChecklistResponse>(`/api/v1/org/{organization}/reports/local_file_document/${documentId}/checklist`)
    .then(res => res.data)

export const updateLocalFile = ({ data }: UpdateSelectedDocumentPayloadType) => {
  return axios
    .patch(`/api/v1/org/{organization}/reports/local_file_document/${data.document_id}/`, {
      document_owner: data.document_owner,
      integral_generated: data.integral_generated,
      is_archived: data.isArchived,
      name: data.name,
      status: data.status,
      type: data.type,
      year: data.year,
      legal_entity_id: data.legal_entity_id,
    })
    .then(res => res.data)
}

export const deleteLocalFile = ({ documentId }: { documentId: number }) =>
  axios.delete(`/api/v1/org/{organization}/reports/local_file_document/${documentId}/`).then(res => res.data)
