import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import DocumentPreviewModal from 'components/documentPreviewModal'
import NewTable from 'components/newTable'
import { getAppendicesConfig } from 'organisms/reportWizard/ReportWizard.helpers'
import React, { useCallback, useMemo, useState } from 'react'

import { getLocalEntityDocsTabColumns } from './LocalEntityTab.helpers'

type props = {
  data: ReturnType<typeof getAppendicesConfig>['Legal Entity']['data']
}

const LocalEntityTab = ({ data }: props) => {
  const [isFullScreen, setIsFullScreen] = useState(false)
  const tabledata: LocalEntityTabTableRow[] = useMemo(() => {
    return [
      {
        label: 'Corporate/Group Legal Entity Organization Chart',
        file: data?.['Corporate/Group Legal Entity Organization Chart'].file,
        file_name: data?.['Corporate/Group Legal Entity Organization Chart'].file_name,
      },
      {
        label: 'Financial Statements',
        file: data?.['Financial Statements'].file,
        file_name: data?.['Financial Statements'].file_name,
      },
    ].filter(data => !!data.file)
  }, [data])

  const [selectedForPreview, setSelectedForPreview] = useState<LocalEntityTabTableRow>()

  const onClosePreview = useCallback(() => {
    setSelectedForPreview(undefined)
  }, [])

  const handlePreview = useCallback((data: LocalEntityTabTableRow) => {
    if (data.file) setSelectedForPreview(data)
  }, [])

  const columns = useMemo(() => {
    return getLocalEntityDocsTabColumns({
      handlePreview,
    })
  }, [handlePreview])

  return (
    <BorderlessBox bgClass="bg-[rgb(16,15,46)]" className="w-full py-3">
      <BorderlessBox bgClass="bg-[#1A193B]" className="w-full !px-2 !py-1">
        <NewTable
          tableRowClassName="!bg-[#141431] !border-none hover:!bg-[#141431]"
          tableCellClassname="!border-none"
          tableHeadClassName="!bg-[#212048] !text-white"
          data={tabledata}
          columns={columns}
        />
      </BorderlessBox>
      <DocumentPreviewModal
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
        documentPreviewModal={!!selectedForPreview?.file}
        setDocumentPreviewModal={onClosePreview}
        selectedFile={selectedForPreview?.file || undefined}
      />
    </BorderlessBox>
  )
}

export default LocalEntityTab

export type LocalEntityTabTableRow = {
  label: string
  file: string | null | undefined
  file_name: string | null | undefined
}
