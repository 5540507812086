/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField } from '@mui/material'
import cx from 'classnames'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import { useState } from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'

import { useStyles } from './styles'

function MuiTextField<T>({
  control,
  id,
  defaultValue,
  disabled,
  className,
  title,
  placeholder = 'Write something...',
  rightComponent,
  minRows = 4,
  children,
  focusColor = '#EFF6FF',
}: InputFieldProps<T & FieldValues>) {
  const classes = useStyles()
  const [isFocused, setFocused] = useState(false)
  const handleFocus = () => setFocused(true)

  const handleBlur = () => {
    setFocused(false)
  }
  return (
    <Controller
      name={String(id)}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => {
        const { name, onChange, ref, value } = field
        return (
          <BorderlessBox
            topRightComponents={rightComponent}
            label={title}
            className={cx('mt-4 w-full overflow-hidden', className)}>
            <TextField
              disabled={disabled}
              name={name}
              className=" bg-white rounded-lg mt-2"
              defaultValue={defaultValue}
              sx={{
                '& fieldset': { border: 'none' },
                width: '100%',
                backgroundColor: isFocused ? focusColor : '#F1F5F9',
              }}
              placeholder={placeholder}
              onFocus={handleFocus}
              onBlur={handleBlur}
              ref={ref}
              onChange={onChange}
              value={value}
              fullWidth
              multiline
              minRows={minRows}
              inputProps={{
                className: classes?.inputProps,
                style: {
                  fontSize: '0.8125rem',
                  color: '#1E293B',
                  fontWeight: '600',
                  width: '100%',
                  overflowY: 'auto',
                },
              }}
            />
            {children}
          </BorderlessBox>
        )
      }}
    />
  )
}

interface InputFieldProps<T extends FieldValues> {
  control: Control<FieldValues | T | any>
  id: keyof T
  defaultValue?: string
  disabled?: boolean
  className?: string
  title?: string
  placeholder?: string
  children?: React.ReactNode
  rightComponent?: JSX.Element
  minRows?: number
  focusColor?: string
}

export default MuiTextField
