import { IconProps } from 'assets'
import React from 'react'

const HeapSnapShot = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M14.5 18.5C14.9167 18.5 15.2708 18.3542 15.5625 18.0625C15.8542 17.7708 16 17.4167 16 17C16 16.5833 15.8542 16.2292 15.5625 15.9375C15.2708 15.6458 14.9167 15.5 14.5 15.5C14.0833 15.5 13.7292 15.6458 13.4375 15.9375C13.1458 16.2292 13 16.5833 13 17C13 17.4167 13.1458 17.7708 13.4375 18.0625C13.7292 18.3542 14.0833 18.5 14.5 18.5ZM9.45 18.45L15.95 11.95L14.55 10.55L8.05 17.05L9.45 18.45ZM9.5 13.5C9.91667 13.5 10.2708 13.3542 10.5625 13.0625C10.8542 12.7708 11 12.4167 11 12C11 11.5833 10.8542 11.2292 10.5625 10.9375C10.2708 10.6458 9.91667 10.5 9.5 10.5C9.08333 10.5 8.72917 10.6458 8.4375 10.9375C8.14583 11.2292 8 11.5833 8 12C8 12.4167 8.14583 12.7708 8.4375 13.0625C8.72917 13.3542 9.08333 13.5 9.5 13.5ZM6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H14L20 8V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM13 9V4H6V20H18V9H13Z"
        fill="currentColor"
        className={pathClassName}
      />
    </svg>
  )
}

export default HeapSnapShot
