import axios from 'api'

import { BenchmarkingMetricsResponse, TransactionMetricsResponse } from './types'

export const getTransactionMetricsData = (
  year?: number,
  legal_entity?: string,
  tp_method?: string,
  transaction_type?: string
) => {
  const params: { [key: string]: number | string | [] } = {}
  if (year) {
    params.year = year
  }

  if (legal_entity) {
    params.legal_entity = legal_entity
  }
  if (tp_method) {
    params.tp_method = tp_method
  }
  if (transaction_type) {
    params.transaction_type = transaction_type
  }

  return axios
    .get<TransactionMetricsResponse>(`/api/v1/org/{organization}/metrics/transaction_metrics/get_metrics/`, { params })
    .then(res => res.data)
}

export const getBenchmarkingMetricsData = (
  year?: number,
  filter?: string,
  legal_entity?: string,
  tp_method?: string,
  transaction_type?: string
) => {
  const params: { [key: string]: number | string | [] } = {}
  if (year) {
    params.year = year
  }
  if (filter) {
    params.comparison_metric_type = filter
  }
  if (legal_entity) {
    params.legal_entity = legal_entity
  }
  if (tp_method) {
    params.tp_method = tp_method
  }
  if (transaction_type) {
    params.transaction_type = transaction_type
  }
  return axios
    .get<BenchmarkingMetricsResponse[]>(
      `/api/v1/org/{organization}/metrics/transaction_metrics/get_benchmarking_metrics/`,
      { params }
    )
    .then(res => res.data)
}
