import { IconsType } from 'assets/types'

export enum IconPlacement {
  Left = 'Left',
  Right = 'Right',
}

export enum ButtonVariant {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary',
  Prominent = 'Prominent',
  AIGradient = 'AIGradient',
}

export enum ButtonSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  ExtraSmall = 'ExtraSmall',
}

export interface ButtonProps {
  children?: React.ReactNode
  isFullWidth?: boolean
  icon?: IconsType
  count?: number
  primaryColor?: string
  secondaryColor?: string
  iconPlacement?: IconPlacement
  variant?: ButtonVariant
  disabled?: boolean
  buttonType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type']
  size?: ButtonSize
  onClick?: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick']
  className?: string
  iconCLass?: string
  successState?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any
  isDefaultSize?: boolean
  iconPathClassName?: string
}

export interface RestButtonConfig {
  isFullWidth: ButtonProps['isFullWidth']
  iconPlacement: ButtonProps['iconPlacement']
  icon: ButtonProps['icon']
}

export interface ButtonConfig {
  background: string
  border: string
  hover: string
  text: string
  disabled: string
  focus: string
  icon: string
  disabledIcon: string
}
