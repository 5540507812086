import { IconProps } from 'assets'
import React from 'react'

const Reset = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M1.5 0.875488V4.62549H1.93614M13.4536 6.12549C13.0845 3.16575 10.5597 0.875488 7.5 0.875488C4.98197 0.875488 2.82622 2.42662 1.93614 4.62549M1.93614 4.62549H5.25M13.5 12.8755V9.12549H13.0639M13.0639 9.12549C12.1738 11.3244 10.018 12.8755 7.5 12.8755C4.44029 12.8755 1.91549 10.5852 1.54642 7.62549M13.0639 9.12549H9.75"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Reset
