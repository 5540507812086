import Checkbox from 'components/checkbox'
import { ReactNode } from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'

function CHECKBOX_FIELD<T>({
  control,
  label,
  id,
  defaultValue,
  disabled,
  className,
  tooltipTitle,
  labelIcon,
}: InputFieldProps<T & FieldValues>) {
  return (
    <Controller
      control={control}
      render={({ field: { onChange, onBlur, name, ref, value } }) => {
        return (
          <Checkbox
            checked={value}
            id={String(id)}
            label={label}
            onBlur={onBlur}
            onChange={onChange}
            name={name}
            ref={ref}
            disabled={disabled}
            className={className}
            tooltipTitle={tooltipTitle}
            labelIcon={labelIcon}
          />
        )
      }}
      shouldUnregister={false}
      name={String(id) as never}
      defaultValue={defaultValue as never}
    />
  )
}

interface InputFieldProps<T extends FieldValues> {
  control: Control<T>
  label: ReactNode
  id: keyof T
  defaultValue?: string
  placeholder?: string
  type?: React.HTMLInputTypeAttribute
  value?: string
  disabled?: boolean
  className?: string
  tooltipTitle?: ReactNode
  labelIcon?: ReactNode
}

export default CHECKBOX_FIELD
