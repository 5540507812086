/* eslint-disable @typescript-eslint/no-explicit-any */
import cx from 'classnames'
import Button, { ButtonVariant } from 'components/button'
import { ButtonProps } from 'components/button/button.types'
import Typography, { Variant } from 'components/typography'
import React from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
import Select, { components, MenuProps } from 'react-select'
import { components as Component } from 'react-select'

import { selectStyle } from './styles'

const Menu = components.Menu

function SelectWithRadioButton<T>({
  control,
  options,
  setSelectedOptions,
  id,
  label,
  defaultValue,
  required,
  className,
  height,
  placeholder = 'Select the options',
  showSelectMenuButton = false,
  selectMenuButtonProps,
}: SelectWithRadioButtonProps<T & FieldValues>) {
  const customMenuRenderer = (props: MenuProps) => {
    return (
      <Menu {...props}>
        {props.children}
        {showSelectMenuButton && (
          <Button className="ml-2 my-2" onClick={selectMenuButtonProps?.buttonAction} variant={ButtonVariant.Secondary}>
            {selectMenuButtonProps?.buttonLabel}
            {selectMenuButtonProps?.children}
          </Button>
        )}
      </Menu>
    )
  }

  return (
    <Controller
      name={id as string}
      control={control}
      render={({ field }) => {
        const { name, onBlur, onChange, ref, value } = field
        return (
          <div className={cx('w-full', className)}>
            {label && (
              <Typography variant={Variant.Callout} type="semibold" className="mb-[0.125rem] flex items-center">
                <span className="my-1">{label}</span>
                {required && <span className="mb-2 text-red-500">*</span>}
              </Typography>
            )}
            <Select
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              placeholder={placeholder}
              name={name}
              ref={ref}
              onBlur={onBlur}
              defaultValue={defaultValue}
              menuShouldScrollIntoView
              value={value}
              styles={selectStyle({ height })}
              onChange={options => {
                if (Array.isArray(options) && setSelectedOptions) {
                  setSelectedOptions(options)
                }
                onChange(options)
              }}
              options={options}
              components={{
                Option: props => {
                  if (props.children === 'Custom') {
                    return null
                  }
                  return (
                    <Component.Option {...props}>
                      <div className="flex flex-col justify-center h-[1.5rem]">
                        <div className="flex items-center text-start">
                          <input
                            className="w-[1.2rem] h-[1.2rem] accent-blue800 border-2 rounded border-solid border-neutral300 mr-4"
                            type="radio"
                            checked={props.isSelected}
                            readOnly
                          />
                          <Typography
                            className="whitespace-nowrap overflow-hidden text-ellipsis text-neutral800"
                            variant={Variant.Body}>
                            {props.children}
                          </Typography>
                        </div>
                      </div>
                    </Component.Option>
                  )
                },
                Menu: customMenuRenderer,
              }}
            />
          </div>
        )
      }}
    />
  )
}

export interface options {
  value: string
  label: string
}

interface SelectWithRadioButtonProps<T extends FieldValues> {
  control: Control<FieldValues | T | any>
  label?: string
  id: keyof T
  placeholder?: string
  options?: { value: string; label: string }[]
  className?: string
  required?: boolean
  setSelectedOptions?: React.Dispatch<React.SetStateAction<options[]>>
  defaultValue?: { value: string; label: string }[]
  height: string
  showSelectMenuButton?: boolean
  selectMenuButtonProps?: SelectMenuButtonProps
}

export interface SelectMenuButtonProps extends ButtonProps {
  buttonLabel: string
  buttonAction: () => void
}

export default SelectWithRadioButton
