import classNames from 'classnames'
import Typography, { Variant } from 'components/typography'
import React from 'react'

type Props = {
  totalTasks: number
  completedTasks: number
  className?: string
}

const TaskCompetionStatus = ({ totalTasks, completedTasks, className }: Props) => {
  return (
    <div className={classNames('flex items-center', className)}>
      <Typography variant={Variant.Callout} type="semibold" className="inline text-gray-300">
        Tasks
      </Typography>
      <Typography variant={Variant.Footnote} type="semibold" className="inline text-gray-300">
        {`(${completedTasks}/${totalTasks} completed)`}
      </Typography>
    </div>
  )
}

export default TaskCompetionStatus
