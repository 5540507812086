import BorderBoxWrapper from 'components/borderBoxWrapper'
import UploadDocuments from 'components/uploadDocuments'
import React from 'react'
import { Control, FieldValues, Path } from 'react-hook-form'

import INPUT_FIELDS from '../inputField'

type FileUploadWithNameProps<T extends FieldValues> = {
  control?: Control<T>
  nameId?: Path<T>
  nameLabel?: string
  file: File | null
  setFile: (file: File) => void
  label?: string
  fileName?: string
  handleDelete?: VoidFunction
  showDelete?: boolean
  accept?: string
  fileUrl?: string
  className?: string
  nameRequired?: boolean
  placeholder?: string
}

function FileUploadWithName<T extends FieldValues>({
  control,
  nameId,
  nameLabel = 'Name',
  file,
  setFile,
  label,
  fileName,
  showDelete,
  accept,
  fileUrl,
  handleDelete,
  className,
  nameRequired,
  placeholder,
}: FileUploadWithNameProps<T>) {
  return (
    <BorderBoxWrapper className={className}>
      {!!(nameId && control) && (
        <INPUT_FIELDS
          newInput={false}
          className={`mb-4 `}
          required={nameRequired}
          control={control}
          id={nameId}
          label={nameLabel}
          placeholder={placeholder}
        />
      )}
      <UploadDocuments
        fileName={fileName}
        label={label}
        file={file}
        setFile={setFile}
        fileUrl={fileUrl}
        showDelete={showDelete}
        handleDelete={handleDelete}
        accept={accept}
      />
    </BorderBoxWrapper>
  )
}

export default FileUploadWithName
