/* eslint-disable @typescript-eslint/no-explicit-any */
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import _isEmpty from 'lodash/isEmpty'
import React, { useCallback, useMemo } from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
import Select from 'react-select'
import { components as Component } from 'react-select'
import { SelectOptions } from 'types/common.types'

import { selectStyle } from './styles'

function SelectDropDownWithCheckBox<T>({
  control,
  options,
  setSelectedOptions,
  id,
  label,
  defaultValue,
  disabled,
  required,
  className,
  height,
  placeholder = 'Select the options',
  isCustomFilter = false,
  menuPortalTarget,
  menuPlacement = 'auto',
}: SelectDropDownWithCheckBoxProps<T & FieldValues>) {
  const customFilter = useCallback(({ data }: { data: SelectOptions }, input: string) => {
    if (input) {
      if (data.filtertext) {
        if (data.filtertext.toLowerCase().includes(input.toLowerCase())) return true
      }
      return false
    }
    return true // if not search, then all match
  }, [])

  const filter = useMemo(() => {
    return isCustomFilter ? customFilter : undefined
  }, [customFilter, isCustomFilter])

  return (
    <Controller
      name={id as string}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { name, onBlur, onChange, ref, value } = field

        const newValue = value?.map((opt: SelectOptions) => options?.find(option => option.value == opt.value) || opt)
        return (
          <div className={cx('w-full', className)}>
            {label && (
              <Typography variant={Variant.Callout} type="semibold" className="mb-[0.125rem] flex items-center">
                <span className="my-1">{label}</span>
                {required && <span className="mb-2 text-red-500">*</span>}
              </Typography>
            )}
            <Select
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              filterOption={filter}
              placeholder={placeholder}
              name={name}
              ref={ref}
              onBlur={onBlur}
              isDisabled={disabled}
              defaultValue={defaultValue}
              menuShouldScrollIntoView
              isMulti
              menuPlacement={menuPlacement}
              menuPortalTarget={menuPortalTarget}
              value={newValue}
              styles={selectStyle({ height, isError: !!(error && !_isEmpty(error)), disableDropdown: !!disabled })}
              onChange={options => {
                if (Array.isArray(options) && setSelectedOptions) {
                  setSelectedOptions(options)
                }
                onChange([...options])
              }}
              options={options}
              components={{
                Option: props => {
                  return (
                    <Component.Option {...props}>
                      <div className="flex items-center text-start">
                        <input
                          className="w-[1.2rem] h-[1.2rem] flex-shrink-0 accent-blue800 border-2 rounded border-solid border-neutral300 mr-4"
                          type="checkbox"
                          checked={props.isSelected}
                          readOnly
                        />
                        <Typography
                          className="whitespace-nowrap overflow-hidden w-auto truncate"
                          variant={Variant.Body}>
                          {props.children}
                        </Typography>
                      </div>
                    </Component.Option>
                  )
                },
              }}
            />
            {error && !_isEmpty(error) && (
              <Typography variant={Variant.Callout} className="pt-[0.125rem] text-red500">
                {error.message}
              </Typography>
            )}
          </div>
        )
      }}
    />
  )
}

interface SelectDropDownWithCheckBoxProps<T extends FieldValues> {
  control: Control<FieldValues | T | any>
  label?: string
  id: keyof T
  placeholder?: string
  options?: SelectOptions[]
  className?: string
  required?: boolean
  disabled?: boolean
  setSelectedOptions?: React.Dispatch<React.SetStateAction<SelectOptions[]>>
  defaultValue?: SelectOptions[]
  height?: string
  isCustomFilter?: boolean
  menuPortalTarget?: HTMLElement | null | undefined
  menuPlacement?: 'auto' | 'bottom' | 'top'
}

export default SelectDropDownWithCheckBox
