import AppColors from 'constants/colors'
import { GroupBase, StylesConfig } from 'react-select'
import { gray } from 'tailwindcss/colors'
import { SelectOptions } from 'types/common.types'

export const selectStyle = (
  errors: boolean,
  isDark: boolean,
  maxHeight: string
): StylesConfig<SelectOptions, false, GroupBase<SelectOptions>> => {
  return {
    control: (base, { isDisabled }) => {
      return {
        ...base,
        boxShadow: 'none',
        fontSize: '0.8125rem',
        fontWeight: 400,
        color: isDisabled ? AppColors.gray['400'] : '#1E293B',
        border: `1px solid ${errors ? '#F87171' : !isDark ? '#CBD5E1' : AppColors.gray[800]}`,
        borderRadius: '0.75rem',
        textAlign: 'left',
        cursor: isDisabled ? 'not-allowed' : 'auto',
        backgroundColor: errors
          ? AppColors.red50
          : isDisabled
          ? AppColors.grey200
          : !isDark
          ? AppColors.white
          : 'rgba(134, 134, 134, 0.05)',
      }
    },
    container: base => {
      return {
        ...base,
        pointerEvents: 'auto',
      }
    },
    menuList: provided => ({
      ...provided,
      maxHeight: maxHeight,
      overflowY: 'auto',
    }),
    placeholder: base => {
      return {
        ...base,
        color: !isDark ? AppColors.gray['400'] : AppColors.gray[500],
      }
    },
    option: (styles, { isFocused, isSelected, isDisabled }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? '#bfdbfe' : isFocused ? '#EFF6FF' : 'white',
        color: '#1E293B',
        fontSize: '0.8125rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        zIndex: 20,
        border: isSelected ? '1px solid #93c5fd' : '',
        opacity: isDisabled ? 0.5 : 1,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      }
    },
    singleValue(base) {
      return {
        ...base,
        color: !isDark ? '#1E293B' : AppColors.gray[300],
      }
    },
    indicatorSeparator() {
      return {
        display: 'none',
      }
    },
    dropdownIndicator(base) {
      return {
        ...base,
        color: gray[400],
      }
    },
  }
}
