import { useMutation } from '@tanstack/react-query'
import { setTaxRulingAppendices } from 'api/documents'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import Button, { ButtonVariant } from 'components/button'
import Loading from 'components/loading'
import Modal from 'components/modal'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { SELECT_DROPDOWN } from 'organisms/fieldRenderers'
import FileUploadWithName from 'organisms/fieldRenderers/fields/fileUploadWithName/FIleUploadWithName'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { SelectOptions } from 'types/common.types'
import { getToastErrorMessage } from 'utils/utils'

import { relevantTaxRulingTableData } from '../RelavantTaxDocsTab'

type Props = {
  isOpen: boolean
  onClose: VoidFunction
  documentTypeOptions: SelectOptions[]
  leId: number
  selectedDocument?: relevantTaxRulingTableData
}

function UploadTaxRulingsDocument({ isOpen, onClose, documentTypeOptions, selectedDocument, leId }: Props) {
  const { refetchChecklist } = useReportWizardContext()
  const { control, setValue, getValues, watch, formState, reset, handleSubmit } = useForm<FIELD_VALUES>({
    defaultValues: {},
  })

  const setFile = useCallback(
    (file: File) => {
      setValue('file', file, {
        shouldDirty: true,
      })
      if (!getValues('file_name'))
        setValue('file_name', file.name, {
          shouldValidate: true,
          shouldDirty: true,
        })
    },
    [getValues, setValue]
  )

  const updateTaxRulingMutation = useMutation(setTaxRulingAppendices)

  const file = watch('file')
  const documentType = watch('documentType')

  const handleSave = useCallback(
    (data: FIELD_VALUES) => {
      if (
        updateTaxRulingMutation.isLoading ||
        !data.file ||
        !(selectedDocument?.documentType || data.documentType?.value)
      ) {
        return
      }
      updateTaxRulingMutation.mutate(
        {
          leId: leId,
          payload: {
            [selectedDocument?.documentType || data.documentType?.value + '_file']:
              typeof data.file == 'string' ? undefined : data.file,
            [selectedDocument?.documentType || data.documentType?.value + '_file_name']:
              data.file == undefined
                ? undefined
                : data.file_name ||
                  (data.file instanceof File
                    ? data.file.name
                    : selectedDocument?.label || String(data.documentType?.label || '') || 'Tax ruling document'),
          },
        },
        {
          onSuccess: () => {
            toast.success('Document updated!!')
            refetchChecklist()
            onClose()
          },
          onError(error) {
            getToastErrorMessage(error as AxiosError)
          },
        }
      )
    },
    [leId, onClose, refetchChecklist, selectedDocument?.documentType, selectedDocument?.label, updateTaxRulingMutation]
  )

  const disabled =
    !Object.keys(formState.dirtyFields).length || !file || !documentType?.value || updateTaxRulingMutation.isLoading

  useEffect(() => {
    if (isOpen && selectedDocument) {
      reset({
        documentType: {
          label: selectedDocument.label,
          value: selectedDocument.documentType,
        },
        file: selectedDocument.file,
        file_name: selectedDocument.file_name || '',
      })
    } else {
      reset({
        documentType: documentTypeOptions?.[0],
        file: undefined,
        file_name: '',
      })
    }
  }, [documentTypeOptions, isOpen, reset, selectedDocument])

  return (
    <Modal
      containerClassName={'!p-6 w-[56.875rem] text-left relative'}
      isOpen={isOpen}
      onClose={onClose}
      title={`${selectedDocument ? 'Edit' : 'Add'} ${
        selectedDocument?.label || documentType?.label || 'Relavant Tax Ruling document'
      }`}>
      <SELECT_DROPDOWN
        id="documentType"
        label="Select document type"
        control={control}
        disabled={!!selectedDocument?.documentType || documentTypeOptions.length <= 1}
        required
        options={documentTypeOptions}
      />
      <FileUploadWithName
        fileName={typeof file == 'string' ? selectedDocument?.file_name || selectedDocument?.label : undefined}
        label="Supporting Document"
        file={file instanceof File ? file : null}
        setFile={setFile}
        fileUrl={typeof file == 'string' ? file : undefined}
        className="bg-white w-full"
        control={control}
        nameId={'file_name'}
        nameLabel={'Document Name'}
        accept=".pdf"
      />
      <Button
        disabled={disabled}
        onClick={handleSubmit(handleSave)}
        className="ms-auto"
        isDefaultSize={false}
        icon={selectedDocument ? IconsType.save : IconsType.plus}
        iconPathClassName={disabled ? 'stroke-gray-400' : 'stroke-white'}
        variant={ButtonVariant.Primary}>
        Save
      </Button>
      {updateTaxRulingMutation.isLoading && (
        <Loading className="bg-white absolute inset-2 flex items-center justify-center" />
      )}
    </Modal>
  )
}

type FIELD_VALUES = {
  documentType?: SelectOptions
  file_name?: string
  file?: File | null | string
}

export default UploadTaxRulingsDocument
