import { IconsType } from 'assets/types'
import { ButtonVariant } from 'components/button'
import Button from 'components/button/Button'
import { INPUT_FIELDS } from 'organisms/fieldRenderers'
import React, { useState } from 'react'
import { Control } from 'react-hook-form'

export const EditableFilenameCell = ({ id, control }: { id: string; control: Control<Record<string, string>> }) => {
  const [editable, setEditable] = useState(false)
  const onToggleEdit = () => {
    setEditable(o => !o)
  }
  return (
    <>
      <div className="flex items-center gap-4">
        {/* <TextField id={id} variant="standard" disabled={!editable} /> */}
        <INPUT_FIELDS disabled={!editable} key={id} className="w-full max-w-xs" control={control} label="" id={id} />
        <Button
          icon={IconsType.edit}
          variant={ButtonVariant.Tertiary}
          className="h-[38px]"
          onClick={onToggleEdit}></Button>
      </div>
    </>
  )
}
