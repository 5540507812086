/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormHelperText } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import classNames from 'classnames'
import { Variant } from 'components/typography'
import Typography from 'components/typography/Typography'
import React, { ReactElement } from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'

function RADIO_BUTTON_GROUP<T>({
  control,
  label,
  id,
  defaultValue,
  required,
  row = false,
  disabled = false,
  radioOptions,
  classes,
  radioGroupClassName,
}: RadioButtonGroupProps<T & FieldValues>) {
  return (
    <Controller
      control={control}
      render={({ field: { onChange, onBlur, ref, value }, formState }) => {
        const { errors } = formState
        const isError = Boolean(errors[id]?.message)
        const descriptiveText = errors[id]?.message?.toString()

        return (
          <div>
            {label && (
              <Typography
                variant={Variant.ContainerText}
                type="semibold"
                className={`mb-[0.125rem] flex items-center text-slate-600 ${classes?.label || ''}`}>
                <span className="my-1">{label}</span>
                {required && <span className="mb-2 text-red-500">*</span>}
              </Typography>
            )}

            <RadioGroup sx={radioGroupClassName} row={row} onChange={onChange} onBlur={onBlur} ref={ref}>
              {radioOptions.map(option => (
                <FormControlLabel
                  key={String(option.value)}
                  value={option.value}
                  disabled={disabled || option?.disabled || false}
                  control={<Radio size="small" className={classNames(classes?.option?.radio, '-my-2')} />}
                  label={
                    typeof option.label == 'string' ? (
                      <Typography variant={Variant.Callout} type="semibold">
                        {option.label}
                      </Typography>
                    ) : (
                      option.label
                    )
                  }
                  checked={option.value === value}
                />
              ))}
            </RadioGroup>
            {isError && <FormHelperText error>{descriptiveText}</FormHelperText>}
          </div>
        )
      }}
      shouldUnregister={false}
      name={String(id)}
      defaultValue={defaultValue}
    />
  )
}

interface RadioButtonGroupProps<T extends FieldValues> {
  control: Control<FieldValues | T | any>
  label?: string
  id: keyof T
  defaultValue?: string
  type?: React.HTMLInputTypeAttribute
  required?: boolean
  row?: boolean
  disabled?: boolean
  classes?: { root?: string; label?: string; option?: { label?: string; radio?: string } }
  radioOptions: { value: string; label: ReactElement | string; disabled?: boolean }[]
  radioGroupClassName?: React.CSSProperties
}

export default RADIO_BUTTON_GROUP
