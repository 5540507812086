import cx from 'classnames'
import classNames from 'classnames'
import Loading from 'components/loading'
import { PORTAL_IDS } from 'components/Portal/constants'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'

import AppLayoutHeader from '../../components/appLayoutHeader/AppLayoutHeader'
import { getRightComponent } from './appLayout.helper'
import { AppLayoutSectionProps } from './types/appLayout.types'

const AppLayoutSection = ({ children, activeLink, breadcrumbs }: AppLayoutSectionProps) => {
  const { isSidebarOpen } = useSelector((state: RootState) => state.app)
  const [pageChanging, setPageChanging] = useState(false)
  const [prevRoutePathname, setPrevRoutePathname] = useState<string | null>(null)

  const Router = useRouter()
  const dispatch = useDispatch()
  const { setStickyFooterHeight } = useReportWizardContext()

  const rightComponent = useMemo(
    () => getRightComponent({ activeLink, Router, dispatch }),
    [activeLink, Router, dispatch]
  )

  useEffect(() => {
    const startPageChange = (routePath: string) => {
      const prevRoute = prevRoutePathname
      let currentRoute = prevRoutePathname
      if (routePath) {
        const url = new URL(routePath, 'http://dummy-website.xyz')
        const currentPathname = url.pathname
        currentRoute = currentPathname
        setPrevRoutePathname(currentRoute)
      }
      if (prevRoute !== currentRoute) {
        setPageChanging(true)
      }
    }
    const endPageChange = () => {
      setPageChanging(false)
    }

    Router.events.on('routeChangeStart', startPageChange)
    Router.events.on('routeChangeComplete', endPageChange)
    Router.events.on('routeChangeError', endPageChange)

    return () => {
      Router.events.off('routeChangeStart', startPageChange)
      Router.events.off('routeChangeComplete', endPageChange)
      Router.events.off('routeChangeError', endPageChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Router])
  const footerRef = useRef<HTMLDivElement>(null)
  const [padding, setPadding] = useState<boolean>(false)
  useEffect(() => {
    const divElement = footerRef.current
    if (divElement) {
      const resizeObserver = new ResizeObserver(entries => {
        let isPaddingRequired = false
        for (const entry of entries) {
          if (entry.contentRect.height > 10) {
            isPaddingRequired = true
            break
          }
        }
        setStickyFooterHeight(divElement.clientHeight)
        setPadding(isPaddingRequired)
      })
      const timeoutId = setTimeout(() => {
        resizeObserver.observe(divElement)
      }, 100)
      return () => {
        clearTimeout(timeoutId)
        resizeObserver.unobserve(divElement)
      }
    }
  }, [activeLink, children, setStickyFooterHeight])

  return (
    <div
      className={cx(
        'bg-neutral-50 h-[calc(100vh-4rem)] pb-4 pr-6 pl-6 pt-4 overflow-y-auto transition-all duration-300 ease-in-out',
        {
          'pr-6': isSidebarOpen,
          'p-[calc(100%-18rem)]': !isSidebarOpen,
        }
      )}>
      <AppLayoutHeader breadcrumbs={breadcrumbs} headerRightComponent={rightComponent} activeLink={activeLink} />
      <div className={` ${padding ? 'pb-16' : ''}`}>{children}</div>
      <div
        ref={footerRef}
        id={PORTAL_IDS.FOOTER_COMPONENT_ID}
        className={classNames('absolute bottom-0 border-0 border-l left-0 right-0 border-solid border-gray-200')}
      />
      {pageChanging && (
        <div className="flex absolute inset-0 flex-col h-full items-center bg-white z-50 justify-center">
          <Loading className="flex flex-col items-center justify-center" />
          <span className="w-[27.25vw] text-body text-center mt-4">
            Switching the module and fetching required resources ...
          </span>
        </div>
      )}
    </div>
  )
}

export default AppLayoutSection
