import { useMutation } from '@tanstack/react-query'
import { otherAppendixType } from 'api/documents/types'
import { patchAppendixFile } from 'api/legalEntity/appendixFiles'
import { IconsType } from 'assets/types'
import { AxiosError } from 'axios'
import Button, { ButtonVariant } from 'components/button'
import Loading from 'components/loading'
import Modal from 'components/modal'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import FileUploadWithName from 'organisms/fieldRenderers/fields/fileUploadWithName/FIleUploadWithName'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { getToastErrorMessage } from 'utils/utils'

type Props = {
  isOpen: boolean
  onClose: VoidFunction
  selectedDocument: otherAppendixType | undefined
}

function EditOtherAppendixFile({ isOpen, onClose, selectedDocument }: Props) {
  const { refetchChecklist } = useReportWizardContext()
  const { control, setValue, getValues, watch, formState, reset, handleSubmit } = useForm<FIELD_VALUES>({
    defaultValues: {},
  })

  const setFile = useCallback(
    (file: File) => {
      setValue('file', file, {
        shouldDirty: true,
      })
      if (!getValues('file_name'))
        setValue('file_name', file.name, {
          shouldValidate: true,
          shouldDirty: true,
        })
    },
    [getValues, setValue]
  )
  const file = watch('file')

  const patchAppendixFileMutation = useMutation(patchAppendixFile)

  const handleSave = useCallback(
    (data: FIELD_VALUES) => {
      if (!data.file || !selectedDocument) {
        return
      }
      patchAppendixFileMutation.mutate(
        {
          url: selectedDocument?.appendix_url,
          payload: {
            other_file_name: data.file_name,
            other_file: typeof data.file == 'string' ? undefined : data.file,
          },
        },
        {
          onSuccess: () => {
            toast.success('Document updated!!')
            refetchChecklist()
            onClose()
          },
          onError(error) {
            getToastErrorMessage(error as AxiosError)
          },
        }
      )
    },
    [onClose, patchAppendixFileMutation, refetchChecklist, selectedDocument]
  )

  const disabled = !Object.keys(formState.dirtyFields).length || !file || patchAppendixFileMutation.isLoading

  useEffect(() => {
    if (isOpen && selectedDocument) {
      reset({
        file: selectedDocument.file,
        file_name: selectedDocument.file_name || '',
      })
    } else {
      reset({
        file: undefined,
        file_name: '',
      })
    }
  }, [isOpen, reset, selectedDocument])

  return (
    <Modal
      containerClassName={'!p-6 w-[56.875rem] text-left relative'}
      isOpen={isOpen}
      onClose={onClose}
      title={`Edit uploaded document`}>
      <FileUploadWithName
        fileName={typeof file == 'string' ? selectedDocument?.file_name || 'File' : undefined}
        label="Document"
        file={file instanceof File ? file : null}
        setFile={setFile}
        fileUrl={typeof file == 'string' ? file : undefined}
        className="bg-white w-full !border-0 !p-0"
        control={control}
        nameId={'file_name'}
        nameLabel={'Document Name'}
        accept=".pdf"
      />
      <Button
        disabled={disabled}
        onClick={handleSubmit(handleSave)}
        className="ms-auto"
        isDefaultSize={false}
        icon={selectedDocument ? IconsType.save : IconsType.plus}
        iconPathClassName={disabled ? 'stroke-gray-400' : 'stroke-white'}
        variant={ButtonVariant.Primary}>
        Save
      </Button>
      {patchAppendixFileMutation.isLoading && (
        <Loading className="bg-white absolute inset-2 flex items-center justify-center" />
      )}
    </Modal>
  )
}

type FIELD_VALUES = {
  file_name?: string
  file?: File | null | string
}

export default EditOtherAppendixFile
