import cx from 'classnames'
import InfoLabel from 'components/input/components/infoLabel'
import React, { forwardRef } from 'react'

const TextArea = (
  {
    label,
    id,
    name,
    rows,
    newInput = true,
    placeholder,
    onChange,
    onBlur,
    disabled,
    required = false,
    value,
    defaultValue,
    classes,
    error,
    ...rest
  }: TextAreaProps,
  ref: React.LegacyRef<HTMLTextAreaElement>
): JSX.Element => {
  return (
    <div className="flex flex-col items-start gap-1">
      {!!label && <InfoLabel newInput={newInput} label={label} required={required} />}
      <textarea
        id={id}
        ref={ref}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        name={name}
        rows={rows || 2}
        value={value || defaultValue}
        {...rest}
        className={cx(
          'border border-solid border-neutral300 focus:outline-none focus:ring-0 min-h-[3.5rem] w-full rounded-xl p-4  resize-none overflow-y-auto text-[0.8125rem] text-slate-800',
          classes?.input,
          error && '!bg-red50 !border-red-500'
        )}
      />
    </div>
  )
}

interface TextAreaProps {
  label?: string
  id?: string
  name: string
  rows?: number
  disabled?: boolean
  placeholder?: string
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  value: string
  required?: boolean
  defaultValue?: string
  classes?: { container?: string; input?: string }
  error?: boolean
  newInput?: boolean
}

export default forwardRef(TextArea)
