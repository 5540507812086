import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { Icon } from '@mui/material'
import cx from 'classnames'
import Loading from 'components/loading/Loading'
import Modal from 'components/modal'
import Typography, { Variant } from 'components/typography'
import { useCallback, useMemo, useState } from 'react'

const DocumentPreviewModal = ({
  children,
  isLoading = false,
  isFullScreen,
  setDocumentPreviewModal,
  documentPreviewModal,
  selectedFile,
  setIsFullScreen,
}: DocumentPreviewModalProps) => {
  const [fileHasNotRendered, setFileHasNotRendered] = useState(true)

  const handleFullScreen = useCallback(() => {
    setIsFullScreen(prev => !prev)
  }, [setIsFullScreen])

  const renderModalTitle = useMemo(
    () => (
      <Icon onClick={handleFullScreen}>
        {isFullScreen ? (
          <CloseFullscreenIcon className="cursor-pointer" />
        ) : (
          <OpenInFullIcon className="cursor-pointer" />
        )}
      </Icon>
    ),
    [handleFullScreen, isFullScreen]
  )

  const handleModalClose = () => {
    setDocumentPreviewModal(false)
    setFileHasNotRendered(true)
  }

  const handleFileRendering = () => {
    setFileHasNotRendered(false)
  }

  const containerClassName = cx('p-4 h-[38rem] transition-height transition-width duration-200 ease-in-out', {
    'w-full h-[96vh]': isFullScreen,
    'w-[37.875rem]': !isFullScreen,
  })

  if (isLoading && !fileHasNotRendered) {
    return (
      <Modal
        className="h-full"
        containerClassName={containerClassName}
        isOpen={documentPreviewModal}
        onClose={handleModalClose}
        title={renderModalTitle}>
        <div className="h-[27.765rem] w-full flex justify-center items-center">
          <Loading />
        </div>
      </Modal>
    )
  }

  return (
    <Modal
      className="h-full object-fill"
      containerClassName={containerClassName}
      isOpen={documentPreviewModal}
      onClose={handleModalClose}
      title={renderModalTitle}>
      {!selectedFile && (
        <Typography
          type="semibold"
          variant={Variant.Heading2}
          className="h-[27.765rem] w-full flex justify-center items-center">
          No file to preview
        </Typography>
      )}
      {selectedFile && (
        <object
          key={`pdf-${isFullScreen}`}
          className="h-full w-full"
          data={selectedFile}
          type="application/pdf"
          onLoad={handleFileRendering}
        />
      )}
      {children}
    </Modal>
  )
}

interface DocumentPreviewModalProps {
  children?: React.ReactNode
  isLoading?: boolean
  isFullScreen: boolean
  setDocumentPreviewModal: (value: boolean) => void
  documentPreviewModal: boolean
  selectedFile?: string
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>
}

export default DocumentPreviewModal
