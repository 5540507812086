import { IconProps } from 'assets'

const NewsPaperFolding = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={pathClassName}
        d="M7.875 16.5L7.5 13.5"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M16.5 16.5V4.5H9.375L9.75 7.5L10.125 10.5L10.5 13.5L7.875 16.5H16.5Z"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M10.125 10.5H13.125"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M9.75 7.5H13.125"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M1.5 1.5H9L9.375 4.5L9.75 7.5L10.125 10.5L10.5 13.5H7.5H1.5V1.5Z"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M3.75 4.5H6.375"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M3.75 7.5H6.75"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={pathClassName}
        d="M3.75 10.5H7.125"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default NewsPaperFolding
