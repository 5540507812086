import GreenTick from 'assets/icons/greenTick'
import cx from 'classnames'
import Typography, { Variant } from 'components/typography'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import { useRouter } from 'next/router'
import React, { useCallback } from 'react'

export type ChecklistItemProps = {
  label: string
  href?: string
  skipped?: boolean
  added: boolean
  bgClass?: string
}

const ChecklistItem = ({ label, href, skipped, added, bgClass = 'bg-[rgb(16,15,46)]' }: ChecklistItemProps) => {
  const router = useRouter()
  const reportContext = useReportWizardContext()

  const isSkippable = typeof skipped == 'boolean'

  const handleClick = useCallback(() => {
    if (href) {
      router.push(href)
      reportContext.setIsExpanded(false)
    }
  }, [href, reportContext, router])

  return (
    <div className={cx('py-3 px-4 w-full flex items-center rounded-lg', bgClass)}>
      <Typography
        onClick={handleClick}
        variant={Variant.Callout}
        type="semibold"
        className={cx('text-indigo-400 max-w-[450px]', {
          'cursor-pointer': !!href,
        })}>
        {label} {added && <GreenTick className="inline w-4 h-4 ms-2" pathClassName="fill-green-600" />}
      </Typography>
      {added ? null : isSkippable ? (
        skipped ? (
          <Typography variant={Variant.Footnote} type="semibold" className="text-gray-500 ms-2">
            Skipped
          </Typography>
        ) : (
          <Typography variant={Variant.Footnote} type="semibold" className="text-indigo-400 ms-auto">
            Skip
          </Typography>
        )
      ) : (
        <></>
      )}
    </div>
  )
}

export default ChecklistItem
