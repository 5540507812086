import { IconProps } from 'assets'
import * as React from 'react'
const ContextualToken = ({ className, pathClassName = 'fill-[#A3E635]' }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none">
      <g id="contextual_token">
        <path
          className={pathClassName}
          id="Vector"
          d="M4.5 12H9.75V9.75H4.5V12ZM11.25 12H13.5V6H11.25V12ZM4.5 8.25H9.75V6H4.5V8.25ZM3 15C2.5875 15 2.23438 14.8531 1.94063 14.5594C1.64687 14.2656 1.5 13.9125 1.5 13.5V4.5C1.5 4.0875 1.64687 3.73438 1.94063 3.44063C2.23438 3.14687 2.5875 3 3 3H15C15.4125 3 15.7656 3.14687 16.0594 3.44063C16.3531 3.73438 16.5 4.0875 16.5 4.5V13.5C16.5 13.9125 16.3531 14.2656 16.0594 14.5594C15.7656 14.8531 15.4125 15 15 15H3ZM3 13.5H15V4.5H3V13.5Z"
        />
      </g>
    </svg>
  )
}
export default ContextualToken
