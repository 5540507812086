import { IconProps } from 'assets'

const Flag = ({ className, pathClassName }: IconProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="flag_2">
        <path
          className={pathClassName}
          id="Vector"
          d="M5 22V3H21L19 8L21 13H7V22H5ZM7 11H18.05L16.85 8L18.05 5H7V11Z"
          fill="black"
        />
      </g>
    </svg>
  )
}

export default Flag
