import { Info } from '@mui/icons-material'
import { TransactionResponse } from 'api/transactions/getTransaction/types'
import { IconsType } from 'assets/types'
import BorderlessBox from 'components/BorderlessBox/BorderlessBox'
import Button, { ButtonVariant } from 'components/button'
import { IconPlacement } from 'components/input'
import Loading from 'components/loading'
import Modal from 'components/modal'
import NewTable from 'components/newTable'
import Typography, { Variant } from 'components/typography'
import { EMPTY_ARRAY } from 'constants/app.constants'
import { useAllTransactionList } from 'hooks/useAllTransactionList'
import { useReportWizardContext } from 'hooks/useReportWizard/useReportWizard'
import _map from 'lodash/map'
import ListTable from 'organisms/transactions/listTable'
import { selectedTransactionsColumns } from 'organisms/transactions/listTable/listTable.column'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  mapSelectedTransactions,
  saveSelectedTransactions,
} from 'views/benchmarks/overview/organisms/transactionSelectForm/helpers'

const TransactionSelectionModal = ({ showModal, handleModalClose, isReorder }: TransactionSelectionModalProps) => {
  const {
    selectedTemplate,
    checklistData,
    setSelectedTransactions: setTransactions,
    legalEntity,
  } = useReportWizardContext()
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})
  const [currentStep, setCurrentStep] = useState(isReorder ? 1 : 0)
  const [selectedTransactions, setSelectedTransactions] = useState<Set<number>>(new Set())
  const [filteredTransactionData, setFilteredTransactionData] = useState<TransactionResponse[]>([])
  const [reorderedTransactions, setReorderedTransactions] = useState<TransactionResponse[]>([])

  const { transactionsList, isLoading } = useAllTransactionList(
    !!(legalEntity || checklistData?.legal_entity?.id),
    undefined,
    {
      legal_entity: legalEntity || checklistData?.legal_entity?.id || undefined,
      compliance_region:
        selectedTemplate?.additionalData && selectedTemplate?.additionalData['complianceRegion']
          ? selectedTemplate.additionalData['complianceRegion']
          : undefined,
    },
    legalEntity || checklistData?.legal_entity?.id
      ? selectedTemplate?.additionalData && selectedTemplate?.additionalData['complianceRegion']
        ? [
            (legalEntity || checklistData?.legal_entity?.id)?.toString(),
            selectedTemplate.additionalData['complianceRegion'],
          ]
        : [(legalEntity || checklistData?.legal_entity?.id)?.toString()]
      : []
  )

  useEffect(() => {
    if (checklistData?.transactions_order && checklistData?.transactions_order.length > 0) {
      const selectedTransactions = new Set(_map(checklistData?.transactions_order))
      setSelectedTransactions(selectedTransactions)
    }
  }, [checklistData?.transactions_order])

  useEffect(() => {
    setSelectedTransactions(prevSelectedTransactions => {
      const newSelectedTransactions = saveSelectedTransactions(
        rowSelection,
        filteredTransactionData
      )(prevSelectedTransactions)

      setRowSelection(mapSelectedTransactions(newSelectedTransactions, transactionsList || []))
      setFilteredTransactionData(transactionsList || [])
      return newSelectedTransactions
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsList, selectedTransactions])

  const reorderTransactionsRow = useCallback(
    (draggedRowIndex: number, targetRowIndex: number) => {
      const swappedAttributes = [...reorderedTransactions]
      swappedAttributes.splice(targetRowIndex, 0, swappedAttributes.splice(draggedRowIndex, 1)[0])
      setReorderedTransactions([...swappedAttributes])
    },
    [reorderedTransactions]
  )

  const onModalClose = useCallback(() => {
    handleModalClose()
    setRowSelection({})
  }, [handleModalClose])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const transactionsColumns = useMemo(() => selectedTransactionsColumns(), EMPTY_ARRAY)

  const handleReportGeneration = () => {
    if (transactionsList) {
      const reorderedTransactionsIDs = reorderedTransactions.map(transaction => transaction.id)
      const selectedTransactions = _map(
        rowSelection,
        (_isSelected, txnIdx) => transactionsList && transactionsList[parseInt(txnIdx)].id
      )
      setTransactions(currentStep === 0 ? selectedTransactions : reorderedTransactionsIDs)
      onModalClose()
    }
  }

  useEffect(() => {
    if (currentStep >= 2) {
      onModalClose()
    }
  }, [currentStep, onModalClose])

  const handleBack = useCallback(() => {
    setCurrentStep(step => step - 1)
  }, [setCurrentStep])

  useEffect(() => {
    if (
      isReorder &&
      transactionsList &&
      checklistData?.transactions_order &&
      checklistData?.transactions_order.length > 0
    ) {
      const selectedTransactions = _map(checklistData.transactions_order, id =>
        transactionsList.find(txn => txn.id == id)
      ).filter(val => val) as TransactionResponse[]

      setReorderedTransactions(selectedTransactions || [])
    }
  }, [checklistData?.transactions_order, isReorder, transactionsList])

  const handleNext = useCallback(() => {
    if (transactionsList) {
      const selectedTransactions = _map(rowSelection, (_isSelected, txnIdx) => transactionsList[parseInt(txnIdx)])
      setReorderedTransactions(selectedTransactions || [])
    }
    setCurrentStep(step => step + 1)
  }, [setCurrentStep, rowSelection, transactionsList])
  const selectedCount = Object.keys(rowSelection).length

  return (
    <Modal
      title="Transaction options for Local File"
      className="w-full h-full p-7"
      containerClassName="w-[66.875rem] max-w-[90vw]"
      isOpen={showModal}
      onClose={onModalClose}>
      <>
        {currentStep === 0 && (
          <>
            <BorderlessBox className="relative" label="Select Transactions to include in Local File">
              <div className="w-full">
                <ListTable
                  height={'min(70vh, 25rem)'}
                  enableRowSelection
                  rowSelection={rowSelection}
                  onRowSelectionChange={setRowSelection}
                  transactionsData={transactionsList || []}
                  noEntryText={'No transactions found for selected legal entity and template'}
                />
              </div>
            </BorderlessBox>
            <div className="flex items-center justify-end  w-full">
              <Button
                disabled={selectedCount === 0}
                isDefaultSize={false}
                onClick={selectedCount > 1 ? handleNext : handleReportGeneration}
                icon={selectedCount > 1 ? IconsType.arrowRight : IconsType.tick}
                iconCLass="h-[18px] w-[18px]"
                iconPathClassName={selectedCount === 0 ? 'stroke-gray-400' : 'stroke-white'}
                iconPlacement={selectedCount > 1 ? IconPlacement.Right : IconPlacement.Left}>
                {selectedCount > 1 ? 'Next' : 'Done'}
              </Button>
            </div>
          </>
        )}
        {currentStep === 1 && (
          <>
            <BorderlessBox label="Reorder Transactions">
              <div className="mt-3 w-full">
                <NewTable
                  height={'min(70vh, 25rem)'}
                  classes={{
                    table: { head: '!bg-blue50 py-0' },
                    container: 'rounded-xl',
                  }}
                  columns={transactionsColumns}
                  data={reorderedTransactions}
                  reorderRow={reorderTransactionsRow}
                  rowSelection={rowSelection}
                />
                <div className="flex items-center gap-1 mt-2">
                  <Info className="h-4 w-4 text-orange-400" />
                  <Typography variant={Variant.Callout} className="text-gray-600">
                    All the transactions will appear in the final report the way you reorder them
                  </Typography>
                </div>
              </div>
            </BorderlessBox>
            <div className="flex items-center justify-between w-full">
              {!isReorder && (
                <Button
                  variant={ButtonVariant.Secondary}
                  icon={IconsType.arrowRight}
                  isDefaultSize={false}
                  onClick={handleBack}
                  iconPathClassName="stroke-blue800"
                  iconCLass="rotate-180 w-[1.125rem] h-[1.125rem]">
                  Back
                </Button>
              )}
              <Button
                icon={IconsType.tick}
                className="ms-auto"
                iconPathClassName="stroke-white"
                disabled={isLoading}
                onClick={handleReportGeneration}>
                Done
              </Button>
            </div>
          </>
        )}
      </>
      {isLoading && <Loading className="absolute inset-2 bg-white flex items-center justify-center z-20" />}
    </Modal>
  )
}

interface TransactionSelectionModalProps {
  showModal: boolean
  handleModalClose: VoidFunction
  isReorder?: boolean
}

export default TransactionSelectionModal
