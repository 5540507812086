import { baseClass, descriptiveTextConfig, variants } from './input.config'
import { InputVariant } from './input.types'

const getVariantClass = (variant: InputVariant) => {
  const variantProps = variants[variant]
  if (!variantProps) {
    return []
  }
  return [variantProps.background, variantProps.border]
}

const getInputConfigClass = (variant: InputVariant) => {
  return [...baseClass, ...getVariantClass(variant)]
}

export const VariantMappingToClass: Record<InputVariant, string[]> = {
  [InputVariant.Success]: getInputConfigClass(InputVariant.Success),
  [InputVariant.Error]: getInputConfigClass(InputVariant.Error),
  [InputVariant.Default]: getInputConfigClass(InputVariant.Default),
  [InputVariant.Disabled]: getInputConfigClass(InputVariant.Disabled),
}

export const getDescriptiveTextClass = (variant: InputVariant) => {
  return descriptiveTextConfig[variant]
}

export const getLabelClass = (variant: InputVariant) => {
  if (variant === InputVariant.Disabled) {
    return 'text-grey400'
  } else {
    return 'text-neutral800'
  }
}
