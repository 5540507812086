/* eslint-disable @next/next/no-img-element */
import { IconsType } from 'assets/types'
import cx from 'classnames'
import BorderBoxWrapper from 'components/borderBoxWrapper'
import { ButtonVariant } from 'components/button'
import Button from 'components/button/Button'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ReactZoomPanPinchContentRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'

type Props = { src?: string; onUpload?: VoidFunction; onDelete?: VoidFunction; scaleUp?: boolean; zoomFactor?: number }

const ImagePanZoom = ({ src, onUpload, onDelete, scaleUp = true, zoomFactor = 8 }: Props) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  const buttonsContainer = useRef<HTMLDivElement>(null)
  const transformWrapperRef = useRef<ReactZoomPanPinchContentRef>(null)

  const [containerWidth, setContainerWidth] = useState<number>(0)
  const [containerHeight, setContainerHeight] = useState<number>(0)

  const [buttonsContainerHeight, setButtonsContainerHeight] = useState<number>(0)

  const [imageNaturalWidth, setImageNaturalWidth] = useState<number>(0)
  const [imageNaturalHeight, setImageNaturalHeight] = useState<number>(0)
  const [imageScale, setImageScale] = useState(1)

  useEffect(() => {
    if (containerWidth && containerHeight && imageNaturalWidth && imageNaturalHeight) {
      const scale = Math.min(
        containerWidth / imageNaturalWidth,
        (containerHeight - buttonsContainerHeight) / imageNaturalHeight
      )

      setImageScale(scaleUp ? scale : Math.max(scale, 1))
    }
  }, [containerWidth, containerHeight, imageNaturalWidth, imageNaturalHeight, buttonsContainerHeight, scaleUp])

  const handleResize = useCallback(() => {
    if (container) {
      const rect = container.getBoundingClientRect()
      setContainerWidth(rect.width)
      setContainerHeight(rect.height)
    } else {
      setContainerWidth(900)
      setContainerHeight(400)
    }
    if (buttonsContainer.current) {
      const buttonsRect = buttonsContainer.current.getBoundingClientRect()
      setButtonsContainerHeight(buttonsRect.height)
    }
    transformWrapperRef?.current?.resetTransform()
  }, [container])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      handleResize()
    })

    if (container) {
      resizeObserver.observe(container)
    }
    return () => {
      resizeObserver.disconnect()
    }
  }, [container, handleResize])

  useEffect(() => {
    if (src) {
      const image = new Image()
      const handleImageOnLoad = (image: HTMLImageElement) => {
        setImageNaturalWidth(image.naturalWidth)
        setImageNaturalHeight(image.naturalHeight)
      }
      image.onload = () => handleImageOnLoad(image)
      image.src = src
    }
  }, [src])
  return (
    <div
      style={{
        width: '100%',
      }}>
      <span className="hidden">
        {JSON.stringify(
          { imageScale, containerWidth, containerHeight, imageNaturalWidth, imageNaturalHeight },
          null,
          1
        )}
      </span>
      <div ref={(el: HTMLDivElement | null) => setContainer(el)}>
        <TransformWrapper
          key={`${containerWidth}x${containerHeight}x${imageNaturalHeight}x${imageScale}`}
          ref={transformWrapperRef}
          initialScale={imageScale}
          minScale={imageScale}
          maxScale={imageScale * zoomFactor}
          centerOnInit>
          {(utils: ReactZoomPanPinchContentRef) => (
            <>
              <BorderBoxWrapper
                className={cx('w-full h-[50vh] !p-0 bg-neutral100 ', {
                  hidden: !src,
                  'cursor-not-allowed': !src,
                  'cursor-move': src,
                })}>
                <TransformComponent
                  contentStyle={{
                    background: 'none',
                  }}
                  wrapperStyle={{
                    width: '100%',
                    height: '100%',
                  }}>
                  <img alt="" onLoad={() => transformWrapperRef.current?.resetTransform()} src={src} />
                </TransformComponent>
              </BorderBoxWrapper>
              <div ref={buttonsContainer} className="flex items-center justify-between">
                <div className="flex mt-4 items-center gap-4">
                  {src && (
                    <>
                      <Button
                        onClick={() => utils.zoomOut(0.2)}
                        isDefaultSize={false}
                        variant={ButtonVariant.Secondary}
                        iconPathClassName="stroke-blue900"
                        icon={IconsType.minus}
                      />
                      <Button
                        onClick={() => utils.resetTransform()}
                        isDefaultSize={false}
                        variant={ButtonVariant.Secondary}
                        iconPathClassName="stroke-blue900"
                        icon={IconsType.reset}
                      />
                      <Button
                        onClick={() => utils.zoomIn(0.2)}
                        isDefaultSize={false}
                        variant={ButtonVariant.Secondary}
                        iconPathClassName="stroke-blue900"
                        icon={IconsType.plus}
                      />
                    </>
                  )}
                </div>
                <div className="flex mt-4 items-center gap-4">
                  {onDelete && src && (
                    <Button
                      onClick={onDelete}
                      isDefaultSize={false}
                      icon={IconsType.delete}
                      variant={ButtonVariant.Secondary}
                      className=" !border-red-500 !text-red-500  !bg-white"></Button>
                  )}
                  {onUpload && (
                    <Button
                      onClick={onUpload}
                      isDefaultSize={false}
                      icon={IconsType.upload}
                      variant={ButtonVariant.Secondary}
                      className="min-w-36"></Button>
                  )}
                </div>
              </div>
            </>
          )}
        </TransformWrapper>
      </div>
    </div>
  )
}
export default ImagePanZoom
