import cx from 'classnames'
import { getDescriptiveTextClass } from 'components/input/input.helpers'
import { InputVariant } from 'components/input/input.types'
import Typography, { Variant } from 'components/typography'
import React, { useMemo } from 'react'

const DescriptiveText = ({ variant, descriptiveText, error }: DescriptiveTextProps): JSX.Element => {
  const descriptiveClass = useMemo(() => {
    if (error) {
      return getDescriptiveTextClass(InputVariant.Error)
    } else {
      getDescriptiveTextClass(variant)
    }
  }, [error, variant])

  return (
    <Typography
      className={cx(`${descriptiveClass} pt-[0.125rem] min-h-[1.25rem]`, {
        visible: descriptiveClass !== undefined,
        invisible: descriptiveClass === undefined,
      })}
      variant={Variant.Callout}>
      {descriptiveText || ''}
    </Typography>
  )
}

interface DescriptiveTextProps {
  variant: InputVariant
  descriptiveText?: string
  error?: boolean
}

export default DescriptiveText
